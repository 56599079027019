import {Dispatch} from "redux";
import {
	getProject,
	getProjectList,
	setProjectAsync,
} from "store/mapx/project-list/projectListAsyncActions";
import {setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {Mixpanel} from "helpers/mixpanel";
import {userEmailSelector} from "store/mapx/user/userSelectors";
import {TAppDispatch, TRootState} from "types";
import {
	TQuickSearchCreationResponse,
	TQuickSearchDataWithProject,
} from "store/mapx/additional-profiles/types";
import {
	addOrUpdateSearchRequestToTheList,
	displayAPResults,
	setActiveSearchRequest,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import axios from "axios";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {projectSelector, projectsSelector} from "store/mapx/project-list/projectListSelectors";
import {TSearchRequestCreate, TSearchRequestDetails} from "api/projectApi/searchRequestApi/types";
import searchRequestApi from "api/projectApi/searchRequestApi";
import {successResponse} from "helpers/map";
import {STProject} from "api/projectApi/types";
import {activeSearchRequestSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {createSearchRequest} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const createFreeTextQuickSearch =
	(description: string) =>
	async (
		dispatch: Dispatch<TAppDispatch>,
		getState: () => TRootState,
	): Promise<TQuickSearchCreationResponse> => {
		try {
			const state = getState();
			const userEmail = userEmailSelector(state);
			const selectedProjectInState = projectSelector(state);

			const apiPayload: TSearchRequestCreate = {
				type: "Free Text Input Search",
				free_text_input: description,
			};

			if (selectedProjectInState) {
				apiPayload.project_id = selectedProjectInState.id;
			}

			const resp = await searchRequestApi.createSearchRequest(apiPayload);

			if (successResponse(resp, 201)) {
				const quickSearchData: TSearchRequestDetails = resp.data;

				const projectId = quickSearchData.project_id;

				let project: STProject;
				let quickSearchWithProject;

				if (selectedProjectInState) {
					project = {...selectedProjectInState};
					quickSearchWithProject = {...quickSearchData, selectedProjectInState};
				} else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					project = await dispatch(getProject(projectId));
					quickSearchWithProject = {...quickSearchData, project};

					dispatch(getProjectList());
					dispatch(setProjectAsync(project));
					// dispatch(prepareToDisplaySearchResults());
				}

				dispatch(addOrUpdateSearchRequestToTheList(quickSearchWithProject));
				dispatch(setActiveSearchRequest(quickSearchWithProject));

				Mixpanel.track(`Ran Quick Search`, {
					name: `${project.name}`,
					pageTitle: `${project.name} - Quick Search`,
					url: window.location.pathname,
					distinct_id: userEmail,
				});

				return {
					status: "success",
					project: project,
					message: "Success",
				};
			} else if (resp.status === 400) {
				return {
					status: "error",
					project: null,
					message: resp.data.detail,
				};
			} else {
				console.log(resp);

				return {
					status: "error",
					project: null,
					message: "Something went wrong.",
				};
			}
		} catch (e) {
			console.log(e);

			return {
				status: "error",
				project: null,
				message: (e as Error).message || "Something went wrong.",
			};
		}
	};

export const prepareToDisplaySearchResults = () => (dispatch: Dispatch<TAppDispatch>) => {
	try {
		// dispatch(clearAPActiveResult());
		dispatch(setProjectTabIndex(4)); // ap tab index 4
		dispatch(displayAPResults(true));

		dispatch(getTargetListCompanies());
		dispatch(getTargetListCandidates());
	} catch (e) {
		console.log(e);
	}
};

export const getQuickSearchDetails =
	(searchId: number, project?: Nullable<STProject>) =>
	async (
		dispatch: Dispatch<TAppDispatch>,
		getState: TRootState,
	): Promise<Nullable<TQuickSearchDataWithProject>> => {
		const requestKey = `GET_QUICK_SEARCH_DETAILS_${searchId}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		let response;

		const state = getState();

		const selectedProject = projectSelector(state);

		const projects = projectsSelector(state);

		try {
			if (!project) {
				// for progress watcher we pass project in 2nd param
				const [quickSearchResponse] = await Promise.allSettled([
					searchRequestApi.getSearchDetailsById(searchId, config),
				]);

				if (quickSearchResponse.status !== "rejected") {
					response = quickSearchResponse.value;
				}
			} else {
				response = await searchRequestApi.getSearchDetailsById(searchId, config);
			}

			if (successResponse(response, 200)) {
				const searchDetails: TSearchRequestDetails = response.data;

				const projectToBeSet =
					project ??
					projects.find((item: STProject) => item.id === searchDetails.project_id);

				const data = {
					...searchDetails,
					project: projectToBeSet,
				};

				// we run multiple quick search at a time
				// if quick search is for currently selected project, then we set it to state
				if (
					selectedProject !== null &&
					projectToBeSet !== null &&
					selectedProject.id === projectToBeSet?.id
				) {
					dispatch(setActiveSearchRequest(data));
				}

				return data;
			}

			return null;
		} catch (e) {
			console.log(`Error from getting all Profile request: ${e}`);

			return null;
		}
	};

/**
 * GENERATE MORE RESULTS FOR QUICK SEARCH
 */

export const requestGenerateMoreResults =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		try {
			const state = getState();

			const activeQuickSearch = activeSearchRequestSelector(state);

			const project = projectSelector(state);

			const payload: TSearchRequestCreate = {
				// candidate_id: 0,
				parent_search_request_id: activeQuickSearch.id,
				project_id: project.id,
				type: "Generate More Results",
			};

			await dispatch(createSearchRequest(payload));
		} catch (e) {
			console.log(e);
		}
	};

export const getGenerateMoreResultsDetails =
	(searchRequestId: number) => async (): Promise<TSearchRequestDetails | null> => {
		try {
			const requestKey = `GET_GENERATE_MORE_DETAILS_${searchRequestId}`;

			if (requestKey in cancelTokens) {
				cancelTokens[requestKey].cancel("Operation canceled due to new request.");
			}

			cancelTokens[requestKey] = axios.CancelToken.source();

			const config = {
				cancelToken: cancelTokens[requestKey].token,
			};

			const response = await searchRequestApi.getSearchDetailsById(searchRequestId, config);

			if (successResponse(response, 200)) {
				return response.data;
			}

			return null;
		} catch (e) {
			console.log(e);

			return null;
		}
	};
