import {
	apCandidateCompaniesFilterKeyByPositionSelector,
	apCandidateCompanyByPositionSelector,
	apCandidateCompanyPositionSelector,
	apCandidateIndustriesByPositionSelector,
	apCandidateIndustriesFilterKeyByPositionSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setAPCompanyPosition, setAPMultipleFilters} from "./additionalProfilesActions";

export const setAPIndustry =
	({position, nextIds}) =>
	async (dispatch, getState) => {
		const state = getState();

		const industryFilterKey = apCandidateIndustriesFilterKeyByPositionSelector(state, position);

		dispatch(setAPMultipleFilters({[industryFilterKey]: nextIds}));
	};

export const setAPCompanyMultiple =
	({ids}) =>
	async (dispatch, getState) => {
		const state = getState();

		const position = apCandidateCompanyPositionSelector(state);

		const companiesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(state, position);

		dispatch(setAPMultipleFilters({[companiesFilterKey]: [...ids]}));
	};

export const setAPCompany =
	({id}) =>
	async (dispatch, getState) => {
		const state = getState();

		const position = apCandidateCompanyPositionSelector(state);

		const companies = apCandidateCompanyByPositionSelector(state);

		const companiesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(state, position);

		const index = companies.indexOf(id);

		const isSelectedAlready = index !== -1;

		const nextCompanies = isSelectedAlready
			? [...companies.slice(0, index), ...companies.slice(index + 1)]
			: [...companies, id];

		dispatch(setAPMultipleFilters({[companiesFilterKey]: nextCompanies}));
	};

export const moveAPCompanyAll =
	({position}) =>
	async (dispatch, getState) => {
		const state = getState();

		const prevPosition = apCandidateCompanyPositionSelector(state);

		const companies = apCandidateCompanyByPositionSelector(state);

		const fromCompaniesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(
			state,
			prevPosition,
		);

		const toCompaniesFilterKey = apCandidateCompaniesFilterKeyByPositionSelector(
			state,
			position,
		);

		if (companies.length > 0) {
			dispatch(
				setAPMultipleFilters({
					[fromCompaniesFilterKey]: [],
					[toCompaniesFilterKey]: [...companies],
				}),
			);
		}

		dispatch(setAPCompanyPosition(position));
	};

export const moveAPIndustry =
	({from, to, ids}) =>
	async (dispatch, getState) => {
		const idsSet = new Set(ids);

		const state = getState();

		const fromIndustriesFilterKey = apCandidateIndustriesFilterKeyByPositionSelector(
			state,
			from,
		);

		const toIndustriesFilterKey = apCandidateIndustriesFilterKeyByPositionSelector(state, to);

		const fromIndustries = apCandidateIndustriesByPositionSelector(state, from);

		const toIndustries = apCandidateIndustriesByPositionSelector(state, to);

		dispatch(
			setAPMultipleFilters({
				[fromIndustriesFilterKey]: fromIndustries.filter((id) => !idsSet.has(id)),
				[toIndustriesFilterKey]: [
					...toIndustries.filter((id) => !idsSet.has(id)), // prevent duplication
					...fromIndustries.filter((id) => idsSet.has(id)),
				],
			}),
		);
	};
