import React, {useEffect, useRef} from "react";
import classNames from "classnames";
import styles from "./SearchAutoGrow.module.scss";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {TSearchAutogrowProps} from "./types";

const SearchAutogrowInput = ({
	customWrapperClass,
	customInputClass,
	isLoading = false,
	loaderColor = colors.loaderDotColor,
	preIcon = null,
	onChange,
	value = "",
	maxHeight = 200,
	setInputHeight,
	...restProps
}: TSearchAutogrowProps) => {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const InlineLoaderComponent = (
		<Loader
			displayAtCenterOfPage={false}
			type={"ThreeDots"}
			height={20}
			width={20}
			color={loaderColor}
		/>
	);

	const resizeTextArea = () => {
		if (textAreaRef?.current) {
			textAreaRef.current.style.height = "auto";

			const scrollHeight = textAreaRef.current.scrollHeight;

			let height;

			if (scrollHeight < maxHeight) {
				height = textAreaRef.current.scrollHeight;
			} else {
				height = `${maxHeight}`;
			}

			textAreaRef.current.style.height = `${height}px`;

			if (setInputHeight) {
				setInputHeight(parseInt(height.toString()));
			}
		}
	};

	useEffect(resizeTextArea, [maxHeight, value, setInputHeight]);

	return (
		<div
			className={classNames(styles.inputContainer, customWrapperClass && customWrapperClass)}
		>
			<div className={styles.inputWrapper}>
				{preIcon && preIcon}

				<textarea
					{...restProps}
					ref={textAreaRef}
					onChange={onChange}
					className={classNames(styles.searchInput, customInputClass && customInputClass)}
					data-testid="search-input"
					rows={1}
					style={{paddingLeft: !preIcon ? 16 : 36}}
				/>

				{isLoading && (
					<span data-testid="inline-loader" className={styles.loader}>
						{InlineLoaderComponent}
					</span>
				)}
			</div>
		</div>
	);
};

export default SearchAutogrowInput;
