import {MarketMapsIcon, SelectAllIcon} from "assets/icons";
import classNames from "classnames";

import {Checkbox, ResetFilters, Switch, TabSelect} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toggleAllTargetCompaniesOnCandidateFilter} from "store/mapx/filter/candidateSearchPageAsyncActions";
import {setCandidateTargetListToggle} from "store/mapx/filter/filterActions";
import {
	addTargetListCompanyToCandidateTLCompanyFilter,
	moveAllTagetListCompaniesToCandidate,
	removeAllTargetListCompanies,
} from "store/mapx/filter/filterAsyncActions";
import {
	candidateTargetListPositionSelector,
	candidateTargetListToggleSelector,
	isTargetListExpandedSelector,
	selectedTargetListCompaniesSelector,
	targetListFilterCountSelector,
} from "store/mapx/filter/filterSelectors";
import {
	targetCompaniesPaginationSelector,
	targetListCompaniesSelector,
	targetListCompanyInProgressSelector,
} from "store/mapx/target-list/targetListSelectors";
import css from "./targetListFilter.module.scss";
import {STCompany} from "api/companyApi/types";
import {TCompaniesPositionValues} from "containers/Filters/PeopleFilters/CompanyFilter/types";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {GeneralButton} from "mapx-components";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {addFromMarketMapModal} from "store/mapx/market-map/marketMapActions";

const positionOptions = [
	{label: "Current", value: "current"},
	{label: "Previous", value: "previous"},
	{label: "Any", value: "any"},
];

const TargetListFilter = ({totalCompanies}: {totalCompanies: number}) => {
	const dispatch = useAppDispatch();

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const selectedCompanies = useAppSelector(selectedTargetListCompaniesSelector);

	const shouldBeExpanded = useAppSelector(isTargetListExpandedSelector);

	const project = useAppSelector(projectSelector);

	const toggled = useAppSelector(candidateTargetListToggleSelector);

	const count = useAppSelector(targetListFilterCountSelector);

	const position = useAppSelector(candidateTargetListPositionSelector);

	const companiesFetchInProgress = useAppSelector(targetListCompanyInProgressSelector);

	const targetCompaniesPagination = useAppSelector(targetCompaniesPaginationSelector);

	const [page, setPage] = useState<number>(1);

	const totalDisplayNumber = useMemo(() => page * 20, [page]);

	const displayedCompanies = useMemo(() => {
		return targetListCompanies.slice(0, totalDisplayNumber);
	}, [targetListCompanies, totalDisplayNumber]);

	const [sentryRef] = useInfiniteScroll({
		loading: false,
		hasNextPage: targetCompaniesPagination?.count > totalDisplayNumber,
		onLoadMore: async () => {
			setPage((prev) => prev + 1);
		},
		disabled: targetCompaniesPagination === null,
		delayInMs: 300,
	});

	const isAllTargetCompaniesAreSelected = useMemo(() => {
		if (count === 0) return false;

		return count === targetListCompanies.length;
	}, [count, targetListCompanies]);

	const handleToggle = useCallback(() => {
		dispatch(toggleAllTargetCompaniesOnCandidateFilter(!toggled));
	}, [dispatch, toggled]);

	const handleResetClick = useCallback(() => {
		dispatch(removeAllTargetListCompanies());
	}, [dispatch]);

	const handleSelectAll = useCallback(() => {
		dispatch(toggleAllTargetCompaniesOnCandidateFilter(true));
	}, [dispatch]);

	const handlePositionChange = useCallback(
		(value: string) => {
			dispatch(moveAllTagetListCompaniesToCandidate(value as TCompaniesPositionValues));
		},
		[dispatch],
	);

	const handleChange = useCallback(
		(company: STCompany) => {
			dispatch(addTargetListCompanyToCandidateTLCompanyFilter(company));
		},
		[dispatch],
	);

	useEffect(() => {
		if (shouldBeExpanded) {
			dispatch(setCandidateTargetListToggle(true));
		}
	}, [dispatch, shouldBeExpanded]);

	const handleAddFromMarketMap = useCallback(() => {
		dispatch(addFromMarketMapModal(true, {targetListID: project?.target_list_id}));
	}, [dispatch, project?.target_list_id]);

	return (
		<div
			className={classNames(css.accordion, {[css.expanded]: toggled})}
			aria-expanded={toggled}
		>
			<div className={css.line} />

			<div className={css.header}>
				{totalCompanies > 0 ? (
					<div className={css.left}>
						<Switch
							data-testid="toggleTargetListCompanyFilter"
							checked={toggled}
							onChange={handleToggle}
						/>
						<div className={css.title}>Target List</div>
						{count > 0 && <div className={css.count}>{count}</div>}
					</div>
				) : (
					<div className={css.left}></div>
				)}

				<div className={css.right}>
					<GeneralButton
						customClass={css.targetListButton}
						icon={<MarketMapsIcon color="#0c5850" />}
						title={"Add Target List"}
						onClick={handleAddFromMarketMap}
					/>
				</div>
			</div>
			{totalCompanies > 0 && (
				<div className={classNames(css.children, {[css.expanded]: toggled})}>
					<TabSelect.LabelContainer label="All positions">
						<TabSelect
							selected={position}
							onTabChange={handlePositionChange}
							options={positionOptions}
						/>
					</TabSelect.LabelContainer>

					<div className={css.resetFilterContainer}>
						{totalCompanies > 0 && (
							<ResetFilters
								parentStyle={{marginBottom: 0, color: "#5A5A5A", marginRight: 19}}
								onClick={handleResetClick}
								displayIcon={true}
							>
								Clear Selection
							</ResetFilters>
						)}

						{!isAllTargetCompaniesAreSelected && (
							<div className={css.selectAll} onClick={handleSelectAll}>
								<SelectAllIcon /> Select All
							</div>
						)}
					</div>
					<CheckboxList>
						{displayedCompanies.map((item: STCompany) => (
							<Checkbox
								borderColor="#0C5850"
								containerClass={css.checkboxContainer}
								isChecked={selectedCompanies.has(item.id)}
								key={item.id}
								label={item.name}
								onChange={() => handleChange(item)}
								value={item.name}
							/>
						))}

						{!companiesFetchInProgress &&
							targetCompaniesPagination?.count > totalDisplayNumber && (
								<div
									ref={sentryRef}
									key="saved_companies"
									style={{width: "100%", height: "20px"}}
								/>
							)}
					</CheckboxList>
				</div>
			)}
		</div>
	);
};

export default TargetListFilter;
