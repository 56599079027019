import {uniqBy} from "lodash";

import type {TFunctionalData} from "./types";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";

export const getModifiedFunctionalBreakdownData = (reports: TFunctionalData[]) => {
	const jobFunctions = uniqBy(
		reports.map((val: TFunctionalData) => val.job_function),
		(e) => e?.id,
	);

	const allCandidateIds = new Set();

	reports.forEach((item: TFunctionalData) => {
		item?.candidates?.forEach((candidate) => {
			allCandidateIds.add(candidate.id);
		});
	});

	const totalCandidatesCount = allCandidateIds.size;

	return jobFunctions.map((val) => {
		const children = reports?.filter(
			(item: TFunctionalData) => item?.job_function?.id === val?.id,
		);

		const groupCandidates = children?.map((item: TFunctionalData) => {
			return item?.candidates;
		});

		// As a candidate might have multiple specialisms, following line will contain duplicate candidates
		// hence we are cleaning up duplicate candidates count
		const candidates = removeDuplicateObjectFromArray(groupCandidates.flat());

		const {percentageSum, candidateCount} = children.reduce(
			(acc, curVal) => {
				acc.candidateCount = candidates.length || 0;
				acc.percentageSum += curVal.duration_percent || 0;

				return acc;
			},
			{
				candidateCount: 0,
				percentageSum: 0,
			},
		);

		if (val === null) {
			val = {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				id: null,
				name: "Other",
			};
		}

		return {
			...val,
			children,
			candidates,
			candidateCount,
			value: Math.round(percentageSum),
			frequencyValue: Math.round((candidateCount / totalCandidatesCount) * 100),
			totalCandidateCountForSkillAnalysis: totalCandidatesCount,
		};
	});
};

// @TODO Need to refactor redux to ts
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getModifiedIndustryBackgroundData = (industryBackgroundData: any) =>
	// @TODO Need to refactor redux to ts
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	industryBackgroundData.map((industryBackgroundDataItem: any) => ({
		id: industryBackgroundDataItem.industry.id,
		name: industryBackgroundDataItem.industry.name,
		candidates: industryBackgroundDataItem.candidates,
		candidateCount: industryBackgroundDataItem.candidates_count,
		frequencyValue: Math.round(industryBackgroundDataItem.candidates_percent),
		value: Math.round(
			industryBackgroundDataItem.duration_percent ||
				industryBackgroundDataItem.background_percentage,
		),
	}));
