import React, {useEffect, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import ProfileRequestConfigurator from "./ProfilesRequestConfigurator";
import SearchRequestResults from "mapx-components/SearchRequestResults";
import {TProjectsResultsTabContentTypes} from "mapx-components/SearchRequestResults/FilterBlock/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {activeProjectResultsTabContentSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import Instruction from "mapx-pages/CandidateSearch/AIWebSearch/Instruction";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {
	clearActiveSearchRequest,
	clearAPActiveResult,
	clearAPFilters,
} from "store/mapx/additional-profiles/additionalProfilesActions";

const CandidateAIWebSearch = () => {
	const dispatch = useAppDispatch();

	const [loadingSearchRequests, setLoadingSearchRequests] = useState();

	const activeContent: TProjectsResultsTabContentTypes = useAppSelector(
		activeProjectResultsTabContentSelector,
	);

	const project = useAppSelector(projectSelector);

	const shouldDisplayConfigWithInstruction = useMemo(() => {
		if (!project) return true;

		return !loadingSearchRequests && activeContent === "profile_request";
	}, [activeContent, loadingSearchRequests, project]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveSearchRequest());
			dispatch(clearAPActiveResult());
			dispatch(clearAPFilters());
		};
	}, [dispatch]);

	return (
		<div className={styles.container}>
			{shouldDisplayConfigWithInstruction && <ProfileRequestConfigurator />}

			{shouldDisplayConfigWithInstruction && <Instruction />}

			{project && activeContent !== "profile_request" && (
				<SearchRequestResults
					searchType={"Additional Profiles"}
					setLoadingSearchRequests={setLoadingSearchRequests}
				/>
			)}
		</div>
	);
};

export default CandidateAIWebSearch;
