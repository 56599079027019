import type {TMultiSelectProps} from "components/MultiSelect/types";

import {TReportFilterParams} from "api/companyApi/types";

export type TUseOrgChartFilterOptionsReturn = {
	// @TODO: Refactor to redux options real types
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	keywordOptions: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	countryOptions: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	seniorityOptions: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	functionSpecialismOptions: any[];
};

export type TUseOrgChartFiltersReturn = {
	resetFilters: () => void;
	selectedFilters: TReportFilterParams;
	onSelectChange: TMultiSelectProps["onSelectChange"];
};

export type TUseOrgChartFiltersParams = {
	excludedKeys?: string[];
};

export enum FilterOptions {
	Country = "country",
	Keyword = "keyword",
	Seniority = "seniority",
	JobFunction = "jobFunction",
}

export type TUseOrgChartFilterOptionsParams = {
	excludedFilterOptions?: FilterOptions[];
};
