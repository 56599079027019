import {ChangeEvent, memo, useCallback, useState} from "react";
import useDebouncedEffect from "use-debounced-effect";
import {useSelector} from "react-redux";
import {Checkbox, CheckboxSkeletonLoader, ResetFilters} from "components";
import {SearchInput} from "mapx-components";
import useFetchListOptions from "hooks/useFetchListOptions";
import {getJobFunctions} from "store/mapx/search/searchAsyncActions";
import {getName} from "helpers/string";
import Tags from "./Tags";
import {jobFunctionOptionsSelector} from "store/mapx/search/searchSelectors";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import ExpandableArrow from "mapx-components/ExpandableArrow";

import {getFunctionExpandableData} from "./utils";
import {STJobFunction} from "api/filterOptionsApi/JobFunctionApi/types";
import {TGetExpandableDataResult} from "types";
import {TFunctionNSpecialismSearchFilterProps} from "./types";
import {arrayIntersected, removeInputArrayIdentifiersFromAnotherArray} from "helpers/array";
import {removeDuplicatesFromArray} from "helpers/filterHandlers";

function FunctionNSpecialismSearchFilter({
	displayResetFilterOption,
	handleTagClick,
	handleResetClick,
	handleBulkUpdate,
	handleSpecialismChange,
	selectedSpecialismIds,
	selectedSpecialismTags,
	selectionTree,
	handleConnectivityOptionChange,
	connectionLogics,
	moveSpecialismTagPosition,
	clearTagsByPosition,
}: TFunctionNSpecialismSearchFilterProps) {
	const [expanded, setExpanded] = useState<TGetExpandableDataResult>({});

	const [, setSearchQuery] = useState<null | string>(null);

	const jobFunctionOptions = useSelector(jobFunctionOptionsSelector);

	const {filteredData, searchTerm, onFilterChanged, loading} = useFetchListOptions({
		options: jobFunctionOptions,
		callbackMethod: getJobFunctions,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		childFilterKey: "specialisms",
	});

	useDebouncedEffect(
		() => {
			if (!searchTerm) {
				setExpanded({});

				return;
			}

			const expandableFilteredData = getFunctionExpandableData(filteredData);

			if (expandableFilteredData) {
				setExpanded(expandableFilteredData);
			}
		},
		500,
		[searchTerm],
	);

	const expand = useCallback(
		(id: number) => {
			setExpanded({
				...expanded,
				[id]: !expanded[id],
			});
		},
		[expanded],
	);

	const handleSearchChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onFilterChanged(e);

			setSearchQuery(e.target.value);
		},
		[onFilterChanged],
	);

	const specialismIdsByJobFunction = useCallback((jobFunction: STJobFunction) => {
		return jobFunction?.specialisms?.map(({id}) => id);
	}, []);

	const hasJobFunctionSelected = useCallback(
		(jobFunction: STJobFunction) =>
			arrayIntersected(selectedSpecialismIds, specialismIdsByJobFunction(jobFunction)),
		[selectedSpecialismIds, specialismIdsByJobFunction],
	);

	const hasJobFunctionPartiallySelected = useCallback(
		(jobFunction: STJobFunction) =>
			!hasJobFunctionSelected(jobFunction) && selectionTree[jobFunction.id]?.length > 0,
		[hasJobFunctionSelected, selectionTree],
	);

	const handleFunctionChange = useCallback(
		(jobFunction: STJobFunction) => {
			const ids = specialismIdsByJobFunction(jobFunction);
			let updatedIds;

			if (hasJobFunctionSelected(jobFunction)) {
				updatedIds = removeInputArrayIdentifiersFromAnotherArray(
					ids,
					selectedSpecialismIds,
				);
			} else {
				updatedIds = removeDuplicatesFromArray([...selectedSpecialismIds, ...ids]);
			}

			handleBulkUpdate(updatedIds);
		},
		[
			handleBulkUpdate,
			hasJobFunctionSelected,
			selectedSpecialismIds,
			specialismIdsByJobFunction,
		],
	);

	return (
		<div>
			<SearchInput
				type="text"
				placeholder="Search for a Function or Specialism"
				onChange={handleSearchChange}
				errorText={undefined}
				errorClass={undefined}
				isLoading={loading}
			/>

			{displayResetFilterOption && (
				<ResetFilters
					parentStyle={{color: "#5A5A5A", marginRight: 19}}
					onClick={handleResetClick}
					displayIcon={true}
				>
					Clear Selection
				</ResetFilters>
			)}

			<CheckboxList>
				{filteredData.map((f: STJobFunction) => {
					const isJobFunctionSelected = hasJobFunctionSelected(f);

					const isJobFunctionPartiallySelected = hasJobFunctionPartiallySelected(f);

					return (
						<CheckboxList.Accordion key={f.id}>
							<CheckboxList.AccordionHeader>
								<ExpandableArrow
									onClick={() => expand(f.id)}
									rotated={!expanded[f.id]}
								/>

								<Checkbox
									borderColor="#0C5850"
									isChecked={isJobFunctionSelected}
									key={f.id}
									label={`${getName(f.name)} (${f.specialisms.length})`}
									onChange={() => handleFunctionChange(f)}
									value={f.id.toString()}
									partiallySelected={isJobFunctionPartiallySelected}
								/>
							</CheckboxList.AccordionHeader>

							<CheckboxList.AccordionContent expanded={expanded[f.id]}>
								{f.specialisms.map((s) => (
									<Checkbox
										borderColor="#0C5850"
										isChecked={selectedSpecialismIds.includes(s.id)}
										key={s.id}
										label={getName(s.name)}
										onChange={() => handleSpecialismChange(s.id)}
										value={getName(s.name)}
									/>
								))}
							</CheckboxList.AccordionContent>
						</CheckboxList.Accordion>
					);
				})}
			</CheckboxList>

			{(loading || jobFunctionOptions.length === 0) && <CheckboxSkeletonLoader />}

			<div style={{display: "flex", flexDirection: "column", gap: 8, marginTop: 8}}>
				<Tags
					tags={selectedSpecialismTags.current}
					tagLabel={"Current"}
					tagValue={"current"}
					key={"current"}
					handleConnectivityOptionChange={handleConnectivityOptionChange}
					selectedLogic={connectionLogics.current}
					handleTagClick={handleTagClick}
					moveSpecialismTagPosition={moveSpecialismTagPosition}
					clearTagsByPosition={clearTagsByPosition}
				/>

				<Tags
					tags={selectedSpecialismTags.previous}
					tagLabel={"Previous"}
					tagValue={"previous"}
					key={"previous"}
					handleConnectivityOptionChange={handleConnectivityOptionChange}
					selectedLogic={connectionLogics.previous}
					handleTagClick={handleTagClick}
					moveSpecialismTagPosition={moveSpecialismTagPosition}
					clearTagsByPosition={clearTagsByPosition}
				/>

				<Tags
					tags={selectedSpecialismTags.any}
					tagLabel={"Any"}
					tagValue={"any"}
					key={"any"}
					handleConnectivityOptionChange={handleConnectivityOptionChange}
					selectedLogic={connectionLogics.any}
					handleTagClick={handleTagClick}
					moveSpecialismTagPosition={moveSpecialismTagPosition}
					clearTagsByPosition={clearTagsByPosition}
				/>
			</div>
		</div>
	);
}

export default memo(FunctionNSpecialismSearchFilter);
