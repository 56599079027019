import config from "config";
import {Mixpanel} from "helpers/mixpanel";
import {useAppDispatch, useAppSelector} from "hooks";
import React, {Fragment, useEffect, useState} from "react";
import ReactGA from "react-ga4";
import {hotjar} from "react-hotjar";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {getProjectList, updateProjectList} from "store/mapx/project-list/projectListAsyncActions";
import {
	editProjectModalSelector,
	getProjectListInProgressSelector,
	projectPaginationSelector,
	projectsSelector,
	updateProjectListInProgressSelector,
} from "store/mapx/project-list/projectListSelectors";
import {userEmailSelector, userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {MainWrapper, ProjectCard} from "mapx-components";
import css from "./projects.module.scss";
import ProjectSkeletonLoader from "mapx-pages/Projects/Loader";
import {isEmpty} from "lodash";
import ProjectsEmpty from "./ProjectsEmpty";
import {STProject} from "api/projectApi/types";
import {Button} from "components";
import {CreatePlusIcon} from "assets/icons";
import styles from "../Home/HomeStyles.module.scss";
import {createProjectModal} from "store/mapx/project-list/projectListActions";
import useSearchDebounce from "hooks/useSearchDebounce";
import ProjectSearch from "./Filter/search";
import useLocationSearchService from "services/useLocationSearchService";
import ProjectEditModal from "mapx-components/Modals/ProjectEditModal";
import {useSelector} from "react-redux";

const ProjectPage = () => {
	const dispatch = useAppDispatch();

	const displayEditModal = useSelector(editProjectModalSelector);

	const projectsFetchInProgress = useAppSelector(getProjectListInProgressSelector);

	const pagination = useAppSelector(projectPaginationSelector);

	const projects = useAppSelector(projectsSelector);

	const userEmail = useAppSelector(userEmailSelector);

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [currentSearch, setCurrentSearch] = useState("");

	const moreProjectsFetchInProgress = useAppSelector(updateProjectListInProgressSelector);

	const {locations} = useLocationSearchService();

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const isStarterOrPlusUser: boolean =
		userLicenseTypeName === "Starter" || userLicenseTypeName === "Plus";

	// useOnClickOutside(projectModalRef, () => setModalDisplay([false, null]));

	const [sentryRef] = useInfiniteScroll({
		loading: moreProjectsFetchInProgress,
		hasNextPage: pagination.pages > pagination.page,
		onLoadMore: async () => await dispatch(updateProjectList()),
		rootMargin: "0px 0px 400px 0px",
		disabled: pagination.pages === pagination.page && pagination && pagination?.count < 21,
		delayInMs: 300,
	});

	useEffect(() => {
		dispatch(getProjectList(1, searchTerm));
	}, [dispatch, searchTerm]);

	useEffect(() => {
		try {
			const pageTitle = `Projects | ${config.pageTitlePostFix}`;

			window.document.title = pageTitle;

			ReactGA.send({hitType: "pageview", page: window.location.pathname, title: pageTitle});

			if (hotjar.initialized()) {
				hotjar.stateChange(window.location.pathname);
			}

			Mixpanel.track(`Project List Page Viewed`, {
				name: `Project List Page`,
				pageTitle: `Project List Page`,
				url: window.location.pathname,
				distinct_id: userEmail,
			});
		} catch (e) {
			console.log(e);
		}
	}, [userEmail]);

	const LoaderComponent = <ProjectSkeletonLoader count={10} />;

	const handleCreateProjectClick = () => {
		dispatch(createProjectModal(true));
	};

	const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
		setCurrentSearch(e.target.value);
	};

	return (
		<MainWrapper>
			<div className={css.container}>
				{!isEmpty(projects) || searchTerm !== "" || projectsFetchInProgress ? (
					<Fragment>
						<div className={css.headerSection}>
							<p>All Projects</p>

							<div className={css.headerSection}>
								<ProjectSearch
									onChange={handleSearchInput}
									loading={projectsFetchInProgress}
									value={currentSearch}
								/>

								{/*Will need to activate this in the future*/}
								{/*<MultiSelectWithAction*/}
								{/*    className={css.multiSelect}*/}
								{/*    isSelected={selected.length > 0}*/}
								{/*    options={marketMapCreatedByOptions}*/}
								{/*    placeholder="Created by"*/}
								{/*    selected={selected}*/}
								{/*    setSelected={setSelected}*/}
								{/*/>*/}

								{!isStarterOrPlusUser && (
									<Button
										data-testid="addNewProjectButtonOnProjectsList"
										LeftIcon={CreatePlusIcon}
										onClick={handleCreateProjectClick}
										className={styles.content__bottom__info__buttons_create}
										leftIconClasses={
											styles.content__bottom__info__buttons_create_icon
										}
									>
										Create a New Project
									</Button>
								)}
							</div>
						</div>

						{projectsFetchInProgress && LoaderComponent}

						{!projectsFetchInProgress &&
							projects.map((project: STProject, index: number) => (
								<ProjectCard
									key={`${index}${project.id}${project.name}`}
									project={project}
									locations={locations}
								/>
							))}

						{!moreProjectsFetchInProgress && projects.length > 19 && (
							<div ref={sentryRef} style={{width: "100%", height: "20px"}} />
						)}

						{isEmpty(projects) && (
							<div className={css.noQueryMatch}>
								<h4>No project was found with your search query</h4>
								<p>Try with something different</p>
							</div>
						)}

						{moreProjectsFetchInProgress && LoaderComponent}
					</Fragment>
				) : (
					<ProjectsEmpty />
				)}
			</div>

			{displayEditModal && <ProjectEditModal />}
		</MainWrapper>
	);
};

export default ProjectPage;
