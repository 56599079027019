import {Loader} from "components";
import {useOnClickOutside} from "hooks";
import {forwardRef, useEffect, useRef, useState} from "react";
import colors from "styles/themes.module.scss";

import SearchInput from "../SearchInput";
import styles from "./autoComplete.module.scss";
import {SuggestionsListComponent} from "./CustomItems/SuggestionsListComponent";
import SuggestionListSkeleton from "./CustomItems/SuggestionsListComponent/SuggestionListSkeleton";

const AutoComplete = forwardRef(
	(
		{
			suggestions,
			notFoundResult,
			handleFilterChange,
			handleItemClick,
			isLoading = false,
			loaderColor = colors.loaderDotColor,
			SuggestionListItemComponent = null,
			hasMore = false,
			placeholderText = "Search",
			clearInputOnSelect = true,
			defaultValue = "",
			...rest
		},
		ref,
	) => {
		const searchRef = useRef();

		const [input, setInput] = useState(defaultValue || "");
		const [showSuggestions, setShowSuggestions] = useState(false);
		const [internalLoading, setInternalLoading] = useState(false);

		useEffect(() => {
			if (internalLoading && !isLoading) {
				setInternalLoading(false);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isLoading]);

		const onChange = (e) => {
			setInput(e.target.value);

			handleFilterChange(e.target.value);

			if (e.target.value.length > 1) {
				setShowSuggestions(true);
				setInternalLoading(true);
			}
		};

		const onClick = (selectedItem) => {
			handleItemClick(selectedItem);
			setShowSuggestions(false);

			if (clearInputOnSelect) {
				setInput("");
			}
		};

		const InlineLoaderComponent = (
			<Loader
				width={20}
				height={20}
				type="ThreeDots"
				color={loaderColor}
				displayAtCenterOfPage={false}
			/>
		);

		const resultsOrNotFound =
			suggestions && suggestions.length > 0 && showSuggestions ? (
				<SuggestionsListComponent
					ref={ref}
					onClick={onClick}
					displayLoader={hasMore}
					suggestions={suggestions}
					SuggestionListItemComponent={SuggestionListItemComponent}
				/>
			) : (
				input?.length > 1 && (
					<div className={styles.list__empty}>
						<p className={styles.list__empty_text}>{notFoundResult}</p>
					</div>
				)
			);

		const renderResults = internalLoading ? <SuggestionListSkeleton /> : resultsOrNotFound;

		useOnClickOutside(searchRef, () => setShowSuggestions(false));

		return (
			<div ref={searchRef} className={styles.container}>
				<div className={styles.searchWrapper}>
					<SearchInput
						type="text"
						value={input}
						autoComplete="off"
						onChange={onChange}
						placeholder={placeholderText}
						onFocus={() => setShowSuggestions(true)}
						{...rest}
					/>

					{isLoading && <span className={styles.loader}>{InlineLoaderComponent}</span>}
				</div>

				{showSuggestions && !!input.length && (
					<div className={styles.list}>{renderResults}</div>
				)}
			</div>
		);
	},
);

AutoComplete.displayName = "AutoComplete";

export default AutoComplete;
