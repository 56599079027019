import {importLinkedInProfile} from "store/mapx/linkedin-bulk-import/linkedInProfilesImportAsyncActions";
import {setSearchProgressCandidateByLinkedInForProject} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {TAppDispatch} from "types";

export const searchCandidateByLinkedInUrl =
	(linkedinUrls: string[], project: {id: number; target_list_id: number}) =>
	async (dispatch: TAppDispatch) => {
		dispatch(
			setSearchProgressCandidateByLinkedInForProject({
				id: project.id,
				data: true,
				targetListId: project.target_list_id,
				project: project,
			}),
		);

		try {
			let response;

			const importResponse = await dispatch(importLinkedInProfile(linkedinUrls, project.id));

			if (importResponse.status === 201) {
				const importInfo = importResponse.data;
				importInfo.iteration = 1;

				response = {candidate: null, message: "importing", importInfo};
			} else if (importResponse.status === 403) {
				response = {
					candidate: null,
					message: importResponse.data.message,
					importInfo: null,
				};
			}

			return response;
		} catch (error) {
			console.log(error);
		}

		return {candidate: null, message: "Error"};
	};
