import type {MouseEvent} from "react";
import {useCallback, useMemo, useRef, useState} from "react";
import {noop} from "lodash";
import classNames from "classnames";
import {BiCheck} from "react-icons/bi";

import {Loader, Tooltip, UpgradeNowTooltip} from "components";

import styles from "./generalButton.module.scss";
import type {TGeneralButtonProps} from "./types";
import {ChevronIcon} from "assets/icons";
import {useOutsideClick} from "hooks";

const GeneralButton = ({
	children,
	customClass = "",
	disabled = false,
	handleClick = noop,
	icon = null,
	isClicked = null,
	loadingSave = false,
	options = [],
	title = "Save",
	variation = "standard",
	showUpgradeTooltip = false,
	tooltipIdentifier = "default",
	tooltipType,
	...rest
}: TGeneralButtonProps) => {
	const ref = useRef<HTMLButtonElement>(null);

	const [expanded, setExpanded] = useState(false);

	const onClick = () => {
		if (!loadingSave && !disabled) {
			handleClick(!isClicked);
		}
	};

	const buttonClass = useMemo(() => {
		return classNames(
			{
				[styles.generalButton]: variation !== "remove",
				[styles.removeButton]: variation === "remove",
				[styles.clickedButton]: title === "Saved",
				[styles.disabled]: loadingSave || disabled,
			},
			customClass,
		);
	}, [customClass, variation, title, loadingSave, disabled]);

	const optionsAvailable = useMemo(() => options.length > 0, [options]);

	const handleExpandClick = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			setExpanded(!expanded);
		},
		[expanded],
	);

	useOutsideClick(ref, () => setExpanded(false));

	return (
		<button
			className={buttonClass}
			onClick={onClick}
			role="button"
			ref={ref}
			{...rest}
			data-tooltip-id={tooltipIdentifier}
		>
			{title === "Saved" && !loadingSave && !optionsAvailable ? (
				<BiCheck size={20} className={styles.checkIcon} />
			) : null}

			{loadingSave ? (
				<Loader height={16} width={16} type="TailSpin" color="#fff" />
			) : (
				<>
					{icon}
					{title}
					{children}
					{optionsAvailable && (
						<Tooltip
							showArrow
							placement="bottom-end"
							open={expanded}
							allowInteraction
							crossAxis={-10}
						>
							<Tooltip.Trigger asChild>
								<span className={styles.expandable} onClick={handleExpandClick}>
									<ChevronIcon
										color="#fff"
										className={classNames(styles.chevron, {
											[styles.expanded]: expanded,
										})}
									/>
								</span>
							</Tooltip.Trigger>
							<Tooltip.Content onClick={(e) => e.stopPropagation()}>
								{options.map((option) => (
									<div
										role="button"
										key={option.label}
										className={styles.option}
										onClick={(e) => {
											e.stopPropagation();
											option.action();
										}}
									>
										{option.label}
									</div>
								))}
							</Tooltip.Content>
						</Tooltip>
					)}
				</>
			)}

			{showUpgradeTooltip && <UpgradeNowTooltip id={tooltipIdentifier} type={tooltipType} />}
		</button>
	);
};

export default GeneralButton;
