import {useMemo, useState} from "react";
import {ProjectCandidateCard} from "mapx-components";
import {useAppSelector} from "hooks";
import {
	filteredSavedCandidatesPaginationSelector,
	filteredSavedCandidatesSelector,
	filteredSavedPeopleFetchIsInProgressSelector,
	targetCandidatesPaginationSelector,
	targetListCandidateInProgressSelector,
	targetListCandidatesSelector,
} from "store/mapx/target-list/targetListSelectors";
import {SICandidate} from "api/candidateApi/types";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {SkeletonLoaderCardAsList} from "components";
import styles from "./styles.module.scss";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import SavedPeopleHeaderSection from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection";
import FilterSection from "mapx-pages/Project/SavedPeople/FilterSection";
import {useSelector} from "react-redux";
import {additionalProfileFiltersSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";

const SavedPeople = () => {
	const targetCandidatesFetchInProgress = useAppSelector(targetListCandidateInProgressSelector);
	const filteredCandidatesFetchInProgress = useAppSelector(
		filteredSavedPeopleFetchIsInProgressSelector,
	);

	const savedPeoples: SICandidate[] = useAppSelector(targetListCandidatesSelector);
	const filteredSavedPeoples: SICandidate[] = useAppSelector(filteredSavedCandidatesSelector);

	const targetCandidatesPagination = useAppSelector(targetCandidatesPaginationSelector);
	const filteredTargetCandidatesPagination = useAppSelector(
		filteredSavedCandidatesPaginationSelector,
	);

	const filterSelection = useSelector(additionalProfileFiltersSelector);

	const [displayAllExperience, setDisplayAllExperience] = useState<boolean>(false);

	const [page, setPage] = useState<number>(1);

	const hasFilters = useMemo(() => {
		return !!buildCandidateApiRequestPayload(filterSelection);
	}, [filterSelection]);

	const totalDisplayNumber = useMemo(() => page * 20, [page]);

	const pagination = useMemo(
		() => (hasFilters ? filteredTargetCandidatesPagination : targetCandidatesPagination),
		[filteredTargetCandidatesPagination, hasFilters, targetCandidatesPagination],
	);

	const displayedCandidates = useMemo(() => {
		return hasFilters
			? filteredSavedPeoples.slice(0, totalDisplayNumber)
			: savedPeoples.slice(0, totalDisplayNumber);
	}, [filteredSavedPeoples, hasFilters, savedPeoples, totalDisplayNumber]);

	const [sentryRef] = useInfiniteScroll({
		loading: false,
		hasNextPage: pagination?.count > totalDisplayNumber,
		onLoadMore: async () => {
			setPage((prev) => prev + 1);
		},
		disabled:
			targetCandidatesPagination === null && filteredTargetCandidatesPagination === null,
		delayInMs: 300,
	});

	const candidatesFetchInProgress = useMemo(
		() => targetCandidatesFetchInProgress || filteredCandidatesFetchInProgress,
		[filteredCandidatesFetchInProgress, targetCandidatesFetchInProgress],
	);

	return (
		<div className={styles.listedItems}>
			<FilterSection />

			{!candidatesFetchInProgress && (
				<SavedPeopleHeaderSection
					displayAllExperience={displayAllExperience}
					setDisplayAllExperience={setDisplayAllExperience}
					hasActiveFilters={hasFilters}
					currentDisplayedCount={pagination?.count ?? 0}
					totalSavedPeopleCount={targetCandidatesPagination?.count ?? 0}
					displayedCandidates={displayedCandidates}
				/>
			)}

			<LazyLoadComponent>
				{!candidatesFetchInProgress &&
					displayedCandidates?.map((candidate, index) => {
						return (
							<ProjectCandidateCard
								key={`${candidate?.id}${index}`}
								candidate={candidate}
								searchType="Project"
								displayAllExperience={displayAllExperience}
							/>
						);
					})}
			</LazyLoadComponent>

			{candidatesFetchInProgress && <SkeletonLoaderCardAsList count={5} />}

			{!candidatesFetchInProgress && pagination?.count > totalDisplayNumber && (
				<div
					ref={sentryRef}
					key="saved_candidates"
					style={{width: "100%", height: "20px"}}
				/>
			)}

			{displayedCandidates?.length === 0 &&
				!candidatesFetchInProgress &&
				(hasFilters
					? "No profiles found based on the selected filters"
					: "You have not saved any peoples on this project yet")}
		</div>
	);
};

export default SavedPeople;
