import React, {useCallback, useEffect, useMemo} from "react";

import styles from "./styles.module.scss";
import ProjectCandidatesFilter from "mapx-pages/Project/ProjectCandidatesFilter";
import {useSelector} from "react-redux";
import {additionalProfileFiltersSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {useAppDispatch, useAppSelector} from "hooks";
import {getFilteredSavedPeople} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import classNames from "classnames";
import AddPeople from "mapx-pages/Project/SavedPeople/AddPeople";

const FilterSection = () => {
	const dispatch = useAppDispatch();

	const filterSelection = useSelector(additionalProfileFiltersSelector);

	const targetListId = useAppSelector(targetListIDSelector);

	const candidateFilterPayload = useMemo(() => {
		const payload = buildCandidateApiRequestPayload({...filterSelection});

		if (payload !== null) {
			return {
				filters: {
					...payload.filters,
					target_list: targetListId,
				},
				sorting_options: {
					...payload.sorting_options,
				},
			};
		} else return null;
	}, [filterSelection, targetListId]);

	const fetchResults = useCallback(
		async (payload: Omit<ICandidateSearchApiRequestPayload, "pagination">): Promise<void> => {
			await dispatch(getFilteredSavedPeople(payload));
		},
		[dispatch],
	);

	useEffect(() => {
		if (candidateFilterPayload !== null) {
			fetchResults(candidateFilterPayload);
		}
		// else {
		//     console.log("No filter selection Available");
		// }
	}, [candidateFilterPayload, fetchResults]);

	return (
		<div className={styles.filterSectionWrapper}>
			<div className={styles.left}>
				<ProjectCandidatesFilter
					canUserClick={true}
					source={"saved_people"}
					className={classNames(styles.filterButton, {
						[styles.hasFilter]: candidateFilterPayload !== null,
					})}
					titleClass={styles.filterButtonTitle}
					shouldDisplayResetFilter={candidateFilterPayload !== null}
				/>
			</div>

			<div className={styles.right}>
				<AddPeople />
			</div>
		</div>
	);
};

export default FilterSection;
