import React from "react";
import {CheckboxSkeletonLoader, Loader, ResetFilters} from "components";
import styles from "./styles.module.scss";
import DropdownCheckboxList from "components/DropdownCheckbox";
import {TDEFilterSectionProps} from "./types";

const DEFilterSection = <T,>({
	label,
	isActive,
	selectedValues,
	count,
	toggleActive,
	data,
	onScroll,
	listInnerRef,
	handleResetClick,
	resetFilterOption,
	renderItem,
	loading,
}: TDEFilterSectionProps<T>) => (
	<DropdownCheckboxList
		label={label}
		isActive={isActive}
		selectedValues={selectedValues}
		count={count}
		toggleActive={toggleActive}
	>
		<div>
			{count > 0 && (
				<ResetFilters
					parentStyle={{color: "#5A5A5A"}}
					onClick={() => handleResetClick(resetFilterOption)}
					displayIcon={true}
				>
					Clear {label} Selection
				</ResetFilters>
			)}
			<div className={styles.checkboxList} ref={listInnerRef} onScroll={onScroll}>
				{data.map(renderItem)}
				{data.length < 1 && <CheckboxSkeletonLoader />}
				{data.length > 0 && loading && (
					<div style={{textAlign: "center"}}>
						<Loader
							width={30}
							height={30}
							type="ThreeDots"
							color="#0c5850"
							displayAtCenterOfPage={false}
						/>
					</div>
				)}
			</div>
		</div>
	</DropdownCheckboxList>
);

export default DEFilterSection;
