import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import placeHolderImage from "assets/images/avatar-icon.png";
import {TLazyLoadWrapperProps} from "components/LazyLoadImageWrapper/types";

const LazyLoadImageWrapper = ({
	onClick,
	image,
	alt,
	delayTime = 300,
	effect = "blur",
	className,
	...restProps
}: TLazyLoadWrapperProps) => {
	return (
		<LazyLoadImage
			onClick={onClick}
			delayTime={delayTime}
			effect={effect}
			alt={alt}
			src={image}
			onError={(e) => {
				e.currentTarget.src = placeHolderImage;
			}}
			className={className && className}
			{...restProps}
		/>
	);
};

export default LazyLoadImageWrapper;
