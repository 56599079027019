import {ArrowUp} from "assets/icons";
import EnhanceIcon from "assets/icons/EnhanceIcon";
import classNames from "classnames";

import {LazyLoadImageWrapper, UpgradeNowCard} from "components";

import {useAppDispatch, useAppSelector, useOpenLink} from "hooks";

import useEventMonitor from "hooks/mapx/useEventMonitor";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {ActionAltButton, CandidateExperience, GeneralButton} from "mapx-components";
import AdditionalProfileActions from "mapx-components/AdditionalProfileActions";

import React, {useCallback, useEffect, useMemo, useState} from "react";

import {BiPhone, BiUser} from "react-icons/bi";

import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
	targetListCompaniesSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";

import styles from "./projectCandidateCard.module.scss";
import {TProjectCandidateCardProps} from "mapx-components/Cards/ProjectCandidateCard/types";
import {STCompanyShort} from "api/companyApi/types";
import CandidateSummary from "mapx-components/Cards/ProjectCandidateCard/CandidateSummary";
import {generateCandidatesContactDetails} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import {generateCandidatesContactDetailsForAllProjectSelector} from "store/mapx/project/generateCandidateContactDetailsSelectors";
import CandidateContactDetails from "./CandidateContactDetails";
import {getSingleCandidateDone} from "store/mapx/candidate/candidateActions";

export default function ProjectCandidateCard({
	candidate,
	position = null,
	searchType,
	shouldTrack = false,
	isAdditionalProfileList = false,
	displayAllExperience = false,
	upgradeView = false,
}: TProjectCandidateCardProps) {
	const dispatch = useAppDispatch();

	const openLink = useOpenLink();

	const history = useHistory();

	const [displayAllExp, setDisplayAllExp] = useState(false);

	const {trackStart, trackComplete, itemEventDetails} = useUserBehaviorTrackHook({
		itemType: "candidate",
		itemId: candidate.id,
		source: searchType,
		isRecommended: false,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		position,
	});

	const targetListId = useAppSelector(targetListIDSelector);

	const currentContactDetailsAll = useAppSelector(
		generateCandidatesContactDetailsForAllProjectSelector,
	);

	const loadingCandidateContactDetails = currentContactDetailsAll[targetListId]?.includes(
		candidate?.id,
	);

	const positions = useMemo(() => candidate.positions ?? [], [candidate]);

	/**
	 * IS THIS A AI SEARCH TYPE
	 */

	const isThisAISearchType = useMemo(
		() =>
			searchType === "Additional Profiles" ||
			searchType === "Free Text Input Search" ||
			searchType === "Generate More Results" ||
			searchType === "More Like This" ||
			searchType === "Rejected Profiles",
		[searchType],
	);

	/**
	 ** USER BEHAVIOR TRACKING LOGICS
	 */
	const containerRef = useEventMonitor({shouldTrack, itemEventDetails});

	/**
	 * CHECKING IF OUTSIDE COMPANIES
	 */

	const targetListCompanies = useSelector(targetListCompaniesSelector);

	const isFromOutsideCompany = useMemo(() => {
		const targetCompanyIds = targetListCompanies.map((company: STCompanyShort) => company.id);

		if (positions && positions?.length > 0 && positions[0]?.company !== undefined) {
			for (const exp of positions) {
				if (exp.year_to === null) {
					const foundCompany = targetCompanyIds?.includes(exp.company.id);

					if (foundCompany) {
						return false;
					}
				}
			}

			return true;
		} else {
			return true; // if no experience means outside company
		}
	}, [positions, targetListCompanies]);

	const displaySpecialIndicator = useMemo(() => {
		if (isThisAISearchType) {
			return isFromOutsideCompany;
		} else {
			return false;
		}
	}, [isFromOutsideCompany, isThisAISearchType]);

	const candidateExperiences = useMemo(() => {
		return positions && positions?.length > 0
			? displayAllExp
				? positions
				: positions.slice(0, 3)
			: [];
	}, [displayAllExp, positions]);

	const ToggleExperienceLengthButtonComponent =
		positions && positions?.length > 3 ? (
			<GeneralButton
				customClass={classNames(styles.showMoreButton, {
					[styles.reverse]: !displayAllExp,
				})}
				icon={<ArrowUp width={20} height={20} />}
				isClicked={false}
				handleClick={() => setDisplayAllExp((prev) => !prev)}
				title={displayAllExp ? "Show Less" : "Show More"}
			/>
		) : (
			<div />
		);

	useEffect(() => {
		setDisplayAllExp(displayAllExperience);
	}, [displayAllExperience]);

	const handleItemClick = useCallback(
		(event: React.MouseEvent<HTMLImageElement | HTMLSpanElement, MouseEvent>) => {
			dispatch(getSingleCandidateDone(candidate));

			if (event.ctrlKey || event.metaKey || event.ctrlKey || event.altKey) {
				const originSource = location.pathname.split("/")[1];

				const link = `/candidate/${candidate.id}?src=/${originSource}`;

				openLink(event, link, true);
			} else {
				// let link;

				// if (searchType === "People Search") {
				// 	link = `${location.pathname}/${candidate.id}?src=${searchType}`;
				// } else {
				// }
				const link = `${location.pathname}/${candidate.id}?src=${searchType}`;

				history.push(link);
			}
		},
		[candidate, dispatch, history, openLink, searchType],
	);

	const handleGenerateContactButtonClick = useCallback(async () => {
		await dispatch(generateCandidatesContactDetails([candidate?.id]));
	}, [dispatch, candidate?.id]);

	return (
		<div onMouseEnter={trackStart} onMouseLeave={trackComplete} ref={containerRef}>
			<div
				className={classNames(styles.candidate, {
					[styles.outsideCompany]: displaySpecialIndicator,
				})}
			>
				{upgradeView && <UpgradeNowCard />}

				<div className={classNames({[styles.upgradeView]: upgradeView})}>
					{displaySpecialIndicator && (
						<span className={styles.enhanceIcon}>
							<EnhanceIcon color="white" />
						</span>
					)}

					<div className={styles.infoBlock}>
						<div
							className={classNames(styles.container, {
								[styles.apCandidateContainer]: isThisAISearchType,
							})}
						>
							{candidate && (
								<div className={styles.informationContainer}>
									<div onClick={handleItemClick}>
										<div className={styles.infoFirstRow}>
											<div className={styles.infoTitle}>
												{candidate.avatar_url !== null ? (
													<LazyLoadImageWrapper
														className={styles.logo}
														image={candidate.avatar_url}
														alt={candidate.full_name ?? candidate.name}
													/>
												) : (
													<BiUser className={styles.iconLogo} />
												)}

												<div className={styles.info}>
													<span className={styles.candidateName}>
														{candidate.full_name ?? candidate.name}
													</span>
													<span className={styles.candidateCountry}>
														{candidate.country?.name}
													</span>
												</div>
											</div>
										</div>
									</div>

									{positions && positions?.length > 0 && (
										<CandidateExperience
											key={candidate.id}
											positions={candidateExperiences}
											fromQuickView={false}
											isRecommended={false}
											candidateId={candidate?.id}
											position={position}
											searchType={searchType}
										/>
									)}
								</div>
							)}

							{searchType === "Project" && (
								<CandidateSummary
									key={candidate.id}
									id={candidate.id}
									summary={candidate.summary}
									expanded={
										displayAllExp ||
										(positions !== undefined && positions?.length < 4)
									}
								/>
							)}
						</div>

						{/*{isThisAISearchType && (*/}
						<div className={styles.iconContainer}>
							<div>
								{searchType === "Project" && (
									<div className={styles.candidateDetails}>
										<CandidateContactDetails
											contactDetails={candidate?.contact_details}
											loading={loadingCandidateContactDetails}
										/>
									</div>
								)}

								<div className={styles.iconContainer}>
									<div className={styles.buttonGroup}>
										{ToggleExperienceLengthButtonComponent}

										{searchType === "Project" && (
											<ActionAltButton
												disabled={candidate?.contact_details !== null}
												key={"Generate Contact Details"}
												customClass={classNames(
													styles.generateContactDetails,
													{
														[styles.loading]:
															loadingCandidateContactDetails,
													},
													{
														[styles.additionalMargin]:
															positions && positions?.length <= 3,
													},
												)}
												iconComponent={<BiPhone color={"#4E5555"} />}
												handleClick={handleGenerateContactButtonClick}
												title={
													loadingCandidateContactDetails
														? "Generating Contact Details..."
														: "Generate Contact Details"
												}
												loading={loadingCandidateContactDetails}
											/>
										)}
									</div>
								</div>
							</div>

							<AdditionalProfileActions
								searchType={searchType}
								candidate={candidate}
								isAdditionalProfileList={isAdditionalProfileList}
								isQuickView={false}
							/>
						</div>
						{/*)}*/}
					</div>
				</div>
			</div>
		</div>
	);
}
