import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import {getArrayDifference} from "helpers/array";
import {
	TKeywordPosition,
	TKeywordPositionConnectivityLogic,
} from "api/filterOptionsApi/KeywordApi/types";
import {apKeywordFilterKeyByPosition} from "store/mapx/additional-profiles/apKeywordFilterSelectors";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import type {TJobFunctionPosition} from "api/filterOptionsApi/JobFunctionApi/types";

const updateSelectedAPKeywordsByKeywordPositionAfterSelection =
	(selectedTexts: string[], position: TKeywordPosition) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const logic =
			state.additional_profiles.ap_filters.keyword_filter_config[
				`active_${position}_position_connect_logic`
			];

		const keywordFilterKey = apKeywordFilterKeyByPosition(state, position, logic);

		const keywordTextsBySelectedPosition: string[] =
			state.additional_profiles.ap_filters[keywordFilterKey];

		const updatedTexts = getArrayDifference(keywordTextsBySelectedPosition, selectedTexts);

		return {
			[keywordFilterKey]: updatedTexts,
		};
	};

export const setKeywordForAPCandidate =
	({
		position,
		logic,
		text,
	}: {
		position: TKeywordPosition;
		logic: TKeywordPositionConnectivityLogic;
		text: string;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const keywordFilterKey = apKeywordFilterKeyByPosition(state, position, logic);

		const keywords: string[] = state.additional_profiles.ap_filters[keywordFilterKey];

		const index = keywords.indexOf(text);

		const isSelectedAlready = index !== -1;

		const nextKeywords = isSelectedAlready
			? [...keywords.slice(0, index), ...keywords.slice(index + 1)]
			: [...keywords, text];

		let payload = {
			[keywordFilterKey]: nextKeywords,
		};

		const positionOptions: TKeywordPosition[] = ["current", "previous", "any"];

		const filteredPositionOptions = positionOptions.filter((option) => option !== position);

		for (const filteredPosition of filteredPositionOptions) {
			const filteredPositionUpdatedPayload = dispatch(
				updateSelectedAPKeywordsByKeywordPositionAfterSelection(
					nextKeywords,
					filteredPosition,
				),
			);

			payload = {...payload, ...filteredPositionUpdatedPayload};
		}

		dispatch(setAPMultipleFilters(payload));
	};

export const switchKeywordConnectorForAPCandidatePosition =
	({
		position,
		fromLogic,
		toLogic,
	}: {
		position: TKeywordPosition;
		fromLogic: TKeywordPositionConnectivityLogic;
		toLogic: TKeywordPositionConnectivityLogic;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromFilterKey = apKeywordFilterKeyByPosition(state, position, fromLogic);

		const toFilterKey = apKeywordFilterKeyByPosition(state, position, toLogic);

		const keywords: string[] = state.additional_profiles.ap_filters[fromFilterKey];

		dispatch(
			setAPMultipleFilters({
				[fromFilterKey]: [],
				[toFilterKey]: keywords,
			}),
		);
	};

export const moveKeywordForAPCandidate =
	({
		from,
		to,
		fromLogic,
		toLogic,
		text,
	}: {
		from: TKeywordPosition;
		to: TKeywordPosition;
		fromLogic: TKeywordPositionConnectivityLogic;
		toLogic: TKeywordPositionConnectivityLogic;
		text: string;
	}) =>
	(dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const fromFilterKey = apKeywordFilterKeyByPosition(state, from, fromLogic);

		const toFilterKey = apKeywordFilterKeyByPosition(state, to, toLogic);

		const fromKeywords: string[] = state.additional_profiles.ap_filters[fromFilterKey];

		const toKeywords: string[] = state.additional_profiles.ap_filters[toFilterKey];

		const nextFromKeywords = [...fromKeywords].filter((i) => i !== text);

		const nextToKeywords = [text, ...toKeywords];

		dispatch(
			setAPMultipleFilters({
				[fromFilterKey]: nextFromKeywords,
				[toFilterKey]: nextToKeywords,
			}),
		);
	};

export const clearKeywordsByPositionForAPCandidate =
	(position: TJobFunctionPosition) => (dispatch: Dispatch<TAppDispatch>) => {
		let payload;

		switch (position) {
			case "current":
				payload = {
					current_text_keywords_and: [],
					current_text_keywords_or: [],
					current_text_keywords_not: [],
				};
				break;
			case "previous":
				payload = {
					previous_text_keywords_and: [],
					previous_text_keywords_or: [],
					previous_text_keywords_not: [],
				};
				break;
			default:
				payload = {
					text_keywords_and: [],
					text_keywords_or: [],
					text_keywords_not: [],
				};
		}

		dispatch(setAPMultipleFilters(payload));
	};
