import {createSelector} from "reselect";
import {companyTypeOptionSelector} from "store/mapx/search/searchSelectors";
import {TRootState} from "types";
import {STCompanyType} from "api/companyApi/types";
import {TCompanyTypePosition} from "mapx-components/Filters/CandidateCompanyTypeSearchFilter/types";

export const candidateAnyCompanyTypesSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_type;
export const candidateCurrentCompanyTypesSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_company_type;
export const candidatePreviousCompanyTypesSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_company_type;

export const companyTypesSelector = (state: TRootState) =>
	state.filter.company_filters.company_type;

export const companyTypeFilterConfigSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_type_filter_config;

export const companyTypeCandidatePositionSelector = (state: TRootState) =>
	state.filter.candidate_filters.company_type_filter_config.active_position;

const getCompanyTypePosition = (_: TRootState, position: TCompanyTypePosition) => position;

export const selectedAnyCompanyTypesSelector = createSelector(
	[candidateAnyCompanyTypesSelector, companyTypeOptionSelector],
	(companyTypes: string[], options: STCompanyType[]) => {
		const any: STCompanyType[] = [];

		const iSet = new Set(companyTypes);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				any.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return any;
	},
);
export const selectedPreviousCompanyTypesSelector = createSelector(
	[candidatePreviousCompanyTypesSelector, companyTypeOptionSelector],
	(companyTypes: string[], options: STCompanyType[]) => {
		const previous: STCompanyType[] = [];

		const iSet = new Set(companyTypes);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				previous.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return previous;
	},
);
export const selectedCurrentCompanyTypesSelector = createSelector(
	[candidateCurrentCompanyTypesSelector, companyTypeOptionSelector],
	(companyTypes: string[], options: STCompanyType[]) => {
		const current: STCompanyType[] = [];

		const iSet = new Set(companyTypes);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				current.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return current;
	},
);
export const allCompanyTypesCountSelector = createSelector(
	[
		candidateCurrentCompanyTypesSelector,
		candidatePreviousCompanyTypesSelector,
		candidateAnyCompanyTypesSelector,
	],
	(current, previous, any) => current?.length + previous?.length + any?.length,
);
export const companyTypeFilterKeyByPosition = createSelector(getCompanyTypePosition, (position) => {
	switch (position) {
		case "current":
			return "current_company_type";
		case "previous":
			return "previous_company_type";
		case "any":
		default:
			return "company_type";
	}
});

export const candidateCompanyTypesByPositionSelector = createSelector(
	[
		candidateCurrentCompanyTypesSelector,
		candidatePreviousCompanyTypesSelector,
		candidateAnyCompanyTypesSelector,
		(_, position) => position,
	],
	(current, previous, any, position) => {
		switch (position) {
			case "current":
				return current || [];
			case "previous":
				return previous || [];
			case "any":
				return any || [];
			default:
				return [];
		}
	},
);
