import React, {useCallback, useEffect} from "react";
import {toast} from "react-toastify";
import {
	allSearchProgressWatchSelector,
	allSearchRequestsSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {useAppDispatch, useAppSelector} from "hooks/index";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {ToastContent} from "components";
import {TCloseToast} from "components/ToastContent/types";
import {useHistory} from "react-router-dom";
import {
	TInputCandidate,
	TSearchRequestDetailsWithProject,
	TSearchRequestMiniResponse,
	TSearchRequestStatus,
} from "api/projectApi/searchRequestApi/types";
import {
	getAllSearchRequestsByType,
	getSearchRequestDetails,
} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import {
	clearAPWorkflowState,
	clearCurrentSearchRequestFromProgressWatch,
	setActiveSearchRequest,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import {setProject} from "store/mapx/project-list/projectListActions";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const searchRequestIntervalId = {} as any;

const useSearchRequestProgressWatcher = () => {
	const selectedProjectInState = useAppSelector(projectSelector);

	const allSearchRequestProgressWatch = useAppSelector(allSearchProgressWatchSelector);

	const allSearchRequests = useAppSelector(allSearchRequestsSelector);

	const activeProjectInState = useAppSelector(projectSelector);

	const dispatch = useAppDispatch();

	const history = useHistory();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleNotificationClick = async (
		searchRequest: TSearchRequestDetailsWithProject,
		closeToast: TCloseToast,
	) => {
		if (
			activeProjectInState === null ||
			activeProjectInState?.id.toString() !== searchRequest.project_id.toString()
		) {
			dispatch(clearAPWorkflowState());
			dispatch(setProject(searchRequest.project));
			await dispatch(
				getAllSearchRequestsByType(searchRequest.project_id, searchRequest.type),
			);
			dispatch(getTargetListCandidates());
		}

		dispatch(setActiveSearchRequest(searchRequest));

		if (
			searchRequest.type === "Free Text Input Search" ||
			searchRequest.type === "Generate More Results"
		) {
			history.push("/candidates-text-search");
		} else if (searchRequest.type === "Additional Profiles") {
			history.push("/candidates-ai-web-search");
		} else if (searchRequest.type === "More Like This") {
			history.push(`/candidates-profile-search?request_id=${searchRequest.id}`);
		}

		if (closeToast) {
			closeToast();
		}
	};

	const getTitle = useCallback(
		(searchRequest: TSearchRequestDetailsWithProject) => {
			if (searchRequest.type === "Free Text Input Search") {
				const str = searchRequest.free_text_input;

				return str && str?.length > 40 ? str?.substring(0, 40) : str;
			} else if (searchRequest.type === "Generate More Results") {
				const parentSearch =
					allSearchRequests instanceof Array
						? allSearchRequests.find(
								(sr: TSearchRequestMiniResponse) =>
									sr.id === searchRequest.parent_search_request_id,
						  )
						: null;

				return `More${
					parentSearch ? ": " + parentSearch.free_text_input : "SearchRequestResults"
				}`;
			} else if (searchRequest.type === "More Like This") {
				const candidateName =
					searchRequest.input_candidates.length > 0
						? searchRequest.input_candidates[0].full_name
						: "";

				return `More Like ${candidateName}`;
			} else if (searchRequest.type === "Additional Profiles") {
				const jobTitles =
					searchRequest["job_titles"] !== undefined
						? searchRequest["job_titles"].join(", ")
						: null;

				const candidateName =
					searchRequest.input_candidates.length > 0
						? searchRequest.input_candidates
								.map((candidate: TInputCandidate) => candidate.full_name)
								.join(", ")
						: "";

				return `AI Web Search: ${jobTitles || candidateName}`;
			} else {
				return `${searchRequest.id}`;
			}
		},
		[allSearchRequests],
	);

	const requestWatchNotify = useCallback(
		async (response: TSearchRequestDetailsWithProject, autoClose = 1000 * 3600) => {
			if (response?.status === "Completed") {
				const title = getTitle(response);

				dispatch(getTargetListCompanies());

				toast.success(ToastContent, {
					autoClose: autoClose,
					closeOnClick: false, // because close option available on the notification
					data: {
						title: title,
						description: (closeToast: () => void, color: string) => (
							<>
								Your search request is completed.{" "}
								<span
									className="toast-link"
									style={{color}}
									onClick={async () =>
										await handleNotificationClick(
											response,
											closeToast as () => void,
										)
									}
								>
									Click here
								</span>{" "}
								to see the results.
							</>
						),
					},
				});
			} else if (response?.status === "Error" || response?.status === "Canceled") {
				toast.error(ToastContent, {
					autoClose: autoClose,
					closeOnClick: true,
					data: {title: "Something Went Wrong!"},
				});
			}
		},
		[dispatch, getTitle, handleNotificationClick],
	);

	const isRequestIsInProgress = (status: TSearchRequestStatus) => {
		return status === "Created" || status === "In Progress";
	};

	const handleSearchStatusChange = useCallback(
		async (
			searchRequest: Nullable<TSearchRequestDetailsWithProject>,
			prevIdentifiedCompanies: number,
			intervalKey: number,
		) => {
			if (searchRequest && "project" in searchRequest) {
				if (searchRequest.status === "Completed") {
					clearInterval(searchRequestIntervalId[intervalKey]);

					dispatch(clearCurrentSearchRequestFromProgressWatch(searchRequest.id));

					await requestWatchNotify(searchRequest);
				} else if (
					searchRequest.status === "Canceled" ||
					searchRequest.status === "Error"
				) {
					await requestWatchNotify(searchRequest, 2000);
				}

				if (
					selectedProjectInState &&
					selectedProjectInState.id === searchRequest.project.id &&
					prevIdentifiedCompanies < searchRequest.companies_identified_count
				) {
					dispatch(getTargetListCompanies());
				}
			}
		},
		[dispatch, requestWatchNotify, selectedProjectInState],
	);

	/**
	 * Here on the state we have kept the information by all the search request initiated by user
	 * We loop through all the found search requests on the state
	 * Then We watch the search request status
	 * if status is completed, then we notify user its complete, and they can click on the notification
	 * if search request has error, then we show notification with reason
	 */

	useEffect(() => {
		if (allSearchRequestProgressWatch) {
			for (const [key, value] of Object.entries(allSearchRequestProgressWatch)) {
				const data: TSearchRequestDetailsWithProject =
					key && value ? allSearchRequestProgressWatch[key] : null;

				if (data && isRequestIsInProgress(data?.status)) {
					searchRequestIntervalId[key] = null;

					const inProgress = isRequestIsInProgress(data?.status);
					const prevIdentifiedCompanies = data.companies_identified_count;

					if (inProgress) {
						searchRequestIntervalId[key] = setInterval(async () => {
							const searchRequest: Nullable<TSearchRequestDetailsWithProject> =
								await dispatch(getSearchRequestDetails(data.id, data.project));

							await handleSearchStatusChange(
								searchRequest,
								prevIdentifiedCompanies,
								searchRequestIntervalId[key],
							);
						}, 15 * 1000);

						setTimeout(() => {
							clearInterval(searchRequestIntervalId[key]);
						}, 60 * 300 * 1000); // it will clear after 5 hours
					}

					return () => {
						if (searchRequestIntervalId[key]) {
							clearInterval(searchRequestIntervalId[key]);
						}
					};
				}
			}
		}
	}, [
		allSearchRequestProgressWatch,
		dispatch,
		selectedProjectInState,
		requestWatchNotify,
		handleSearchStatusChange,
	]);
};

export default useSearchRequestProgressWatcher;
