import React, {useCallback, useMemo} from "react";
import styles from "./styles.module.scss";
import {useSelector} from "react-redux";
import {
	prJobTitleSuggestionLoadingSelector,
	prSelectedJobTitlesSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import JobTitlesSearchFilter from "mapx-components/Filters/JobTitlesSearchFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {HelpIcon} from "assets/icons";
import {Tooltip as ReactTooltip} from "react-tooltip";
import Loader from "components/Plugins/Loader";
import colors from "styles/themes.module.scss";
import {setSelectedJobTitlesForPRFilters} from "store/mapx/additional-profiles/profileRequestConfigurator";

const JobTitles = () => {
	const selectedJobTitles = useSelector(prSelectedJobTitlesSelector);

	const dispatch = useAppDispatch();

	const isPRSaving = useSelector(prJobTitleSuggestionLoadingSelector);

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const isStarterUser = userLicenseTypeName === "Starter";

	const handleChange = useCallback(
		(jobTitle: string) => {
			// if (activeSearchRequest === null) {
			//     toast.info(ToastContent, {
			//         data: {
			//             title: "To create a new profile request, job titles can not be modified before one of the other filters is modified.",
			//         },
			//     });
			// } else {
			// }
			dispatch(setSelectedJobTitlesForPRFilters(jobTitle));
		},
		[dispatch],
	);

	const PremiumFeatureTooltip = (
		<ReactTooltip
			clickable
			place="right"
			id="jobTitle"
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			type="light"
			textColor="#404040"
			backgroundColor="#fff"
			arrowColor="#fff"
			className={styles.tooltip}
		>
			<div style={{fontWeight: 500, marginBottom: 8}}>Premium Features</div>

			<div>
				<div style={{marginBottom: 10}}>
					Your plan allows you to search only C-1 and C-2 Job Titles.{" "}
					<span className={styles.green}>Upgrade</span> now to access more seniority.
				</div>
			</div>
		</ReactTooltip>
	);

	const LoaderComponent = (
		<Loader
			height={80}
			width={80}
			type="ThreeDots"
			customStyle={{display: "flex", alignItems: "center", margin: "0 5px"}}
			color={colors.mainThemeColor}
		/>
	);

	const displayOverlay = useMemo(() => {
		return isPRSaving;
	}, [isPRSaving]);

	const overlayText = useMemo(() => "Adjusting search parameters", []);

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				Relevant Job Titles
				{isStarterUser && (
					<div className={styles.helpIcon}>
						<HelpIcon dataTooltipId="jobTitle" />

						{PremiumFeatureTooltip}
					</div>
				)}
			</div>

			{/*{selectedJobTitles?.length < 10 && (*/}
			<JobTitlesSearchFilter
				isDisabled={isPRSaving}
				handleOnChange={handleChange}
				selectedJobTitles={selectedJobTitles}
			/>
			{/*)}*/}

			{displayOverlay && (
				<div className={styles.overlay}>
					{LoaderComponent}
					<span className={styles.overlayText} data-overlay-text={overlayText}>
						{overlayText} <span className={styles.loading} />
					</span>
				</div>
			)}
		</div>
	);
};

export default JobTitles;
