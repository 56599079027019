import {components, DropdownIndicatorProps, GroupBase} from "react-select";
import {ChevronIcon} from "assets/icons";

export const DropdownIndicator = <
	Option,
	IsMulti extends boolean,
	Group extends GroupBase<Option>,
>({
	hideIndicator = false,
	...props
}: {hideIndicator: boolean} & DropdownIndicatorProps<Option, IsMulti, Group>) => {
	return (
		<div>
			{hideIndicator && ""}
			{!hideIndicator && (
				<components.DropdownIndicator {...props}>
					<ChevronIcon style={{transform: "rotateX(180deg)"}} />
				</components.DropdownIndicator>
			)}
		</div>
	);
};
