import {candidateCompanyDateRangeBand} from "api/candidateApi/candidateCompanyDateRangeBand";
import {TDEDateRange} from "mapx-components/Filters/DealExperienceSearchFilter/types";
import ranges from "api/candidateApi/candidateCompanyEventSizeBand.json";

// Helper function to compare only the date part (ignoring the time component)
const compareOnlyDate = (date1: Date, date2: Date) => {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};

export const getDateRangeLabel = (dates: TDEDateRange[]): string => {
	return dates
		?.map(({from_date, to_date}) => {
			const from = new Date(from_date);
			const to = new Date(to_date);

			const matchingRange = candidateCompanyDateRangeBand.find((range) => {
				const minDate = new Date(range.min_value);
				const maxDate = new Date(range.max_value);

				// Compare only the date part
				return (
					(from >= minDate || compareOnlyDate(from, minDate)) &&
					(to <= maxDate || compareOnlyDate(to, maxDate))
				);
			});

			return matchingRange ? matchingRange.name : "";
		})
		.filter((label) => label) // Filter out any empty results
		.join(", ");
};

export const getEventSizeLabel = (sizes: string[]): string => {
	const matchingRanges = ranges.filter((range) => sizes.includes(range.id));

	// Map the matching ranges to their names and join them into a string
	return matchingRanges.map((range) => range.name).join(", ");
};
