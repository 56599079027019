import React, {useCallback, useEffect, useMemo, useState} from "react";

import styles from "./styles.module.scss";
import {useSelector} from "react-redux";
import {companyFiltersSelector} from "store/mapx/filter/filterSelectors";
import {ActionAltButton, CompaniesSkeletonLoader, CompanyCard} from "mapx-components";
import {LazyLoadComponent} from "react-lazy-load-image-component";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	buildCompanyFilterApiPayload,
	buildQueryParameter,
	removeDuplicateObjectFromArrayByKeepingTheFirstIndex,
} from "helpers/filterHandlers";
import {resetCompaniesFilters} from "store/mapx/filter/filterActions";
import classNames from "classnames";
import {ClearSelectionIcon, SavePlusIcon} from "assets/icons";
import {saveCompaniesByFilters} from "store/mapx/target-list/targetListAsyncActions";
import CompanyFilters from "containers/Filters/CompanyFilters";
import {
	companiesInProgressSelector,
	companiesPaginationInfoSelector,
	companiesSelector,
	filteredCompaniesInProgressSelector,
} from "store/mapx/company/companySelectors";
import {STCompany, TFilterCompanyPayload} from "api/companyApi/types";
import axios, {CancelTokenSource} from "axios";
import {getCompaniesData, getFilteredCompaniesData} from "store/mapx/company/companyAsyncActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
let companyCancelToken: CancelTokenSource;

const CompanyFilterSearch = ({companiesResultCount}: {companiesResultCount: number}) => {
	const dispatch = useAppDispatch();

	const [savingAll, setSavingAll] = useState<boolean>(false);

	const [requestCancelled, setRequestCancelled] = useState(false);

	const [loadingMoreResults, setLoadingMoreResults] = useState(false);

	const companiesInProgress = useSelector(companiesInProgressSelector);

	const filteredCompaniesInProgress = useSelector(filteredCompaniesInProgressSelector);

	const filterSelection = useSelector(companyFiltersSelector);

	const companies: STCompany[] = useSelector(companiesSelector);

	const pagination = useSelector(companiesPaginationInfoSelector);

	const companiesApiPayload = useMemo(() => {
		if (filterSelection) {
			return buildCompanyFilterApiPayload(filterSelection);
		} else {
			return null;
		}
	}, [filterSelection]);

	const companiesQueryString = useMemo(() => {
		if (filterSelection) {
			return buildQueryParameter(filterSelection);
		} else {
			return null;
		}
	}, [filterSelection]);

	const fetchResults = useCallback(
		async (query: TFilterCompanyPayload | null) => {
			if (typeof companyCancelToken !== typeof undefined) {
				companyCancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			companyCancelToken = axios.CancelToken.source();
			const config = {cancelToken: companyCancelToken.token};

			let response;

			if (query) {
				response = await dispatch(getFilteredCompaniesData(query, 1, config));
			} else {
				response = await dispatch(getCompaniesData(1, config));
			}

			if (response?.cancelled) {
				setRequestCancelled(true);
			} else {
				setRequestCancelled(false);
			}
		},
		[dispatch],
	);

	useEffect(() => {
		if (companiesApiPayload !== null) {
			fetchResults(companiesApiPayload).then((r) => r);
		} else {
			fetchResults(null).then((r) => r);
		}
	}, [companiesApiPayload, fetchResults]);

	const [sentryRef] = useInfiniteScroll({
		loading: false,
		hasNextPage: pagination?.pages > pagination?.page,
		onLoadMore: async () => {
			const currentPageNumber = pagination?.page + 1 || 1;

			setLoadingMoreResults(true);

			if (companiesApiPayload) {
				await dispatch(getFilteredCompaniesData(companiesApiPayload, currentPageNumber));
			} else {
				await dispatch(getCompaniesData(currentPageNumber));
			}

			setLoadingMoreResults(false);
		},
		disabled: pagination === null,
		delayInMs: 300,
	});

	const companiesFetchInProgress = useMemo(
		() => companiesInProgress || filteredCompaniesInProgress || requestCancelled,
		[companiesInProgress, filteredCompaniesInProgress, requestCancelled],
	);

	const resetFilters = useCallback(() => {
		dispatch(resetCompaniesFilters());
	}, [dispatch]);

	const handleAddAllCandidatesToProject = useCallback(async () => {
		const hasFilter = !!companiesQueryString;

		if (!hasFilter) {
			window.alert("Please apply filters.");
		} else if (hasFilter && companies?.length > 5000) {
			window.alert(
				"Companies search result count exceeds limit. Please consider narrowing down your search.",
			);
		} else {
			setSavingAll(true);
			await dispatch(saveCompaniesByFilters(companiesQueryString, false));
			setSavingAll(false);
		}
	}, [companies?.length, companiesQueryString, dispatch]);

	const isAddAllButtonVisibleForCompanies = useMemo(() => {
		return (
			pagination?.count > 0 && pagination?.count < 5001
			// !displayedOnlySimilarProfiles
		);
	}, [pagination?.count]);

	const selectedCompanies = useAppSelector((state) => state.filter.selected_companies);

	const companiesAll: STCompany[] = useMemo(() => {
		const allCompanies = companiesFetchInProgress
			? [...selectedCompanies]
			: [...selectedCompanies, ...companies];

		return removeDuplicateObjectFromArrayByKeepingTheFirstIndex(allCompanies, "id");
	}, [selectedCompanies, companies, companiesFetchInProgress]);

	return (
		<div className={styles.container}>
			<CompanyFilters />

			<div className={styles.resultsWrapper}>
				<div className={styles.headerSectionWrapper}>
					<div className={styles.actionButtonsRight}>
						{!!companiesQueryString && (
							<ActionAltButton
								key={"clear_filter"}
								customClass={classNames(styles.resetFilterButton)}
								iconComponent={<ClearSelectionIcon />}
								handleClick={resetFilters}
								title={"Clear Filters"}
							/>
						)}

						<div className={styles.count}>Results: {companiesResultCount}</div>
					</div>

					<div className={styles.actionButtonsLeft}>
						{isAddAllButtonVisibleForCompanies && (
							<ActionAltButton
								key={"add_all_to_projects"}
								customClass={classNames(styles.toggleExpandButton)}
								loading={savingAll}
								disabled={savingAll}
								iconComponent={<SavePlusIcon />}
								handleClick={handleAddAllCandidatesToProject}
								title={
									savingAll ? "Adding all to project..." : "Add all to project"
								}
							/>
						)}
					</div>
				</div>

				<LazyLoadComponent>
					{companiesAll?.map((company: STCompany, index: number) => {
						return (
							<CompanyCard
								key={company.id}
								company={company}
								isRecommended={company.recommended}
								position={index + 1}
								shouldTrack={!!companiesQueryString}
							/>
						);
					})}
				</LazyLoadComponent>

				{(companiesFetchInProgress || loadingMoreResults) && (
					<CompaniesSkeletonLoader count={5} />
				)}

				{!companiesFetchInProgress &&
					!loadingMoreResults &&
					pagination?.pages > pagination?.page && (
						<div
							ref={sentryRef}
							key="people_search"
							style={{width: "100%", height: "20px"}}
						/>
					)}

				{!companies?.length && companiesResultCount === 0 && (
					<div className={styles.noResult}>
						<div>
							Hmm, I have not found any results &#128542;. Try to re-configure your
							search criteria.
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CompanyFilterSearch;
