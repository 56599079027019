import cancelTokens from "api/cancelTokens";
import mapXProjectApi from "api/projectApi";
import mapXTargetListApi from "api/targetListApi";

import axios, {CanceledError} from "axios";
import {successResponse} from "helpers/map";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {setMultipleFilterForCandidates} from "../filter/filterActions";
import {
	candidateTargetListPositionSelector,
	candidateTargetListToggleSelector,
	selectedCompaniesSelector,
	tlCompaniesByTargetListPositionSelector,
	tlCompaniesFilterKeyByPositionSelector,
} from "../filter/filterSelectors";
import {updateProjectDone} from "../project-list/projectListActions";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

import {
	addCandidateToTargetListDone,
	addCandidateToTargetListFail,
	addCandidateToTargetListInit,
	addCompaniesFromMarketMapToTargetListFail,
	addCompaniesFromMarketMapToTargetListInit,
	addCompanyToTargetListDone,
	addCompanyToTargetListFail,
	addCompanyToTargetListInit,
	createDetachedTargetListDone,
	createDetachedTargetListFail,
	createDetachedTargetListInit,
	getTargetListCompaniesDone,
	getTargetListCompaniesFail,
	getTargetListCompaniesInit,
	getTargetListsByOwnerIDDone,
	getTargetListsByOwnerIDFail,
	getTargetListsByOwnerIDInit,
	removeCandidateFromTargetListDone,
	removeCandidateFromTargetListFail,
	removeCandidateFromTargetListInit,
	removeCandidatesFromTargetListDone,
	removeCandidatesFromTargetListFail,
	removeCandidatesFromTargetListInit,
	removeCompaniesFromTargetListDone,
	removeCompaniesFromTargetListFail,
	removeCompaniesFromTargetListInit,
	removeCompanyFromTargetListDone,
	removeCompanyFromTargetListFail,
	removeCompanyFromTargetListInit,
	saveCandidatesByFiltersDone,
	saveCandidatesByFiltersInit,
	saveCompaniesByFiltersDone,
	saveCompaniesByFiltersInit,
} from "./targetListActions";
import {targetListCompanyIdsSelector, targetListIDSelector} from "./targetListSelectors";

export const getTargetListsByOwnerID = () => async (dispatch, getState) => {
	try {
		dispatch(getTargetListsByOwnerIDInit());

		const state = getState();

		const ownerID = state.user.user.user_id;

		let {
			data: {results},
		} = await mapXTargetListApi.getTargetListsByOwnerID(ownerID);

		const isDetachedAvailable = results.some((targetList) => !targetList.project_id);

		if (results.length === 0) {
			const {data: targetList} = await mapXTargetListApi.createDetachedTargetList();

			results = [targetList];
		} else if (!isDetachedAvailable) {
			const {data: targetList} = await mapXTargetListApi.createDetachedTargetList();

			results = [...results, targetList];
		}

		dispatch(getTargetListsByOwnerIDDone(results));
	} catch (error) {
		dispatch(getTargetListsByOwnerIDFail({error}));
	}
};

export const getTargetListCompanies = () => async (dispatch, getState) => {
	const requestKey = "GET_TARGETLIST_COMPANIES";

	if (cancelTokens[requestKey]) {
		cancelTokens[requestKey].cancel("Operation canceled due to new request.");
	}

	cancelTokens[requestKey] = axios.CancelToken.source();

	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(getTargetListCompaniesInit());

		const response = await mapXTargetListApi.getTargetListCompanies(targetListID, 1, 20000, {
			cancelToken: cancelTokens[requestKey].token,
		});

		if (successResponse(response, 200)) {
			const data = response.data;

			dispatch(getTargetListCompaniesDone(data));
			const isTargetListOn = candidateTargetListToggleSelector(state);

			if (isTargetListOn) {
				const state = getState();

				const position = candidateTargetListPositionSelector(state);

				const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

				// const companies = tlCompaniesByTargetListPositionSelector(state);

				// const existingCompanyIds = new Set(companies.map((c) => c.id));

				// let newCompanies = [...companies];

				// if (companies.length > 0) {
				// 	response.data.results.forEach((c) => {
				// 		const exist = existingCompanyIds.has(c.id);
				//
				// 		if (!exist) {
				// 			newCompanies.push(c);
				// 		}
				// 	});
				// } else {
				// }
				const newCompanies = [...response.data.results];

				dispatch(setMultipleFilterForCandidates({[filterKey]: newCompanies}));
			}

			return data.results;
		} else if (response == null) {
			throw new CanceledError("Operation canceled due to new request.");
		}

		delete cancelTokens[requestKey];

		return [];
	} catch (error) {
		if (!axios.isCancel(error)) {
			dispatch(getTargetListCompaniesFail({error}));
			delete cancelTokens[requestKey];
		}

		return [];
	}
};

export const addCandidateToTargetList = (candidateID) => async (dispatch, getState) => {
	try {
		dispatch(addCandidateToTargetListInit(candidateID));

		const state = getState();

		const targetListID = targetListIDSelector(state);

		const {data: candidate} = await mapXTargetListApi.addCandidateToTargetList(
			targetListID,
			candidateID,
		);

		dispatch(addCandidateToTargetListDone(candidate, targetListID));

		await dispatch(
			updateProjectSuccessChecklistItem({
				attribute: "has_saved_people",
				value: true,
			}),
		);
	} catch (error) {
		dispatch(addCandidateToTargetListFail({error}));
	}
};

export const addCompanyToTargetList = (companyID) => async (dispatch, getState) => {
	try {
		dispatch(addCompanyToTargetListInit(companyID));

		const state = getState();

		const targetListID = targetListIDSelector(state);

		const {data: company} = await mapXTargetListApi.addCompanyToTargetList(
			targetListID,
			companyID,
		);

		dispatch(addCompanyToTargetListDone(company, targetListID));

		await dispatch(
			updateProjectSuccessChecklistItem({
				attribute: "has_saved_companies",
				value: true,
			}),
		);

		const isTargetListOn = candidateTargetListToggleSelector(state);

		if (isTargetListOn) {
			const state = getState();

			const position = candidateTargetListPositionSelector(state);

			const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

			const companies = tlCompaniesByTargetListPositionSelector(state);

			const existingCompanyIds = new Set(companies.map((c) => c.id));

			let newCompanies = [...companies];

			if (companies.length > 0) {
				const exist = existingCompanyIds.has(company.id);

				if (!exist) {
					newCompanies.push(company);
				}
			} else {
				newCompanies = [company];
			}

			dispatch(setMultipleFilterForCandidates({[filterKey]: newCompanies}));
		}
	} catch (error) {
		dispatch(addCompanyToTargetListFail({error}));
	}
};

export const removeCandidateFromTargetList = (candidateID) => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCandidateFromTargetListInit(candidateID));

		await mapXTargetListApi.removeCandidateFromTargetList(targetListID, candidateID);

		dispatch(removeCandidateFromTargetListDone(candidateID, targetListID));
	} catch (error) {
		dispatch(removeCandidateFromTargetListFail({error, candidateID}));
	}
};

export const removeCompanyFromTargetList = (companyID) => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCompanyFromTargetListInit(companyID));

		await mapXTargetListApi.removeCompanyFromTargetList(targetListID, companyID);

		const isTargetListOn = candidateTargetListToggleSelector(state);

		if (isTargetListOn) {
			const state = getState();

			const position = candidateTargetListPositionSelector(state);

			const filterKey = tlCompaniesFilterKeyByPositionSelector(state, position);

			const companies = tlCompaniesByTargetListPositionSelector(state);

			const targetListCompanyIds = targetListCompanyIdsSelector(state);

			if (targetListCompanyIds.has(companyID)) {
				dispatch(
					setMultipleFilterForCandidates({
						[filterKey]: companies.filter((c) => c.id !== companyID),
					}),
				);
			}
		}

		dispatch(removeCompanyFromTargetListDone(companyID, targetListID));
	} catch (error) {
		dispatch(removeCompanyFromTargetListFail({error, companyID}));
	}
};

export const removeCandidatesFromTargetList = () => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCandidatesFromTargetListInit());

		await mapXTargetListApi.removeCandidatesFromTargetList(targetListID);

		dispatch(removeCandidatesFromTargetListDone(targetListID));
	} catch (error) {
		dispatch(removeCandidatesFromTargetListFail({error}));
	}
};

export const removeCompaniesFromTargetList = () => async (dispatch, getState) => {
	try {
		const state = getState();

		const targetListID = targetListIDSelector(state);

		dispatch(removeCompaniesFromTargetListInit());

		await mapXTargetListApi.removeCompaniesFromTargetList(targetListID);

		dispatch(removeCompaniesFromTargetListDone(targetListID));
	} catch (error) {
		dispatch(removeCompaniesFromTargetListFail({error}));
	}
};

// TODO: This logic should be moved to the backend. Remove this async function
// and all related redux elements after the BE implementation.
export const createDetachedTargetList = () => async (dispatch) => {
	try {
		dispatch(createDetachedTargetListInit());

		const {data: targetList} = await mapXTargetListApi.createDetachedTargetList();

		dispatch(createDetachedTargetListDone(targetList));

		return {targetList};
	} catch (error) {
		dispatch(createDetachedTargetListFail(error));

		return {error};
	}
};

export const saveCompaniesByFilters =
	(filters, showEnhancedCompanies = false) =>
	async (dispatch, getState) => {
		try {
			dispatch(saveCompaniesByFiltersInit());

			const state = getState();

			const project = projectSelector(state);

			const targetListID = targetListIDSelector(state);

			const selectedCompanies = selectedCompaniesSelector(state);

			await mapXTargetListApi.saveCompaniesByFilters(
				filters,
				targetListID,
				showEnhancedCompanies,
			);

			if (project) {
				const {data: newProject} = await mapXProjectApi.getProject(project.id);
				dispatch(updateProjectDone(newProject));
			}

			const tlCompanies = await dispatch(getTargetListCompanies());

			const tLCompanyIds = tlCompanies.map((tlC) => tlC.id);

			// SOME OF THE SELECTED COMPANIES ARE NOT ADDED BY FILTERS
			// SO WE MANUALLY ADD TO TL
			if (selectedCompanies?.length > 0) {
				for (const sCompany of selectedCompanies) {
					if (!tLCompanyIds.includes(sCompany.id)) {
						dispatch(addCompanyToTargetList(sCompany.id));
					}
				}
			}

			dispatch(saveCompaniesByFiltersDone());

			await dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_saved_companies",
					value: true,
				}),
			);
		} catch (error) {
			dispatch(saveCompaniesByFiltersDone());

			return {error};
		}
	};

export const addCompaniesFromMarketMapToTargetList =
	(marketMapID, targetListID) => async (dispatch, getState) => {
		try {
			dispatch(addCompaniesFromMarketMapToTargetListInit());

			const state = getState();

			// const marketMaps = marketMapsModalSelector(state);

			targetListID = targetListID || targetListIDSelector(state);

			await mapXTargetListApi.addCompaniesFromMarketMapToTargetList(
				targetListID,
				marketMapID,
			);

			// const {data} = await mapXTargetListApi.getTargetListCompanies(targetListID);
			//
			// const companies =
			// 	data?.results || marketMaps.find((m) => m.id === marketMapID).companies;

			// dispatch(addCompaniesFromMarketMapToTargetListDone(companies));

			dispatch(getTargetListCompanies());

			await dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_saved_companies",
					value: true,
				}),
			);
		} catch (error) {
			dispatch(addCompaniesFromMarketMapToTargetListFail(error));

			return {error};
		}
	};

export const saveCandidatesByFilters =
	(apiPayload, projectUpdate = true) =>
	async (dispatch, getState) => {
		try {
			dispatch(saveCandidatesByFiltersInit());

			const state = getState();

			const project = projectSelector(state);

			const targetListID = targetListIDSelector(state);

			if ("sorting_options" in apiPayload) {
				delete apiPayload.sorting_options;
			}

			await mapXTargetListApi.saveCandidatesByFilters(targetListID, apiPayload);

			if (project && projectUpdate) {
				const {data: newProject} = await mapXProjectApi.getProject(project.id);
				dispatch(updateProjectDone(newProject));
			}

			dispatch(getTargetListCandidates());

			dispatch(saveCandidatesByFiltersDone());

			await dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_saved_people",
					value: true,
				}),
			);
		} catch (error) {
			dispatch(saveCandidatesByFiltersDone());

			return {error};
		}
	};
