import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {
	activeSearchRequestSelector,
	additionalProfileFiltersSelector,
	additionalProfileResultsSelector,
	AdditionalProfilesPaginationInfoSelector,
	rejectedAdditionalProfileResultsSelector,
	RejectedAdditionalProfilesPaginationInfoSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import mapxProfileRequestsApi from "api/projectApi/profileRequestApi";
import {
	setAPActiveResult,
	setAPMoreRejectedProfilesResult,
	setAPRejectedProfilesResult,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import axios from "axios";
import mapXCandidateApi from "api/candidateApi";
import {buildCandidateApiRequestPayload} from "helpers/filterHandlers";
import {
	getTargetListCompanies,
	saveCandidatesByFilters,
} from "store/mapx/target-list/targetListAsyncActions";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {SICandidate} from "api/candidateApi/types";
import {
	getSearchRequestDetails,
	getSearchRequestedProfilesByQuery,
} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import {STCandidateDecisionData} from "api/projectApi/profileRequestApi/types";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import queryString from "qs";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const preparePayloadForCurrentSearch = () => (_: any, getState: () => TRootState) => {
	const state = getState();

	const activeSearchRequest = activeSearchRequestSelector(state);

	const filtersInState = additionalProfileFiltersSelector(state);

	try {
		let filters = {...filtersInState};

		filters = {...filters, found_by_search_request: [activeSearchRequest?.id]};

		return buildCandidateApiRequestPayload(filters);
	} catch (e) {
		console.log(e);
	}

	return null;
};

export const removeCandidateFromAPList =
	(candidateId: number) => (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();
		const results = additionalProfileResultsSelector(state);
		const pagination = AdditionalProfilesPaginationInfoSelector(state);

		const data = {
			pagination: {
				...pagination,
				count: pagination.count - 1,
			},
			results: [...results].filter((item: SICandidate) => item.id !== candidateId),
		};

		dispatch(setAPActiveResult(data));
	};
export const removeCandidateFromRejectedList =
	(candidateId: number) => (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();
		const results = rejectedAdditionalProfileResultsSelector(state);
		const pagination = RejectedAdditionalProfilesPaginationInfoSelector(state);

		const rejectedData = {
			pagination: {
				...pagination,
				count: pagination.count - 1,
			},
			results: results.filter((item: SICandidate) => item.id !== candidateId),
		};

		dispatch(setAPRejectedProfilesResult(rejectedData));
	};
export const approveORejectAllCandidates =
	(isApproveAll: boolean) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const project = projectSelector(state);

		try {
			const apiPayload = dispatch(preparePayloadForCurrentSearch()) as unknown as Omit<
				ICandidateSearchApiRequestPayload,
				"pagination"
			>;

			if (apiPayload === null) {
				throw new Error("API payload can not be null when saving all or reject all");
			}

			const formData: STCandidateDecisionData = {
				status: isApproveAll ? "Approved" : "Rejected",
			};

			const queryParams = queryString.stringify(
				{
					...apiPayload.filters,
					...apiPayload.sorting_options,
				},
				{
					indices: false,
					arrayFormat: "comma",
					addQueryPrefix: true,
				},
			);

			if (isApproveAll) {
				await dispatch(saveCandidatesByFilters(apiPayload, false));
			} else {
				await mapxProfileRequestsApi.rejectCandidatesOnSearchRequests(
					project.id,
					queryParams,
					formData,
				);
			}
			await dispatch(getSearchRequestedProfilesByQuery(apiPayload, 1));

			const activeSearchRequest = activeSearchRequestSelector(state);
			dispatch(getSearchRequestDetails(activeSearchRequest.id));
		} catch (e) {
			console.log(`Error from modifying save or reject all Profile status: ${e}`);
		}
	};
/**
 * APPROVE CANDIDATE ON ADDITIONAL PROFILES RESULT
 * @param candidateId
 * @param removeFromRejectList
 */

export const approveCandidate =
	(candidateId: number, removeFromRejectList = false) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();
		const project = projectSelector(state);

		let response;

		try {
			const formData: STCandidateDecisionData = {status: "Approved"};

			response = await mapxProfileRequestsApi.modifyAdditionalProfileStatus(
				project.id,
				candidateId,
				formData,
			);

			if (response?.data) {
				await dispatch(getTargetListCompanies());

				if (removeFromRejectList) {
					dispatch(removeCandidateFromRejectedList(candidateId));
				} else {
					dispatch(removeCandidateFromAPList(candidateId));
				}
			} else {
				window.alert("Something went wrong");
			}
		} catch (e) {
			console.log(`Error from getting all Profile request: ${e}`);
		}
	};
/**
 * REJECT CANDIDATE ON ADDITIONAL PROFILES RESULT
 * @param candidate
 */

export const rejectCandidate =
	(candidate: SICandidate) =>
	async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const state = getState();

		const project = projectSelector(state);

		let response;

		const candidateId = candidate.id;

		try {
			const formData: STCandidateDecisionData = {status: "Rejected"};

			response = await mapxProfileRequestsApi.modifyAdditionalProfileStatus(
				project.id,
				candidateId,
				formData,
			);

			if (response?.data) {
				await dispatch(getTargetListCandidates());

				dispatch(removeCandidateFromAPList(candidateId));

				dispatch(getAPRejectedProfilesByProjectId(project.id));
			} else {
				window.alert("Something went wrong");
			}
		} catch (e) {
			console.log(`Error from getting all Profile request: ${e}`);
		}
	};
export const getAPRejectedProfilesByProjectId =
	(projectId: number, page = 1) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = `GET_REJECTED_PROFILES_${projectId}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		let response;

		try {
			// const query = `?rejected_candidates_project_id=${projectId}&page=${page}`;
			const payload = {
				filters: {rejected_candidates_project_id: projectId},
				pagination: {page},
			};

			response = await mapXCandidateApi.getCandidatesByFilter(payload, config);

			if (response?.status === 200 && response?.data) {
				if (page === 1) {
					dispatch(setAPRejectedProfilesResult(response.data));
				} else {
					dispatch(setAPMoreRejectedProfilesResult(response.data));
				}

				if (response.data.results.length > 0) {
					return response.data.results;
				} else return [];
			}

			return "cancelled";
		} catch (e) {
			console.log(`Error from getting rejected Profiles: ${e}`);

			return "cancelled";
		}
	};
