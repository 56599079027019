import {createSelector} from "reselect";
import {TRootState} from "types";
import {
	TKeywordPosition,
	TKeywordPositionConnectivityLogic,
} from "api/filterOptionsApi/KeywordApi/types";

export const keywordFilterConfigSelector = (state: TRootState) =>
	state.filter.candidate_filters.keyword_filter_config;

export const keywordCandidatePositionSelector = (state: TRootState) =>
	state.filter.candidate_filters.keyword_filter_config.active_position;

export const keywordCurrentPositionConnectivityLogicSelector = (state: TRootState) =>
	state.filter.candidate_filters.keyword_filter_config.active_current_position_connect_logic;

export const keywordPreviousPositionConnectivityLogicSelector = (state: TRootState) =>
	state.filter.candidate_filters.keyword_filter_config.active_previous_position_connect_logic;

export const keywordAnyPositionConnectivityLogicSelector = (state: TRootState) =>
	state.filter.candidate_filters.keyword_filter_config.active_any_position_connect_logic;

export const currentAndKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_text_keywords_and;
export const currentOrKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_text_keywords_or;
export const currentNotKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_text_keywords_not;

export const previousAndKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_text_keywords_and;
export const previousOrKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_text_keywords_or;
export const previousNotKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_text_keywords_not;

export const anyAndKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.text_keywords_and;
export const anyOrKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.text_keywords_or;
export const anyNotKeywordsSelector = (state: TRootState) =>
	state.filter.candidate_filters.text_keywords_not;

const getKeywordPosition = (_: TRootState, position: TKeywordPosition) => position;
const getKeywordPositionConnectLogic = (
	_: TRootState,
	__: TRootState,
	logic: TKeywordPositionConnectivityLogic,
) => logic;

export const keywordsByPositionSelector = createSelector(
	[
		currentAndKeywordsSelector,
		currentOrKeywordsSelector,
		currentNotKeywordsSelector,
		previousAndKeywordsSelector,
		previousOrKeywordsSelector,
		previousNotKeywordsSelector,
		anyAndKeywordsSelector,
		anyOrKeywordsSelector,
		anyNotKeywordsSelector,
		getKeywordPosition,
		getKeywordPositionConnectLogic,
	],
	(
		currentAndKeywords,
		currentOrKeywords,
		currentNotKeywords,
		previousAndKeywords,
		previousOrKeywords,
		previousNotKeywords,
		anyAndKeywords,
		anyOrKeywords,
		anyNotKeywords,
		position,
		logic,
	) => {
		if (position === "current" && logic === "and") {
			return currentAndKeywords || [];
		} else if (position === "current" && logic === "or") {
			return currentOrKeywords || [];
		} else if (position === "current" && logic === "not") {
			return currentNotKeywords || [];
		} else if (position === "previous" && logic === "and") {
			return previousAndKeywords || [];
		} else if (position === "previous" && logic === "or") {
			return previousOrKeywords || [];
		} else if (position === "previous" && logic === "not") {
			return previousNotKeywords || [];
		} else if (position === "any" && logic === "and") {
			return anyAndKeywords || [];
		} else if (position === "any" && logic === "or") {
			return anyOrKeywords || [];
		} else {
			return anyNotKeywords || [];
		}
	},
);

export const currentKeywordTagsSelector = createSelector(
	[currentAndKeywordsSelector, currentOrKeywordsSelector, currentNotKeywordsSelector],
	(andKeywords: [], orKeywords: [], notKeywords: []) => {
		return [...andKeywords, ...orKeywords, ...notKeywords];
	},
);
export const previousKeywordTagsSelector = createSelector(
	[previousAndKeywordsSelector, previousOrKeywordsSelector, previousNotKeywordsSelector],
	(andKeywords: [], orKeywords: [], notKeywords: []) => {
		return [...andKeywords, ...orKeywords, ...notKeywords];
	},
);
export const anyKeywordTagsSelector = createSelector(
	[anyAndKeywordsSelector, anyOrKeywordsSelector, anyNotKeywordsSelector],
	(andKeywords: [], orKeywords: [], notKeywords: []) => {
		return [...andKeywords, ...orKeywords, ...notKeywords];
	},
);

export const KeywordFilterKeyByPosition = createSelector(
	getKeywordPosition,
	getKeywordPositionConnectLogic,
	(position, logic) => {
		if (position === "current" && logic === "and") {
			return "current_text_keywords_and";
		} else if (position === "current" && logic === "or") {
			return "current_text_keywords_or";
		} else if (position === "current" && logic === "not") {
			return "current_text_keywords_not";
		} else if (position === "previous" && logic === "and") {
			return "previous_text_keywords_and";
		} else if (position === "previous" && logic === "or") {
			return "previous_text_keywords_or";
		} else if (position === "previous" && logic === "not") {
			return "previous_text_keywords_not";
		} else if (position === "any" && logic === "and") {
			return "text_keywords_and";
		} else if (position === "any" && logic === "or") {
			return "text_keywords_or";
		} else {
			return "text_keywords_not";
		}
	},
);

export const allKeywordsCountSelector = createSelector(
	[
		currentAndKeywordsSelector,
		currentOrKeywordsSelector,
		currentNotKeywordsSelector,
		previousAndKeywordsSelector,
		previousOrKeywordsSelector,
		previousNotKeywordsSelector,
		anyAndKeywordsSelector,
		anyOrKeywordsSelector,
		anyNotKeywordsSelector,
	],
	(
		currentAndKeywords,
		currentOrKeywords,
		currentNotKeywords,
		previousAndKeywords,
		previousOrKeywords,
		previousNotKeywords,
		anyAndKeywords,
		anyOrKeywords,
		anyNotKeywords,
	) =>
		currentAndKeywords.length +
		currentOrKeywords.length +
		currentNotKeywords.length +
		previousAndKeywords.length +
		previousOrKeywords.length +
		previousNotKeywords.length +
		anyAndKeywords.length +
		anyOrKeywords.length +
		anyNotKeywords.length,
);

export const allKeywordLabelSelector = createSelector(
	[
		currentAndKeywordsSelector,
		currentOrKeywordsSelector,
		currentNotKeywordsSelector,
		previousAndKeywordsSelector,
		previousOrKeywordsSelector,
		previousNotKeywordsSelector,
		anyAndKeywordsSelector,
		anyOrKeywordsSelector,
		anyNotKeywordsSelector,
	],
	(
		currentAndKeywords,
		currentOrKeywords,
		currentNotKeywords,
		previousAndKeywords,
		previousOrKeywords,
		previousNotKeywords,
		anyAndKeywords,
		anyOrKeywords,
		anyNotKeywords,
	) => [
		...currentAndKeywords,
		...currentOrKeywords,
		...currentNotKeywords,
		...previousAndKeywords,
		...previousOrKeywords,
		...previousNotKeywords,
		...anyAndKeywords,
		...anyOrKeywords,
		...anyNotKeywords,
	],
);
