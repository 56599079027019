import {type FC, useCallback} from "react";
import classNames from "classnames";

import Badge from "../../Badge";

import type {TTabTitleProps} from "./types";
import styles from "./tabTitle.module.scss";

const TabTitle: FC<TTabTitleProps> = ({
	title,
	extendedTitle,
	index,
	setSelectedTab,
	isActive,
	count,
	icon,
	className = null,
}) => {
	const handleOnClick = useCallback(() => {
		setSelectedTab(index);
	}, [setSelectedTab, index]);

	return (
		<li
			className={classNames(styles.tabTitle, className && className, {
				[styles.activeTab]: isActive,
			})}
		>
			<span
				role="button"
				onClick={handleOnClick}
				className={classNames(styles.title, {[styles.activeTab]: isActive})}
				title={extendedTitle || title}
			>
				{icon && icon} {title}
				{!!count && <Badge className={styles.count} count={`${count}`} />}
			</span>
		</li>
	);
};

export default TabTitle;
