import {TRootState} from "types";
import {createSelector} from "reselect";

export const generateCandidatesSummariesForAllProjectSelector = (state: TRootState) =>
	state.mapxProject.generateCandidatesSummaryByProject;

export const targetListIdSelector = (_: TRootState, id: number) => id;
export const candidateIdSelector = (_: TRootState, __: TRootState, candidateId: number) =>
	candidateId;

export const getAllGenerateSummaryCandidateIdsForCurrentProjectSelector = createSelector(
	generateCandidatesSummariesForAllProjectSelector,
	targetListIdSelector,
	(generateCandidatesSummariesForAllProject, tlId) => {
		if (tlId) {
			const objectKey =
				typeof generateCandidatesSummariesForAllProject === "object"
					? Object.keys(generateCandidatesSummariesForAllProject).find(
							(id) => id.toString() === tlId.toString(),
					  )
					: null;

			if (objectKey && generateCandidatesSummariesForAllProject[objectKey] !== undefined) {
				return generateCandidatesSummariesForAllProject[objectKey] || [];
			}
		}

		return [];
	},
);

export const isSummaryForCurrentCandidateInProgressForCurrentProjectSelector = createSelector(
	generateCandidatesSummariesForAllProjectSelector,
	targetListIdSelector,
	candidateIdSelector,
	(generateCandidatesSummariesForAllProject, tlId, candidateId) => {
		if (tlId && candidateId) {
			const objectKey =
				typeof generateCandidatesSummariesForAllProject === "object"
					? Object.keys(generateCandidatesSummariesForAllProject).find(
							(id) => id.toString() === tlId.toString(),
					  )
					: null;

			if (
				objectKey &&
				generateCandidatesSummariesForAllProject[objectKey] !== undefined &&
				Array.isArray(generateCandidatesSummariesForAllProject[objectKey])
			) {
				return generateCandidatesSummariesForAllProject[objectKey].includes(candidateId);
			}
		}

		return false;
	},
);
