import {type FC, Fragment, useCallback, useMemo, useState} from "react";
import {NavLink} from "react-router-dom";
import Highlighter from "react-highlight-words";
import useDebouncedEffect from "use-debounced-effect";

import {CompanyInfoTooltip, LazyLoadImageWrapper} from "components";
import {sanitizeStringText} from "helpers/map";
import {LineIcon, PlusIcon} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import useUserBehaviorTrackHook from "hooks/mapx/useUserBehaviorTrackHook";
import {setTrackedEventsData} from "store/mapx/events/eventTrackingActions";
import {tooltipCompaniesSelector} from "store/mapx/company/companySelectors";
import {updateTooltipCompaniesList} from "store/mapx/company/companyAsyncActions";
import {candidateSelectedKeywordLabelsSelector} from "store/mapx/candidate/candidateSelectors";
import {apCandidateSelectedKeywordLabelsSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";

import type {TCandidateExperienceProps, TExperience, TExperiencesType} from "./types";
import styles from "./candidateExperience.module.scss";
import experience from "mapx-components/Cards/ExperienceCard/Experience";

import type {SICandidatePosition} from "api/candidateApi/types";

const CandidateExperience: FC<TCandidateExperienceProps> = ({
	searchType,
	position,
	candidateId,
	isRecommended,
	fromQuickView = false,
	positions,
}) => {
	const dispatch = useAppDispatch();

	const keywordLabels = useAppSelector(candidateSelectedKeywordLabelsSelector);

	const apKeywordLabels = useAppSelector(apCandidateSelectedKeywordLabelsSelector);

	const [companyId, setCompanyId] = useState<string>("");

	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const [isMouseEnter, setIsMouseEnter] = useState<boolean>(false);

	const [eventRecordedForRole, setEventRecordedForRole] = useState<boolean>(false);

	const {itemEventDetails} = useUserBehaviorTrackHook({
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		position,
		source: searchType,
		isRecommended,
		itemId: candidateId,
		itemType: "candidate",
		defaultEvent: "expanded",
	});

	const tooltipId = `${searchType}-${candidateId}`;

	const tooltipCompanies = useAppSelector(tooltipCompaniesSelector);

	const companyToWatch = companyId ? tooltipCompanies[companyId] : null;

	const isAIResultsType = useMemo(() => {
		return (
			searchType === "Additional Profiles" ||
			searchType === "Free Text Input Search" ||
			searchType === "More Like This" ||
			searchType === "Rejected Profiles"
		);
	}, [searchType]);

	const shouldSearchHighlight = useMemo(() => isAIResultsType, [isAIResultsType]);

	const groupedExperiencesByCompany = useMemo(() => {
		// @TODO need to add type for experiencesDuplicate
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const experiencesDuplicate: any[] = [];
		const companyIds = new Set();

		positions?.forEach((exp: SICandidatePosition) => {
			const {company, ...rest} = exp;

			if (companyIds.has(company.id)) {
				const index = experiencesDuplicate.findIndex(
					(fnd) => fnd.company.id === company.id,
				);

				experiencesDuplicate[index] = {
					company,
					details: [...experiencesDuplicate[index].details, rest],
				};
			} else {
				companyIds.add(company.id);
				experiencesDuplicate.push({company, details: [rest]});
			}
		});

		return experiencesDuplicate;
	}, [positions]);

	useDebouncedEffect(
		() => {
			if (companyId && isMouseEnter) {
				dispatch(updateTooltipCompaniesList(companyId));
			}
		},
		1000,
		[companyId, isMouseEnter],
	);

	const mouseEnterHandler = (id: string) => {
		setIsMouseEnter(true);
		setCompanyId(id);
	};

	const hasDescriptions = positions?.some((val: SICandidatePosition) => val?.description);

	const getJobTitleWithHighlighted = useCallback(
		(description: string) => {
			const allowed = shouldSearchHighlight || fromQuickView;

			const additionalOrNot = isAIResultsType ? apKeywordLabels : keywordLabels;

			if (additionalOrNot && allowed) {
				return (
					<Highlighter
						autoEscape={false}
						searchWords={additionalOrNot}
						textToHighlight={description}
						highlightClassName={styles.highlighter}
					/>
				);
			} else {
				return description;
			}
		},
		[shouldSearchHighlight, fromQuickView, isAIResultsType, apKeywordLabels, keywordLabels],
	);

	const getPositionEndDate = (date: Nullable<string>, isCurrent?: boolean | undefined) => {
		if (isCurrent !== undefined) {
			return isCurrent ? "Present" : date ? new Date(date).getFullYear() : "n/a";
		} else {
			return date ? new Date(date).getFullYear() : "Present";
		}
	};

	return (
		<Fragment>
			<div className={styles.workList}>
				<div className={styles.expandContainer}>
					{hasDescriptions && fromQuickView && (
						<button
							className={styles.expandButton}
							onClick={() => {
								setIsExpanded((prev) => !prev);

								if (!isExpanded) {
									if (!eventRecordedForRole) {
										dispatch(setTrackedEventsData(itemEventDetails));
										setEventRecordedForRole(true);
									}
								}
							}}
						>
							{isExpanded ? <LineIcon /> : <PlusIcon />}
							<span>
								{isExpanded ? "Collapse" : "Expand"} role
								{experience.length === 1 ? "" : "s"}
							</span>
						</button>
					)}
				</div>

				{[...groupedExperiencesByCompany].map((exp: TExperience, idx) => (
					<div key={idx} className={styles.experience}>
						<span
							data-tooltip-id={tooltipId}
							className={styles.companyName}
							data-tooltip-delay-show={1000}
							onMouseOut={() => setIsMouseEnter(false)}
							onBlur={() => setIsMouseEnter(false)}
							onMouseEnter={() => mouseEnterHandler(String(exp?.company?.id))}
						>
							<NavLink
								target="_blank"
								rel="noreferrer"
								to={`/company/${exp.company.id}`}
							>
								<div
									className={styles.companyName__skeleton}
									onMouseOut={() => setIsMouseEnter(false)}
									onBlur={() => setIsMouseEnter(false)}
									onMouseEnter={() => mouseEnterHandler(String(exp?.company?.id))}
								/>
								<LazyLoadImageWrapper
									onMouseOut={() => setIsMouseEnter(false)}
									onMouseEnter={() => mouseEnterHandler(String(exp?.company?.id))}
									alt={exp && exp.company.name}
									image={
										exp.company.logo_url !== null
											? exp.company.logo_url
											: "https://storage.googleapis.com/nebula-static/logos100x100/empty_logo.png"
									}
									className={styles.companyLogo}
								/>
								{exp.company.name}
							</NavLink>
						</span>

						<span className={styles.details}>
							{exp.details.map((detail: TExperiencesType, dx) => (
								<div key={`${idx}${dx}${detail.position_id}`}>
									<div className={styles.detail}>
										{detail.start_date ? (
											<span className={styles.years}>
												{`${new Date(
													detail.start_date,
												).getFullYear()} - ${getPositionEndDate(
													detail.end_date,
													detail.is_current,
												)}`}
											</span>
										) : (
											<span className={styles.years}>{`n/a - n/a`}</span>
										)}
										<span className={styles.jobTitle}>
											{getJobTitleWithHighlighted(detail.job_title)}
										</span>
									</div>

									{/* HIGHLIGHTER PLUGIN DOES NOT WORK WITH HTML SO HERE WE BROKE THE TEXT BY BREAK LINE AND MADE IT CHUNKS AND THEN RENDERED*/}
									{detail.description !== null && isExpanded && (
										<div className={styles.description}>
											{sanitizeStringText(detail.description)
												.split("<br/>")
												.map((chunk, index) => (
													<div key={index}>
														{index > 0 && (
															<span
																style={{
																	display: "flex",
																	paddingBottom: 5,
																}}
															/>
														)}
														{getJobTitleWithHighlighted(chunk)}
													</div>
												))}
										</div>
									)}
								</div>
							))}
						</span>
					</div>
				))}
			</div>

			<CompanyInfoTooltip id={tooltipId} isInCompanyPage company={companyToWatch} />
		</Fragment>
	);
};

export default CandidateExperience;
