import mapxProjectApi from "api/projectApi";
import {LockIcon, PencilIconButton} from "assets/icons";
import {Loader, UpgradeNowTooltip} from "components";
import {
	prepareDateForFileNameSuffix,
	prepareSpreadSheetFileAndDownload,
} from "helpers/documentHelpers";
import {Mixpanel} from "helpers/mixpanel";
import {useAppDispatch, useOutsideClick} from "hooks";
import useTrackProjectDetails from "mapx-pages/Project/ProjectTitle/TrackProjectDetails";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {HiOutlineDownload} from "react-icons/hi";
import {useSelector} from "react-redux";
import {
	editProjectModalSelector,
	projectSelector,
} from "store/mapx/project-list/projectListSelectors";
import {
	isStarterTypeUserSelector,
	userEmailSelector,
	userSelector,
} from "store/mapx/user/userSelectors";
import colors from "styles/themes.module.scss";

import css from "./projectTitle.module.scss";
import {TProjectTitleProps} from "mapx-pages/Project/ProjectTitle/types";
import ShareIcon from "assets/icons/ShareIcon";
import {STProject} from "api/projectApi/types";
import {editProjectModal, setProjectForm} from "store/mapx/project-list/projectListActions";
import ProjectEditModal from "mapx-components/Modals/ProjectEditModal";
import useLocationSearchService from "services/useLocationSearchService";

export default function ProjectTitle({
	loading,
	projectID,
	displayDownloadButton = true,
}: TProjectTitleProps) {
	const project: STProject = useSelector(projectSelector);

	const displayEditModal = useSelector(editProjectModalSelector);

	const projectModalRef = useRef(null);

	const [downloadProcessing, setDownloadProcessing] = useState(false);

	const {locations} = useLocationSearchService();

	const user = useSelector(userSelector);

	const userEmail = useSelector(userEmailSelector);

	const dispatch = useAppDispatch();

	const isStarterTypeUser = useSelector(isStarterTypeUserSelector);

	const handleDownloadExcel = useCallback(async () => {
		if (isStarterTypeUser) return;

		setDownloadProcessing(true);

		const projectName = project.name !== null ? project.name : "Unnamed Project";
		const formattedDate = prepareDateForFileNameSuffix();
		const fileName = `${projectName}-${formattedDate}`;

		await mapxProjectApi.exportProject(projectID).then((res) => {
			prepareSpreadSheetFileAndDownload(res.data, fileName);
		});
		setDownloadProcessing(false);

		Mixpanel.track(`Project Downloaded`, {
			name: `${project.name}`,
			pageTitle: `${project.name}`,
			url: window.location.pathname,
			distinct_id: userEmail,
		});
	}, [isStarterTypeUser, project?.name, projectID, userEmail]);

	const LoaderComponent = (
		<Loader
			height={24}
			width={24}
			type="ThreeDots"
			customStyle={{display: "flex", alignItems: "center", margin: "0 5px"}}
			color={colors.loaderDotColor}
		/>
	);

	/**
	 * HANDLING PROJECT EDIT
	 * @param projectId
	 * @returns {Promise<void>}
	 */

	const [onlyProjectShareOption, setOnlyProjectShareOption] = useState(false);

	useOutsideClick(projectModalRef, () => {
		dispatch(editProjectModal(false));
	});

	const formData = useMemo(() => {
		return {
			id: project.id,
			name: project.name,
			location: project.location ? locations.find((l) => l.id === project.location) : null,
			users:
				project.users && project.users?.length > 0
					? [...project.users].filter((u) => u.id !== user.user_id)
					: [],
		};
	}, [locations, project, user]);

	const handleEditProjectClick = (project: STProject, onlyShare = false) => {
		dispatch(editProjectModal(true));

		dispatch(setProjectForm(formData));

		setOnlyProjectShareOption(onlyShare);
	};

	useEffect(() => {
		if (displayEditModal) {
			dispatch(setProjectForm(formData));

			// if (editProjectModalProps && editProjectModalProps?.only_share) {
			//     setOnlyProjectShareOption(true);
			// }
		}
	}, [dispatch, displayEditModal, formData]);

	/**
	 * MIX PANEL & GOOGLE ANALYTICS TRACK LOG
	 */
	useTrackProjectDetails();

	/**
	 * DOWNLOAD BLOCK LOCKING
	 */

	const LOCK_ICON_COMPONENT = <LockIcon className={css.downloadIcon} />;

	const DOWNLOAD_ICON_COMPONENT = <HiOutlineDownload size={18} className={css.downloadIcon} />;

	const [activeIcon, setActiveIcon] = useState(DOWNLOAD_ICON_COMPONENT);

	return (
		<div className={css.projectTitle}>
			<div className={css.titleContainer}>
				<h3 className={css.titleContainer__text}>
					{project && project.name !== null ? project.name : "Unnamed Project"}
				</h3>
			</div>

			{!loading && (
				<div className={css.buttonContainer}>
					<span
						className={css.shareButton}
						style={{cursor: "pointer"}}
						onClick={() => handleEditProjectClick(project, true)}
					>
						Share <ShareIcon />
					</span>

					{displayDownloadButton &&
						(downloadProcessing ? (
							LoaderComponent
						) : (
							<span
								className={css.downloadButton}
								onClick={handleDownloadExcel}
								onMouseEnter={() => {
									if (isStarterTypeUser) {
										setActiveIcon(LOCK_ICON_COMPONENT);
									}
								}}
								onMouseLeave={() => {
									if (isStarterTypeUser) {
										setActiveIcon(DOWNLOAD_ICON_COMPONENT);
									}
								}}
								data-tooltip-id={"downloadProjectReport"}
							>
								{activeIcon}{" "}
								{isStarterTypeUser && (
									<UpgradeNowTooltip id={"downloadProjectReport"} />
								)}
							</span>
						))}

					<PencilIconButton
						className={css.editIcon}
						style={{cursor: "pointer"}}
						onClick={() => handleEditProjectClick(project)}
					/>
				</div>
			)}

			{displayEditModal && <ProjectEditModal onlyShareOption={onlyProjectShareOption} />}
		</div>
	);
}
