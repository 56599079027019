import {confirmAlert} from "react-confirm-alert";
import classNames from "classnames";

import "styles/custom/_confrmAlert.scss";

import type {TCustomConfirmAlertParams} from "./types";

const customConfirmAlert = ({
	handlePressYes,
	title = "Are you sure?",
	message = "You want to delete this file?",
	yesButtonLabel = "Yes",
	noButtonLabel = "No",
	afterClose,
	actionButtonPrefixIcon = null,
	alertType = "success",
}: TCustomConfirmAlertParams): void => {
	const alertHeaderIconColor = () => {
		if (alertType === "success") {
			return "#0C5850";
		} else if (alertType === "error") {
			return "#C91515";
		} else {
			return "#FFC34C";
		}
	};

	const buttonTypeClass = alertType === "error" ? "btn-red" : "btn-green";

	return confirmAlert({
		title,
		message,
		customUI: ({onClose}) => {
			return (
				<div className="react-confirm-alert-body">
					<div className="react-confirm-alert-icon">
						<svg
							width="64"
							height="64"
							viewBox="0 0 64 64"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask
								id="mask0_1041_43017"
								style={{maskType: "alpha"}}
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="64"
								height="64"
							>
								<rect width="64" height="64" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_1041_43017)">
								<path
									d="M31.9993 44.6C32.5327 44.6 32.966 44.4333 33.2994 44.1C33.6327 43.7667 33.7994 43.3333 33.7994 42.8C33.7994 42.2667 33.6327 41.8333 33.2994 41.5C32.966 41.1667 32.5327 41 31.9993 41C31.466 41 31.0327 41.1667 30.6993 41.5C30.366 41.8333 30.1993 42.2667 30.1993 42.8C30.1993 43.3333 30.366 43.7667 30.6993 44.1C31.0327 44.4333 31.466 44.6 31.9993 44.6ZM30.666 35.2H33.666V18.6H30.666V35.2ZM31.9993 57.3333C28.4882 57.3333 25.1994 56.6667 22.1327 55.3333C19.066 54 16.3882 52.1889 14.0993 49.9C11.8105 47.6111 9.99935 44.9333 8.66602 41.8667C7.33268 38.8 6.66602 35.5111 6.66602 32C6.66602 28.4889 7.33268 25.1889 8.66602 22.1C9.99935 19.0111 11.8105 16.3333 14.0993 14.0667C16.3882 11.8 19.066 10 22.1327 8.66667C25.1994 7.33334 28.4882 6.66667 31.9993 6.66667C35.5105 6.66667 38.8105 7.33334 41.8993 8.66667C44.9882 10 47.666 11.8 49.9327 14.0667C52.1994 16.3333 53.9994 19.0111 55.3327 22.1C56.666 25.1889 57.3327 28.4889 57.3327 32C57.3327 35.5111 56.666 38.8 55.3327 41.8667C53.9994 44.9333 52.1994 47.6111 49.9327 49.9C47.666 52.1889 44.9882 54 41.8993 55.3333C38.8105 56.6667 35.5105 57.3333 31.9993 57.3333ZM32.066 54.3333C38.2438 54.3333 43.4994 52.1556 47.8327 47.8C52.166 43.4445 54.3327 38.1556 54.3327 31.9333C54.3327 25.7556 52.166 20.5 47.8327 16.1667C43.4994 11.8333 38.2216 9.66667 31.9993 9.66667C25.8216 9.66667 20.5549 11.8333 16.1993 16.1667C11.8438 20.5 9.66602 25.7778 9.66602 32C9.66602 38.1778 11.8438 43.4445 16.1993 47.8C20.5549 52.1556 25.8438 54.3333 32.066 54.3333Z"
									fill={alertHeaderIconColor()}
								/>
							</g>
						</svg>
					</div>
					<h1>{title}</h1>
					<p>{message}</p>

					<div className="react-confirm-alert-button-group">
						<button
							onClick={() => {
								onClose();

								if (afterClose) {
									afterClose();
								}
							}}
							className="btn btn-white"
						>
							{noButtonLabel}
						</button>
						<button
							className={classNames("btn", buttonTypeClass)}
							onClick={() => {
								handlePressYes();
								onClose();
							}}
						>
							{actionButtonPrefixIcon !== null && actionButtonPrefixIcon}{" "}
							{yesButtonLabel}
						</button>
					</div>
				</div>
			);
		},
	});
};

export default customConfirmAlert;
