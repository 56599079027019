import React, {useCallback, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {ToastContent} from "components";
import {
	candidateImportsByLinkedInForProjectSelector,
	isModalDisplayedForProject,
	searchCandidatesForProjectSelector,
	showLastBulkImportReportSelector,
} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {toast} from "react-toastify";
import {
	setImportLinkedInProfileInfoForProject,
	updateImportLinkedInURLsForProject,
} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {getIndividualBulkImportStatuses} from "store/mapx/linkedin-bulk-import/linkedInProfilesImportAsyncActions";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {setProject, setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {
	TImportLinkedinProfileInfoForProject,
	TUrlList,
} from "mapx-components/AddPeopleByLinkedIn/types";
import {useAppDispatch, useAppSelector} from "hooks/index";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {TRootState} from "types";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {lastCompletedCandidateImportByLinkedInForProjectSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import {setLastCompleteImportLinkedInProfileInfoForProject} from "store/mapx/background-tasks/backgroundTaskActions";
import {TLinkedinBulkImportIndividualStatusResponse} from "../types";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const projectIntervalId = {} as any;

const useBulkImportLinkedInProfileProgressWatcher = () => {
	const history = useHistory();

	const project = useAppSelector(projectSelector);

	const activeProjectInState = useAppSelector(projectSelector);

	const showLastBulkImportReport = useAppSelector(showLastBulkImportReportSelector);

	const lastCompletedCandidateImportByLinkedInForProject = useAppSelector(
		lastCompletedCandidateImportByLinkedInForProjectSelector,
	);

	const currentTargetListID = useAppSelector(targetListIDSelector);

	const importLinkedInProfileForProjects = useAppSelector(
		candidateImportsByLinkedInForProjectSelector,
	);

	const importsInProgress = useAppSelector(searchCandidatesForProjectSelector);

	const activeAddPeopleModalForProject = useAppSelector(isModalDisplayedForProject);

	const dispatch = useAppDispatch();

	const handleSeeProjectClick = useCallback(
		async (projectID: number) => {
			if (
				activeProjectInState === null ||
				activeProjectInState?.id.toString() !== projectID.toString()
			) {
				dispatch(clearAPWorkflowState());
				dispatch(setProject(importsInProgress.project));
				dispatch(getTargetListCandidates());
				dispatch(getTargetListCompanies());
			}

			dispatch(setProjectTabIndex(1));
			history.push(`/project/${projectID}`);

			toast.dismiss("import_success_toaster");
			toast.dismiss("import_error_toaster");
		},
		[dispatch, activeProjectInState, history, importsInProgress],
	);

	const isImportInProgress = (status: string) => {
		return status === "Created" || status === "In Progress" || status === "Scraped";
	};

	const updateImportInfo = useCallback(
		async (response: TRootState, data: TImportLinkedinProfileInfoForProject) => {
			let candidate = null;
			if (response.status === "Completed") {
				candidate = response.candidate;
			}

			const updatedData = {
				...data,
				importInfo: response.data,
				reason: response.reason,
				status: response.status || "In Progress",
				candidate,
			};

			if (data.importInfo) {
				dispatch(
					setImportLinkedInProfileInfoForProject({
						id: data.importInfo.id,
						reason: response.reason,
						data: updatedData,
					}),
				);
			}

			// Prepare an array of updates
			const updates: {projectID: number; url: string; reason: string; status: string}[] = [];
			updatedData.importInfo?.linkedin_candidate_urls?.forEach(
				(url: {url: string; status: string}) => {
					const notFinishedStateValues = [
						"waiting",
						"importing",
						"scraping",
						"in progress",
						"created",
						"scraped",
					];

					let status = url.status;
					let reason = response.data.reason;

					if (
						(response.status === "Completed" || response.data.status === "Error") &&
						notFinishedStateValues.includes(url.status.toLowerCase())
					) {
						status = "Scraping Error";
						reason =
							"This profile has been sent for manual import. If it is a valid URL, it will be visible on MapX within 12 hours";
					}

					// Accumulate the updates instead of dispatching inside the loop
					updates.push({
						projectID: data.id,
						url: url.url,
						status,
						reason,
					});
				},
			);

			// Dispatch all updates at once
			dispatch(updateImportLinkedInURLsForProject(updates));

			const hasAddedPeople = updatedData.importInfo?.linkedin_candidate_urls?.some(
				(item: TUrlList) =>
					["success", "imported", "completed", "already exists"].includes(
						item.status?.toLowerCase(),
					),
			);

			if (hasAddedPeople) {
				dispatch(
					updateProjectSuccessChecklistItem({
						attribute: "has_saved_people",
						value: true,
					}),
				);
			}
		},
		[dispatch],
	);

	const handleSuccessToast = useCallback(
		async (projectID: number) => {
			if (!toast.isActive("import_error_toaster")) {
				toast.success(ToastContent, {
					toastId: "import_success_toaster",
					autoClose: false,
					closeOnClick: false,
					data: {
						title: "Import is completed",
						description: () => (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "2px",
									fontSize: "12px",
								}}
							>
								<p style={{width: "190px"}}>
									Import is successfully completed without errors, click on report
									to see imported profiles.
								</p>
								<button
									style={{
										backgroundColor: "#309161",
										color: "#ECF9F2",
										borderRadius: "5px",
										marginTop: "-20px",
										width: "60px",
										cursor: "pointer",
									}}
									onClick={() => handleSeeProjectClick(projectID)}
								>
									Report
								</button>
							</div>
						),
					},
					delay: 100,
				});
			}
		},
		[handleSeeProjectClick],
	);

	const handleErrorToast = useCallback(
		async (projectID: number) => {
			if (!toast.isActive("import_success_toaster")) {
				toast.warning(ToastContent, {
					toastId: "import_error_toaster",
					autoClose: false,
					closeOnClick: false,
					data: {
						title: "Import is completed with errors.",
						description: () => (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "2px",
									fontSize: "12px",
								}}
							>
								<p style={{width: "190px"}}>
									Import is completed with errors, click on report to see imported
									profiles.
								</p>
								<button
									style={{
										backgroundColor: "#CC8800",
										color: "#ECF9F2",
										borderRadius: "5px",
										marginTop: "-20px",
										width: "60px",
										cursor: "pointer",
									}}
									onClick={() => handleSeeProjectClick(projectID)}
								>
									Report
								</button>
							</div>
						),
					},
					delay: 100,
				});
			}
		},
		[handleSeeProjectClick],
	);

	const updateImportHistoryOnState = useCallback(
		(response: TLinkedinBulkImportIndividualStatusResponse, urls: TUrlList[]) => {
			const previouslyImportedData =
				lastCompletedCandidateImportByLinkedInForProject[response.data.project_id]
					?.importInfo?.linkedin_candidate_urls || [];

			const mergedData = {
				id: response.data.project_id,
				status: "Completed",
				importInfo: {
					linkedin_candidate_urls: [...urls],
				},
			};

			previouslyImportedData.forEach((item: TUrlList) => {
				mergedData.importInfo.linkedin_candidate_urls.push(item);
			});

			const payload = {id: response.data.project_id, data: mergedData};

			dispatch(setLastCompleteImportLinkedInProfileInfoForProject(payload));
		},
		[lastCompletedCandidateImportByLinkedInForProject, dispatch],
	);

	useEffect(() => {
		if (importLinkedInProfileForProjects) {
			for (const [key, value] of Object.entries(importLinkedInProfileForProjects)) {
				const data: TImportLinkedinProfileInfoForProject | null =
					key && value ? (value as TImportLinkedinProfileInfoForProject) : null;

				if (data && isImportInProgress(data?.status)) {
					const importInfo = data.importInfo;

					const isImporting = importInfo !== null && importInfo?.status !== undefined;

					const inProgress = isImportInProgress(data?.status);

					if (importInfo) {
						projectIntervalId[importInfo?.id] = null;
					}

					if (isImporting && inProgress) {
						projectIntervalId[importInfo.id] = setInterval(async () => {
							const response = await dispatch(
								getIndividualBulkImportStatuses(importInfo.id),
							);

							await updateImportInfo(response, data);
							if (response.status === "Completed") {
								await dispatch(getTargetListCandidates());

								const urls = response.data.linkedin_candidate_urls;

								updateImportHistoryOnState(response, urls);

								if (urls.length > 0) {
									const errorImportingCount: number = urls.filter(
										(item: TUrlList) =>
											["bad url", "not found"].includes(
												item.status?.toLowerCase(),
											),
									).length;

									if (response.data.progress === 100) {
										toast.dismiss("import_started_toaster");

										const paramsProjectId = Number(
											location.pathname.replace("/project/", ""),
										);

										if (paramsProjectId === response.data.project_id) {
											return;
										}

										if (errorImportingCount === 0) {
											await handleSuccessToast(response.data.project_id);
										} else {
											await handleErrorToast(response.data.project_id);
										}
									}
								}

								clearInterval(projectIntervalId[importInfo.id]);
							}
						}, 15 * 1000);
					}

					return () => {
						if (importInfo && projectIntervalId[importInfo?.id]) {
							clearInterval(projectIntervalId[importInfo?.id]);
						}
					};
				}
			}
		}
	}, [
		importLinkedInProfileForProjects,
		dispatch,
		activeAddPeopleModalForProject,
		project?.id,
		currentTargetListID,
		updateImportInfo,
		handleErrorToast,
		handleSuccessToast,
		showLastBulkImportReport,
		lastCompletedCandidateImportByLinkedInForProject,
		updateImportHistoryOnState,
	]);
};

export default useBulkImportLinkedInProfileProgressWatcher;
