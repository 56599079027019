import axios, {CanceledError} from "axios";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {
	filteredSavedPeopleFetchCompleted,
	filteredSavedPeopleFetchIsInProgress,
	getTargetListCandidatesDone,
	getTargetListCandidatesFail,
	getTargetListCandidatesInit,
} from "store/mapx/target-list/targetListActions";
import mapXCandidateApi from "api/candidateApi";
import {successResponse} from "helpers/map";
import {Dispatch} from "react";
import {TAppDispatch, TRootState} from "types";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";

// eslint-disable-next-line
const cancelTokens = {} as any;

export const getTargetListCandidates =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: () => TRootState) => {
		const requestKey = "GET_TARGETLIST_CANDIDATES";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		try {
			const state = getState();

			const targetListID = targetListIDSelector(state);

			dispatch(getTargetListCandidatesInit());

			const apiPayload: ICandidateSearchApiRequestPayload = {
				filters: {
					target_list: targetListID,
				},
				pagination: {
					page: 1,
					per_page: 10000,
				},
			};

			const response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, {
				cancelToken: cancelTokens[requestKey].token,
			});

			if (successResponse(response, 200)) {
				const data = response.data;

				dispatch(getTargetListCandidatesDone(data));
			} else if (response == null) {
				// @TODO: Work on better implementation that does not rely on getting
				// "undefined" for cancels. Edit Api client to not catch errors.
				throw new CanceledError("Operation canceled due to new request.");
			} else {
				dispatch(getTargetListCandidatesFail({error: "something went wrong"}));
			}

			delete cancelTokens[requestKey];
		} catch (error) {
			if (!axios.isCancel(error)) {
				dispatch(getTargetListCandidatesFail({error}));
				delete cancelTokens[requestKey];
			}
		}
	};

export const getFilteredSavedPeople =
	(payload: Omit<ICandidateSearchApiRequestPayload, "pagination">) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = "GET_FILTERED_SAVED_PEOPLE";

		if (cancelTokens[requestKey]) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		try {
			dispatch(filteredSavedPeopleFetchIsInProgress(true));

			const apiPayload: ICandidateSearchApiRequestPayload = {
				...payload,
				pagination: {
					page: 1,
					per_page: 10000,
				},
			};

			const response = await mapXCandidateApi.getCandidatesByFilter(apiPayload, {
				cancelToken: cancelTokens[requestKey].token,
			});

			if (successResponse(response, 200)) {
				const data = response.data;

				dispatch(filteredSavedPeopleFetchCompleted(data));
			} else {
				dispatch(filteredSavedPeopleFetchIsInProgress(false));
			}

			delete cancelTokens[requestKey];
		} catch (error) {
			if (!axios.isCancel(error)) {
				delete cancelTokens[requestKey];
			}

			dispatch(filteredSavedPeopleFetchIsInProgress(false));
		}
	};
