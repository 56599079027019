import React, {useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import {ActionAltButton} from "mapx-components/index";
import classNames from "classnames";
import {MagicWandIcon} from "assets/icons";
import {TProjectCandidateSummary} from "mapx-components/Cards/ProjectCandidateCard/CandidateSummary/types";
import ContentLoader from "react-content-loader";
import {generateCandidatesSummary} from "store/mapx/project/generateCandidateSummaryAsyncActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	generateCandidatesSummariesForAllProjectSelector,
	isSummaryForCurrentCandidateInProgressForCurrentProjectSelector,
} from "store/mapx/project/generateCandidateSummarySelectors";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";

const CandidateSummary = ({id, summary, expanded}: TProjectCandidateSummary) => {
	const dispatch = useAppDispatch();

	const targetListID = useAppSelector(targetListIDSelector);

	const [creatingRequest, setCreatingRequest] = useState(false);

	const isSummaryForCurrentCandidateInProgressForCurrentProject = useAppSelector<boolean>(
		(state) =>
			isSummaryForCurrentCandidateInProgressForCurrentProjectSelector(
				state,
				targetListID,
				id,
			),
	);

	const currentSummariesAll = useAppSelector(generateCandidatesSummariesForAllProjectSelector);

	const targetListId = useAppSelector(targetListIDSelector);

	const generatingSummary = useMemo(
		() => creatingRequest || isSummaryForCurrentCandidateInProgressForCurrentProject,
		[creatingRequest, isSummaryForCurrentCandidateInProgressForCurrentProject],
	);

	const handleGenerateButtonClick = useCallback(async () => {
		setCreatingRequest(true);
		await dispatch(generateCandidatesSummary([id]));
		setCreatingRequest(false);
	}, [dispatch, id]);

	return (
		<div className={styles.summaryBlock}>
			<div className={styles.summaryActionWrapper}>
				<div className={styles.summaryTitle}>Summary</div>

				{!summary && (
					<ActionAltButton
						key={"expand"}
						customClass={classNames(styles.generateSummaryButton, {
							[styles.loading]: generatingSummary,
						})}
						iconComponent={<MagicWandIcon color={"#4E5555"} />}
						handleClick={handleGenerateButtonClick}
						title={generatingSummary ? "Generating" : "Generate"}
						loading={generatingSummary}
					/>
				)}
			</div>

			<div className={styles.description}>
				{!summary && !generatingSummary && "Click Generate button to add summary..."}
				{!!summary &&
					!generatingSummary &&
					(expanded ? summary : `${summary.substring(0, 500)}...`)}

				{currentSummariesAll[targetListId]?.includes(id) && (
					<ContentLoader
						style={{marginTop: 10}}
						speed={2}
						height={180}
						width={"100%"}
						backgroundColor="#EEEDEC"
						foregroundColor="#e6e6e6"
					>
						<rect x="1" y="8.8" width="99%" height="22" />
						<rect x="1" y="38.8" width="96%" height="22" />
						<rect x="1" y="68.8" width="98%" height="22" />
						<rect x="1" y="98.8" width="94%" height="22" />
						<rect x="1" y="128.8" width="97%" height="22" />
						<rect x="1" y="158.8" width="92%" height="22" />
					</ContentLoader>
				)}
			</div>
		</div>
	);
};

export default CandidateSummary;
