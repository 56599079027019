import {
	ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT,
	CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
	CLEAR_SIMILAR_CANDIDATES_RESULTS,
	FLAG_CANDIDATE,
	GET_CANDIDATES_BY_FILTER_DONE,
	GET_CANDIDATES_BY_FILTER_INIT,
	GET_CANDIDATES_DONE,
	GET_CANDIDATES_FAIL,
	GET_CANDIDATES_INIT,
	GET_SIMILAR_CANDIDATES_DONE,
	GET_SIMILAR_CANDIDATES_FAIL,
	GET_SIMILAR_CANDIDATES_INIT,
	GET_SINGLE_CANDIDATE_DONE,
	GET_SINGLE_CANDIDATE_FAIL,
	GET_SINGLE_CANDIDATE_INIT,
	IMPORT_LINKEDIN_PROFILE_IN_PROGRESS,
	LOGOUT,
	RESET_FILTERED_CANDIDATES,
	SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_PROJECT,
	SET_IMPORT_LINKEDIN_PROFILE_INFO,
	SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
	SET_SIMILAR_CANDIDATES_WITH_PAGINATION,
} from "../mapXActionTypes";

export const candidateInitialState = {
	candidates: [],
	paginationInfo: null,
	currentCandidate: null,
	candidateError: false,
	candidatesError: false,
	candidatesInProgress: false,
	filteredCandidatesInProgress: false,
	candidateProfileInProgress: false,
	similarCandidates: [],
	similarCandidatesPaginationInfo: null,
	similarCandidatesError: false,
	similarCandidatesInProgress: false,
	importLinkedInCandidateInProgress: false,
	importLinkedInProfileInfo: null,

	candidateImportByLinkedInForProject: {}, // used for project // Structure projectId: Info
	searchCandidateForProject: {}, // projectId: true/false, if link doesn't exist on database, we trigger scraping
	activeAddPeopleModalForProject: null, // can be null or id
};

const candidateReducer = (state = candidateInitialState, action) => {
	switch (action.type) {
		case GET_CANDIDATES_INIT:
			return {...state, candidatesInProgress: true};
		case GET_CANDIDATES_DONE:
			const prevCandidates = action.payload.pageNumber > 1 ? state.candidates : [];
			const candidates = [...prevCandidates, ...action.payload.data];
			const paginationInfo = action.payload.paginationInfo;

			return {
				...state,
				candidatesInProgress: false,
				filteredCandidatesInProgress: false,
				candidates,
				paginationInfo,
			};
		case GET_CANDIDATES_FAIL:
			return {
				...state,
				candidatesInProgress: false,
				filteredCandidatesInProgress: false,
				candidatesError: true,
			};

		case GET_CANDIDATES_BY_FILTER_INIT:
			return {...state, filteredCandidatesInProgress: true};
		case GET_CANDIDATES_BY_FILTER_DONE:
			const prevFilteredCandidates = action.payload.pageNumber > 1 ? state.candidates : [];
			const filteredCandidates = [...prevFilteredCandidates, ...action.payload.data];
			const filteredPaginationInfo = action.payload.paginationInfo;

			return {
				...state,
				candidatesInProgress: false,
				filteredCandidatesInProgress: false,
				candidates: filteredCandidates,
				paginationInfo: filteredPaginationInfo,
			};

		case GET_SIMILAR_CANDIDATES_INIT:
			return {...state, similarCandidatesInProgress: true};
		case GET_SIMILAR_CANDIDATES_DONE:
			const similarCandidates = [...action.payload];

			return {
				...state,
				similarCandidates,
				similarCandidatesInProgress: false,
			};
		case GET_SIMILAR_CANDIDATES_FAIL:
			return {
				...state,
				similarCandidatesError: true,
			};
		case CLEAR_SIMILAR_CANDIDATES_RESULTS:
			return {
				...state,
				similarCandidates: [],
				similarCandidatesPaginationInfo: null,
			};
		case SET_SIMILAR_CANDIDATES_WITH_PAGINATION:
			const prevSimilarCandidates =
				action.payload.pageNumber > 1 ? state.similarCandidates : [];
			const updatedSimilarCandidates = [...prevSimilarCandidates, ...action.payload.data];

			return {
				...state,
				similarCandidatesInProgress: false,
				similarCandidates: updatedSimilarCandidates,
				similarCandidatesPaginationInfo: {...action.payload.paginationInfo},
			};

		case GET_SINGLE_CANDIDATE_INIT:
			return {...state, candidateProfileInProgress: true};
		case GET_SINGLE_CANDIDATE_DONE:
			return {
				...state,
				currentCandidate: action.payload,
				candidateProfileInProgress: false,
			};
		case FLAG_CANDIDATE:
			return {
				...state,
				currentCandidate: {
					...state.currentCandidate,
					is_data_flagged_wrong: action.payload,
				},
			};
		case GET_SINGLE_CANDIDATE_FAIL:
			return {
				...state,
				candidateError: true,
			};
		case RESET_FILTERED_CANDIDATES:
			return {
				...state,
				candidatesInProgress: false,
				candidates: [],
				paginationInfo: null,
				similarCandidates: [],
				similarCandidatesPaginationInfo: null,
			};

		case IMPORT_LINKEDIN_PROFILE_IN_PROGRESS:
			return {
				...state,
				importLinkedInCandidateInProgress: action.payload,
			};
		case SET_IMPORT_LINKEDIN_PROFILE_INFO:
			return {
				...state,
				importLinkedInProfileInfo: action.payload,
			};

		case SEARCH_LINKEDIN_PROFILE_IN_PROGRESS_FOR_PROJECT:
			return {
				...state,
				searchCandidateForProject: {
					...state.searchCandidateForProject,
					[action.payload.id]: action.payload.data, // sets boolean value for project by id
				},
			};

		case SET_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT:
			return {
				...state,
				candidateImportByLinkedInForProject: {
					...state.candidateImportByLinkedInForProject,
					[action.payload.id]: action.payload.data, // sets object for project by id
				},
			};

		case CLEAR_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT:
			const data = {...state.candidateImportByLinkedInForProject};
			const dataProgressInfo = {...state.searchCandidateForProject};
			const id = action.payload;

			if (id in data) {
				delete data[id];
			}

			if (id in dataProgressInfo) {
				delete dataProgressInfo[id];
			}

			return {
				...state,
				candidateImportByLinkedInForProject: {...data},
				searchCandidateForProject: {...dataProgressInfo},
			};

		case ACTIVE_ADD_PEOPLE_MODAL_FOR_PROJECT:
			return {
				...state,
				activeAddPeopleModalForProject: action.payload,
			};
		case LOGOUT:
			return {
				...state,
				...candidateInitialState,
			};
		default:
			return state;
	}
};

export default candidateReducer;
