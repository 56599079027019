import {useCallback, useMemo} from "react";
import {SearchInput} from "mapx-components";
import {Checkbox, ResetFilters, TabSelect} from "components";
import css from "../styles.module.scss";
import useFetchListOptions from "hooks/useFetchListOptions";
import {targetListCompaniesSelector} from "store/mapx/target-list/targetListSelectors";
import {sortArrayOfObjectsAlphabetically} from "helpers/array";
import {
	apCandidateCompanyByPositionSelector,
	apCandidateCompanyPositionSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import useCheckboxListSortHook from "hooks/useCheckboxListSortHook";
import CheckboxList from "mapx-components/Inputs/CheckboxList";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";
import {
	moveAPCompanyAll,
	setAPCompany,
	setAPCompanyMultiple,
} from "store/mapx/additional-profiles/additionalProfilesAsyncActions";
import Tags from "./Tags";
import {SelectAllIcon} from "assets/icons";
import {useAppDispatch, useAppSelector} from "hooks";
import {STCompanyShort} from "api/companyApi/types";

const positionOptions = [
	{label: "Current", value: "current"},
	{label: "Previous", value: "previous"},
	{label: "Any", value: "any"},
];

function CompanyFilterForAP() {
	const dispatch = useAppDispatch();

	const targetListCompanies = useAppSelector(targetListCompaniesSelector);

	const companies = useAppSelector(apCandidateCompanyByPositionSelector);

	const position = useAppSelector(apCandidateCompanyPositionSelector);

	const handlePositionChange = useCallback(
		(value: string) => {
			dispatch(moveAPCompanyAll({position: value}));
		},
		[dispatch],
	);

	/**
	 * Here CompanyDetails options are the input companies selected for the additional profiles search
	 * @returns Array
	 */
	const companyOptions = useMemo(() => {
		return sortArrayOfObjectsAlphabetically([...targetListCompanies], "name");
	}, [targetListCompanies]);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const {filteredData, onFilterChanged} = useFetchListOptions({
		options: companyOptions,
	});

	const sortedFilteredData = useCheckboxListSortHook(filteredData);

	const handleOnChange = (id: number) => {
		dispatch(setAPCompany({id}));
	};

	const handleResetClick = () => {
		dispatch(
			setAPMultipleFilters({
				current_companies: [],
				previous_companies: [],
				companies: [],
			}),
		);
	};

	const handleSelectAll = () => {
		const allIds = sortedFilteredData.map(({id}) => id);

		dispatch(setAPCompanyMultiple({ids: allIds}));
	};

	const displayReset = useMemo(() => {
		return companies.length > 0;
	}, [companies]);

	const shouldDisplaySelectAll = useMemo(() => {
		return sortedFilteredData?.length !== companies.length;
	}, [companies, sortedFilteredData]);

	return (
		<div>
			<TabSelect.LabelContainer label="All Positions">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={positionOptions}
				/>
			</TabSelect.LabelContainer>

			<SearchInput
				onChange={onFilterChanged}
				placeholder="Search for a Company"
				type="text"
				errorText={undefined}
				errorClass={undefined}
			/>

			<div className={css.resetFilterContainer}>
				{displayReset ? (
					<ResetFilters
						parentStyle={{marginBottom: 0, color: "#5A5A5A", marginRight: 19}}
						onClick={handleResetClick}
						displayIcon={true}
					>
						Clear Selection
					</ResetFilters>
				) : (
					<span />
				)}

				{shouldDisplaySelectAll && (
					<div className={css.selectAll} onClick={handleSelectAll}>
						<SelectAllIcon /> Select All
					</div>
				)}
			</div>

			<CheckboxList>
				{sortedFilteredData.map((item: STCompanyShort) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={css.checkboxContainer}
						isChecked={companies.includes(item.id)}
						key={item.id}
						label={item.name}
						onChange={() => handleOnChange(item.id)}
						value={item.name}
					/>
				))}
			</CheckboxList>

			<Tags />
		</div>
	);
}

export default CompanyFilterForAP;
