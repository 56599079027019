import React, {useCallback, useState} from "react";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import {useSelector} from "react-redux";
import {
	editProjectFormSelector,
	editProjectModalSelector,
} from "store/mapx/project-list/projectListSelectors";
import {editProjectModal} from "store/mapx/project-list/projectListActions";
import {ModalHeader, ModalSeparator} from "components";
import ProjectForm from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm";
import {TProjectFormData} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm/types";
import {updateProject} from "store/mapx/project-list/projectWorkflow";

const ProjectEditModal = ({onlyShareOption = false}: {onlyShareOption?: boolean}) => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState(false);

	const modalDisplay = useSelector(editProjectModalSelector);

	const projectFormData: TProjectFormData = useAppSelector(editProjectFormSelector);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{
			content: {
				maxWidth: "588px",
				borderRadius: "4px",
				width: "99%",
				boxShadow: "0px 25px 40px -10px rgba(79, 75, 74, 0.08)",
			},
		},
		modalDisplay,
	);

	const handleModalHide = useCallback(() => {
		dispatch(editProjectModal(false));
	}, [dispatch]);

	const handleSaveProject = useCallback(
		async (formData: TProjectFormData) => {
			setLoading(true);

			await dispatch(updateProject(projectFormData.id as number, formData));

			setLoading(false);
		},
		[dispatch, projectFormData],
	);

	return (
		<Modal
			contentLabel="Modal"
			isOpen={modalIsOpen}
			onRequestClose={handleModalHide}
			style={customStyles}
			title="Create New Project"
		>
			<ModalHeader title={projectFormData.name} onClose={handleModalHide} />

			<ModalSeparator />

			<ProjectForm
				formType={"edit-form"}
				onlyShareOption={onlyShareOption}
				projectName={projectFormData.name}
				projectLocation={projectFormData.location}
				projectUsers={projectFormData.users}
				handleSubmitButtonClick={handleSaveProject}
				loading={loading}
			/>
		</Modal>
	);
};

export default ProjectEditModal;
