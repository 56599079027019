import type {FC} from "react";

import type {IconProps} from "./types";

const LockIcon: FC<IconProps> = ({
	width = 20,
	height = 20,
	className = "",
	stroke = "#D9D9D9",
	color = "#B5B1B0",
	...rest
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		{...rest}
	>
		<mask
			id="mask0_799_23335"
			style={{maskType: "alpha"}}
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="20"
			height="20"
		>
			<rect width="20" height="20" fill={stroke} />
		</mask>
		<g mask="url(#mask0_799_23335)">
			<path
				d="M5.55335 18.1545C5.0776 18.1545 4.67145 17.9852 4.33491 17.6465C3.99836 17.3079 3.83008 16.9008 3.83008 16.4253V8.54486C3.83008 8.06934 3.9994 7.66227 4.33804 7.32363C4.67666 6.98501 5.08374 6.81569 5.55927 6.81569H5.92474V4.96017C5.92474 3.82693 6.32185 2.86201 7.11606 2.0654C7.91025 1.26879 8.87296 0.870483 10.0042 0.870483C11.1354 0.870483 12.0965 1.26879 12.8876 2.0654C13.6787 2.86201 14.0742 3.82693 14.0742 4.96017V6.81569H14.4397C14.9152 6.81569 15.3223 6.98501 15.6609 7.32363C15.9996 7.66227 16.1689 8.06934 16.1689 8.54486V16.4253C16.1689 16.9008 15.9995 17.3079 15.6607 17.6465C15.3219 17.9852 14.9146 18.1545 14.4388 18.1545H5.55335ZM5.55927 16.4253H14.4397V8.54486H5.55927V16.4253ZM10.0039 13.9851C10.4176 13.9851 10.7703 13.8378 11.062 13.5432C11.3536 13.2485 11.4995 12.8944 11.4995 12.4807C11.4995 12.0669 11.3522 11.7143 11.0576 11.4226C10.7629 11.1309 10.4088 10.9851 9.99506 10.9851C9.58134 10.9851 9.22864 11.1324 8.93697 11.427C8.64531 11.7216 8.49947 12.0758 8.49947 12.4895C8.49947 12.9032 8.64678 13.2559 8.94139 13.5476C9.23602 13.8393 9.59018 13.9851 10.0039 13.9851ZM7.65391 6.81569H12.345V4.96017C12.345 4.30448 12.1178 3.74714 11.6632 3.28815C11.2086 2.82916 10.6541 2.59967 9.99947 2.59967C9.34489 2.59967 8.79031 2.82916 8.33574 3.28815C7.88119 3.74714 7.65391 4.30448 7.65391 4.96017V6.81569Z"
				fill={color}
			/>
		</g>
	</svg>
);

export default LockIcon;
