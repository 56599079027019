import {FC, useMemo, useState} from "react";
import classNames from "classnames";

import {useAppDispatch, useAppSelector} from "hooks";
import {
	candidateMappingForCurrentCompanySelector,
	companyOrgChartSelector,
} from "store/mapx/company/companySelectors";

import {ChartCard} from "../Card";
import styles from "./index.module.scss";
import {getPaginatedDataForCompanyOrgChart} from "store/mapx/company/companyAsyncActions";
import {Loader} from "components";
import type {SIOrgChartProps} from "api/companyApi/types";
import type {TChartSectionProps} from "./types";
import type {SICandidatePosition} from "api/candidateApi/types";
import {getSingleCandidateDone} from "store/mapx/candidate/candidateActions";

export const ChartSection: FC<TChartSectionProps> = ({
	candidates,
	currentCompany,
	seniorityTitle = "",
}) => {
	if (!candidates?.length) {
		return null;
	}

	const [paginateStep, setPaginateStep] = useState<"min" | "mid" | "max">("min");

	const [paginationLoading, setPaginationLoading] = useState<boolean>(false);

	const dispatch = useAppDispatch();

	const companyOrgChartOnState = useAppSelector(companyOrgChartSelector);

	const mapInfoForCurrentCompany = useAppSelector((state) => {
		return candidateMappingForCurrentCompanySelector(state, currentCompany.id);
	});

	const newlyMappedCandidateIds = useMemo(() => {
		if (
			mapInfoForCurrentCompany &&
			mapInfoForCurrentCompany?.found_candidate_ids !== undefined &&
			mapInfoForCurrentCompany?.status === "Completed"
		) {
			return mapInfoForCurrentCompany.found_candidate_ids;
		} else {
			return [];
		}
	}, [mapInfoForCurrentCompany]);

	const seniorityData = useMemo(() => {
		return companyOrgChartOnState.find(
			({seniority}: SIOrgChartProps) => seniority === seniorityTitle,
		);
	}, [companyOrgChartOnState, seniorityTitle]);

	const displayedDataCount = useMemo(() => {
		if (paginateStep === "min") return 10;
		else if (paginateStep === "mid") return 40;
		else return candidates?.length > 40 ? seniorityData?.pagination?.count : 40;
	}, [candidates?.length, paginateStep, seniorityData?.pagination?.count]);

	const totalCandidatesLeftToDisplay = useMemo(() => {
		const tL = seniorityData?.pagination?.count - displayedDataCount;

		return tL < 0 ? 0 : tL;
	}, [displayedDataCount, seniorityData?.pagination?.count]);

	const handSeeMoreCandidateClick = async () => {
		if (paginateStep === "min") {
			setPaginateStep("mid");
		} else if (paginateStep === "mid") {
			setPaginateStep("max");

			setPaginationLoading(true);

			await dispatch(getPaginatedDataForCompanyOrgChart(seniorityTitle, 1));

			setPaginationLoading(false);
		}
	};

	const LOADER_COMPONENT = <Loader height={20} width={20} type="ThreeDots" color="#0c5850" />;

	return (
		<div className={styles.wrapper}>
			<div className={styles.sectionContainer}>
				<p className={styles.title}>
					{seniorityTitle === "ExCo" ? "ExCo (Executive Committee)" : seniorityTitle}
				</p>

				<div
					className={classNames(styles.chartGrid, {
						[styles.expandedGrid]: displayedDataCount > 5,
					})}
				>
					{[...candidates.slice(0, displayedDataCount)].map((candidate, idx) => {
						// if (idx > 4) {
						// 	return null;
						// }

						const isNewlyMapped =
							newlyMappedCandidateIds?.length > 0 &&
							newlyMappedCandidateIds.includes(candidate.id);

						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						const positions = candidate?.experience?.filter(
							(exp: SICandidatePosition) =>
								((exp.seniority || "Other") === seniorityTitle ||
									(exp.seniority === "ExCo" &&
										seniorityTitle === "ExCo (Executive Committee)")) &&
								!exp.year_to &&
								currentCompany?.id === exp.company.id,
						);

						// HERE key = candidate id and stringify of position
						// that's how it will not re-render each time
						// don't use unique id here

						return (
							<ChartCard
								key={`${idx}-${seniorityTitle}-${candidate.id}-${JSON.stringify(
									positions,
								)}`}
								id={candidate.id}
								positions={positions}
								picture={candidate.avatar_url}
								name={`${candidate.first_name} ${candidate.last_name}`}
								newlyMapped={isNewlyMapped}
								onClick={() => dispatch(getSingleCandidateDone(candidate))}
							/>
						);
					})}
				</div>

				{seniorityData?.pagination?.count > displayedDataCount && (
					<div
						className={classNames(styles.buttonContainer, {
							[styles.loading]: paginationLoading,
						})}
					>
						<button onClick={handSeeMoreCandidateClick}>
							{paginationLoading && <div>Showing more people {LOADER_COMPONENT}</div>}

							{!paginationLoading && `${totalCandidatesLeftToDisplay} More`}
						</button>
					</div>
				)}
			</div>
			{totalCandidatesLeftToDisplay < 1 && <div className={styles.border} />}
		</div>
	);
};
