import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {LocationSearchFilter} from "mapx-components";
import {
	apCandidateCountryRegionsSelector,
	apCandidateLocationSelector,
} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import LocationFilterHelper from "mapx-components/Filters/LocationSearchFilter/LocationFilterHelper";
import {flattenCountriesSelector} from "store/mapx/filter/filterSelectors";
import {setAPMultipleFilters} from "store/mapx/additional-profiles/additionalProfilesActions";

function LocationFilterForAP() {
	const selectedCountries = useSelector(apCandidateLocationSelector);

	const selectedCountryRegions = useSelector(apCandidateCountryRegionsSelector);

	const flattenCountries = useSelector(flattenCountriesSelector);

	const dispatch = useDispatch();

	const {handleCheckChangeForSingleItem, handleCheckChangeForBulkItems, handleRegionCheckChange} =
		LocationFilterHelper({
			selectedCountries,
			selectedCountryRegions,
			flattenCountries,
		});

	const handleChange = useCallback(
		(id) => {
			const {nextCountryIds, toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

			dispatch(
				setAPMultipleFilters({
					countries: nextCountryIds,
					regions: toBeSetRegionIds,
				}),
			);
		},
		[dispatch, handleCheckChangeForSingleItem],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setAPMultipleFilters({
				countries: [],
				regions: [],
			}),
		);
	}, [dispatch]);

	const handleBulkUpdate = useCallback(
		(ids) => {
			const toBeSetRegionIds = handleCheckChangeForBulkItems(ids);

			dispatch(
				setAPMultipleFilters({
					countries: ids,
					regions: toBeSetRegionIds,
				}),
			);
		},
		[dispatch, handleCheckChangeForBulkItems],
	);

	const handleRegionChange = useCallback(
		(regionId, country) => {
			const {toBeSetRegionIds, nextCountryIds} = handleRegionCheckChange(regionId, country);

			dispatch(
				setAPMultipleFilters({
					countries: nextCountryIds,
					regions: toBeSetRegionIds,
				}),
			);
		},
		[dispatch, handleRegionCheckChange],
	);

	return (
		<LocationSearchFilter
			filterType={"ap_candidates"}
			handleResetClick={handleResetClick}
			handleOnChange={handleChange}
			selectedLocation={selectedCountries}
			selectedCountryRegions={selectedCountryRegions}
			handleBulkUpdate={handleBulkUpdate}
			handleRegionChange={handleRegionChange}
		/>
	);
}

export default LocationFilterForAP;
