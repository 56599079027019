export const candidateCompanyDateRangeBand = [
	{
		id: "< 6 months",
		name: "< 6 months",
		min_value: new Date(new Date(new Date().setMonth(new Date().getMonth() - 6)).setDate(1)),
		max_value: new Date(new Date().setDate(0)),
	},
	{
		id: "< 12 months",
		name: "< 12 months",
		min_value: new Date(new Date(new Date().setMonth(new Date().getMonth() - 12)).setDate(1)),
		max_value: new Date(new Date().setMonth(new Date().getMonth() - 6)).setDate(0),
	},
	{
		id: "1-2 years",
		name: "1-2 years",
		min_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 2)).setMonth(0, 1),
		),
		max_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setMonth(11, 31),
		),
	},
	{
		id: "2-3years",
		name: "2-3 years",
		min_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 3)).setMonth(0, 1),
		),
		max_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 2)).setMonth(11, 31),
		),
	},
	{
		id: "3-4years",
		name: "3-4 years",
		min_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 4)).setMonth(0, 1),
		),
		max_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 3)).setMonth(11, 31),
		),
	},
	{
		id: "5-6years",
		name: "5-6 years",
		min_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 6)).setMonth(0, 1),
		),
		max_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 5)).setMonth(11, 31),
		),
	},
	{
		id: "6+years",
		name: "6+ years ago",
		min_value: new Date(
			new Date(new Date().setFullYear(new Date().getFullYear() - 6)).setMonth(0, 1),
		),
		max_value: new Date(),
	},
];
