import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Accordion, LocationSearchFilter} from "mapx-components";
import {
	candidateCountriesSelector,
	candidateCountryRegionsSelector,
	flattenCountriesSelector,
} from "store/mapx/filter/filterSelectors";
import {setMultipleFilterForCandidates} from "store/mapx/filter/filterActions";
import LocationFilterHelper from "mapx-components/Filters/LocationSearchFilter/LocationFilterHelper";

function LocationFilter({disabled, displayLock = false}) {
	const dispatch = useDispatch();

	const selectedCountries = useSelector(candidateCountriesSelector);

	const selectedCountryRegions = useSelector(candidateCountryRegionsSelector);

	const flattenCountries = useSelector(flattenCountriesSelector);

	const {handleCheckChangeForSingleItem, handleCheckChangeForBulkItems, handleRegionCheckChange} =
		LocationFilterHelper({
			selectedCountries,
			selectedCountryRegions,
			flattenCountries,
		});

	const handleChange = useCallback(
		(id) => {
			const {nextCountryIds, toBeSetRegionIds} = handleCheckChangeForSingleItem(id);

			dispatch(
				setMultipleFilterForCandidates({
					countries: nextCountryIds,
					regions: toBeSetRegionIds,
				}),
			);
		},
		[dispatch, handleCheckChangeForSingleItem],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCandidates({
				countries: [],
				regions: [],
			}),
		);
	}, [dispatch]);

	const handleBulkUpdate = useCallback(
		(ids) => {
			const toBeSetRegionIds = handleCheckChangeForBulkItems(ids);

			dispatch(
				setMultipleFilterForCandidates({
					countries: ids,
					regions: toBeSetRegionIds,
				}),
			);
		},
		[dispatch, handleCheckChangeForBulkItems],
	);

	const handleRegionChange = useCallback(
		(regionId, country) => {
			const {toBeSetRegionIds, nextCountryIds} = handleRegionCheckChange(regionId, country);

			dispatch(
				setMultipleFilterForCandidates({
					countries: nextCountryIds,
					regions: toBeSetRegionIds,
				}),
			);
		},
		[dispatch, handleRegionCheckChange],
	);

	return (
		<Accordion
			title="Location"
			subtitle={selectedCountries.length + (selectedCountryRegions?.length || 0)}
			disabled={disabled}
			displayLock={displayLock}
		>
			<LocationSearchFilter
				filterType={"candidates"}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				selectedLocation={selectedCountries}
				selectedCountryRegions={selectedCountryRegions}
				handleBulkUpdate={handleBulkUpdate}
				handleRegionChange={handleRegionChange}
			/>
		</Accordion>
	);
}

export default LocationFilter;
