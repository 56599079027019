import {
	GET_INDUSTRIES_REPORT_FETCH,
	GET_INDUSTRIES_REPORT_SUCCESS,
	GET_INDUSTRY_BACKGROUND_REPORT_DONE,
	GET_INDUSTRY_BACKGROUND_REPORT_FAILED,
	GET_INDUSTRY_BACKGROUND_REPORT_INIT,
	LOGOUT,
	SET_COUNTRY_REPORT,
	SET_ETHNIC_DIVERSITY_DATA,
	SET_GENDER_DIVERSITY_DATA,
	SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_CURRENT_PROJECT,
	SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT,
	SET_JOB_FUNCTION_DISTRIBUTION_DATA,
	SET_ONBOARDING_STEP,
	SET_ONBOARDING_VISIBILITY,
	SET_PROJECT,
	SET_PROJECT_CHECKLIST_FULL_DISPLAY,
	SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA,
	SET_PROJECT_STATS,
	SET_PROJECT_STATS_FAILED,
	SET_PROJECT_STATS_INIT,
	UPDATE_PROJECT_SUCCESS_CHECKLIST,
} from "../mapXActionTypes";
import projectChecklist from "api/projectApi/projectChecklistData.json";

export const mapxProjectInitialState = {
	insights: {
		genderDiversity: {
			male_percent: 0,
			female_percent: 0,
		},
		ethnicDiversity: {},
		jobFunctionDistribution: [],
		countryReport: null,
	},
	industryReport: null,
	industryReportLoading: false,
	projectStats: null,
	projectStatsLoading: false,
	projectTabIndex: 0,
	projectFrequentlyHiredFromData: null,
	industryBackgroundReport: [],
	industryBackgroundReportProgress: false,
	onboardingStep: 0,
	openOnboardingComponent: false,
	generateCandidatesSummaryByProject: {}, // targetListId: [candidate_id]
	generateCandidatesContactDetailsByProject: {}, // targetListId: [candidate_id]

	projectChecklistOptions: projectChecklist,
	displayFullProjectChecklist: false,
};

const projectReducer = (state = mapxProjectInitialState, action) => {
	switch (action.type) {
		case SET_PROJECT_CHECKLIST_FULL_DISPLAY: {
			return {
				...state,
				displayFullProjectChecklist: action.payload,
			};
		}

		case UPDATE_PROJECT_SUCCESS_CHECKLIST: {
			return {
				...state,
				projectChecklistOptions: [...action.payload],
			};
		}

		case SET_PROJECT: {
			return {
				...state,
				projectChecklistOptions: [...projectChecklist],
				displayFullProjectChecklist: false,
			};
		}

		case SET_ONBOARDING_VISIBILITY: {
			return {
				...state,
				openOnboardingComponent: action.payload,
			};
		}

		case SET_ONBOARDING_STEP: {
			let updatedOnboardingStep = state.onboardingStep;
			const boardingStep = state.onboardingStep === 5 ? 4 : state.onboardingStep - 1;

			// Checking the action which should be triggered in case of payload's type is a number or a string
			if (typeof action.payload === "string") {
				if (action.payload === "increment") {
					updatedOnboardingStep =
						updatedOnboardingStep === null ? 1 : state.onboardingStep + 1;
				} else if (action.payload === "decrement") {
					updatedOnboardingStep = boardingStep;
				} else {
					updatedOnboardingStep = null;
				}
			} else if (typeof action.payload === "number") {
				updatedOnboardingStep = action.payload;
			}

			return {
				...state,
				onboardingStep: updatedOnboardingStep,
			};
		}
		case SET_GENDER_DIVERSITY_DATA:
			return {
				...state,
				insights: {
					...state.insights,
					genderDiversity: action.payload,
				},
			};

		case SET_COUNTRY_REPORT:
			return {
				...state,
				insights: {
					...state.insights,
					countryReport: action.payload,
				},
			};

		case GET_INDUSTRIES_REPORT_FETCH:
			return {
				...state,
				industryReportLoading: true,
			};
		case GET_INDUSTRIES_REPORT_SUCCESS:
			return {
				...state,
				industryReport: action.payload,
				industryReportLoading: false,
			};
		case SET_ETHNIC_DIVERSITY_DATA:
			return {
				...state,
				insights: {
					...state.insights,
					ethnicDiversity: action.payload,
				},
			};
		case SET_JOB_FUNCTION_DISTRIBUTION_DATA:
			return {
				...state,
				insights: {
					...state.insights,
					jobFunctionDistribution: action.payload,
				},
			};
		case SET_PROJECT_STATS:
			return {
				...state,
				projectStats: action.payload,
				projectStatsLoading: false,
			};
		case SET_PROJECT_STATS_INIT:
			return {
				...state,
				projectStatsLoading: true,
			};
		case SET_PROJECT_STATS_FAILED:
			return {
				...state,
				projectStatsLoading: false,
			};
		case SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA: {
			const {results, pagination} = action.payload;
			const {projectFrequentlyHiredFromData} = state;

			return {
				...state,
				projectFrequentlyHiredFromData: {
					results:
						pagination.page === 1
							? results
							: [...(projectFrequentlyHiredFromData.results || []), ...results],
					pagination,
				},
			};
		}

		case GET_INDUSTRY_BACKGROUND_REPORT_INIT:
			return {
				...state,
				industryBackgroundReportProgress: true,
			};

		case GET_INDUSTRY_BACKGROUND_REPORT_DONE:
			return {
				...state,
				industryBackgroundReport: action.payload,
				industryBackgroundReportProgress: false,
			};
		case GET_INDUSTRY_BACKGROUND_REPORT_FAILED:
			return {
				...state,
				industryBackgroundReportProgress: false,
			};
		case SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT:
			return {
				...state,
				generateCandidatesSummaryByProject: {
					...state.generateCandidatesSummaryByProject,
					...action.payload,
				},
			};
		case SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_CURRENT_PROJECT:
			return {
				...state,
				generateCandidatesContactDetailsByProject: {
					...state.generateCandidatesContactDetailsByProject,
					...action.payload,
				},
			};
		case LOGOUT:
			return {
				...state,
				...mapxProjectInitialState,
			};

		default:
			return state;
	}
};

export default projectReducer;
