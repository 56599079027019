import {useState} from "react";
import {debounce} from "lodash";
import axios, {type CancelTokenSource} from "axios";
import {successResponse} from "helpers/map";
import mapxJobTitleApi from "api/filterOptionsApi/JobTitleApi";
import {STJobTitle} from "api/filterOptionsApi/JobTitleApi/types";

let cancelToken: CancelTokenSource;

const useJobTitleSearchService = () => {
	const [loadingJobTitle, setLoadingJobTitle] = useState<boolean>(false);

	const fetchJobTitle = debounce(
		async (inputString: string, callback: (options: Record<string, string>[]) => void) => {
			setLoadingJobTitle(true);
			//Check if there are any previous pending requests
			if (typeof cancelToken !== typeof undefined) {
				cancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			cancelToken = axios.CancelToken.source();

			const config = {cancelToken: cancelToken.token};

			const response = await mapxJobTitleApi.search(inputString, 1, config);

			if (response === undefined) {
				setLoadingJobTitle(true);
			}

			if (successResponse(response, 200)) {
				setLoadingJobTitle(false);

				const data: Record<string, string>[] = response.data.results.filter(
					(item: STJobTitle) =>
						item.positions_count >= 10 ||
						item.job_title.toLowerCase() === inputString.toLowerCase(),
				);

				return callback(data);
			} else {
				setLoadingJobTitle(false);

				return callback([]);
			}
		},
		800,
	);

	return {
		loadingJobTitle,
		setLoadingJobTitle,
		fetchJobTitle,
	};
};

export default useJobTitleSearchService;
