import {
	CLEAR_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO,
	CURRENT_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO,
	REFRESH_LINKEDIN_PROFILE_IN_PROGRESS,
} from "store/mapx/mapXActionTypes";
import {TCandidateImportRequestResponse} from "api/candidateApi/types";

export function refreshLinkedInCandidateInProgress(payload: {[x: string]: boolean}) {
	return {type: REFRESH_LINKEDIN_PROFILE_IN_PROGRESS, payload};
}

export function setCurrentRefreshLinkedInProfileScrapeInfo(payload: {
	[x: string]: TCandidateImportRequestResponse;
}) {
	return {type: CURRENT_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO, payload};
}

export function clearRefreshLinkedInProfileScrapeInfo(payload: string) {
	return {type: CLEAR_REFRESH_LINKEDIN_PROFILE_SCRAPE_INFO, payload};
}
