import React, {useMemo, useState} from "react";
import ButtonsRow from "mapx-components/Buttons/ButtonsRow";
import {getUpdatedIndustryIdsByCurrentSelectedItem} from "mapx-components/Filters/IndustrySearchFilter/utils";
import {setIndustryForCandidate} from "store/mapx/filter/industriesFilterAsyncActions";
import {useSelector} from "react-redux";
import {
	candidateIndustriesByPositionSelector,
	industryCandidatePositionSelector,
} from "store/mapx/filter/industriesFilterSelectors";
import {
	flattenIndustriesSelector,
	industryOptionsSelector,
	jobFunctionOptionsSelector,
} from "store/mapx/search/searchSelectors";
import {flattenByChildren} from "helpers/TreeModification";
import {formatDistanceStrict, parseISO} from "date-fns";
import {combineGeneralistAndNoSpecialismExpDurations} from "helpers/map";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";
import {
	currentPositionConnectivityLogicSelector,
	jobFunctionCandidatePositionSelector,
	specialismsByPositionSelector,
} from "store/mapx/filter/jobFunctionFilterSelectors";
import {formatData} from "mapx-components/Cards/CandidateCard/CandidateExpertise/utils";
import {useAppDispatch} from "hooks";
import {
	TCandidateExpertise,
	TExperienceCalculationObj,
	TIndustryStatObj,
	TSpecialismStatObj,
} from "mapx-components/Cards/CandidateCard/CandidateExpertise/types";
import {STSpecialism} from "api/filterOptionsApi/JobFunctionApi/types";
import {TChildIndustryForFilter, TIndustryForFilter} from "api/filterOptionsApi/IndustryApi/types";
import {setSpecialismForCandidate} from "store/mapx/filter/jobFunctionAsyncAction";
import {getIndustries, getJobFunctions} from "store/mapx/search/searchAsyncActions";
import {setActiveAccordionOnFilter} from "store/mapx/filter/filterActions";

const CandidateExpertise = ({
	positions,
	trackEventReport,
	disabledTags,
	printRequested,
}: TCandidateExpertise) => {
	const dispatch = useAppDispatch();
	const [loadingIndustries, setLoadingIndustries] = useState(false);
	const [loadingSpecialism, setLoadingSpecialism] = useState(false);

	const candidateIndustryPosition = useSelector(industryCandidatePositionSelector);

	const candidateSpecialismPosition = useSelector(jobFunctionCandidatePositionSelector);

	const candidateSpecialismConnectivityLogic = useSelector(
		currentPositionConnectivityLogicSelector,
	);

	const activeSpecialismIds = useSelector((state) =>
		specialismsByPositionSelector(
			state,
			candidateSpecialismPosition,
			candidateSpecialismConnectivityLogic,
		),
	);

	const industries = useSelector((state) =>
		candidateIndustriesByPositionSelector(state, candidateIndustryPosition),
	);

	const industryOptions = useSelector(industryOptionsSelector);

	const jobFunctionOptions = useSelector(jobFunctionOptionsSelector);

	const flattenIndustries = useSelector(flattenIndustriesSelector) as TIndustryForFilter[];

	const allIndustryOptionsWithoutChild = useMemo(
		() => flattenByChildren(industryOptions),
		[industryOptions],
	) as TChildIndustryForFilter[];

	const candidateSpecialisms = useMemo(() => {
		const currentSpecialisms = [];

		if (positions) {
			for (const val of positions) {
				if (val.specialisms) {
					for (const spec of val.specialisms) {
						currentSpecialisms.push(spec);
					}
				}
			}
		}

		return removeDuplicateObjectFromArray(currentSpecialisms);
	}, [positions]);

	const specialismsData = useMemo(() => {
		const obj: TExperienceCalculationObj = {};

		if (positions) {
			for (const val of positions) {
				const startDate = val.start_date !== null ? parseISO(val.start_date) : new Date();
				const endDate = val.end_date !== null ? parseISO(val.end_date) : new Date();
				const expDuration = parseInt(
					formatDistanceStrict(startDate, endDate, {unit: "month"}),
				);

				if (val.specialisms) {
					for (const spec of val.specialisms) {
						const key = `${spec.job_function.name}: ${spec.name}`;

						if (!obj[key]) {
							obj[key] = expDuration / val.specialisms.length;
						} else {
							obj[key] = obj[key] + expDuration;
						}
					}
				}
			}

			const sum = Object.values(obj).reduce((prev, current) => prev + current, 0);

			for (const key in obj) {
				obj[key] = Math.round(obj[key] / (sum / 100)) || 1;
			}

			return combineGeneralistAndNoSpecialismExpDurations(obj);
		}

		return obj;
	}, [positions]);

	const specialismItems = useMemo(
		() => formatData(specialismsData as TExperienceCalculationObj),
		[specialismsData],
	);

	const industriesData = useMemo(() => {
		const obj: TExperienceCalculationObj = {};

		if (positions) {
			for (const val of positions) {
				const startDate = val.start_date !== null ? parseISO(val.start_date) : new Date();
				const endDate = val.end_date !== null ? parseISO(val.end_date) : new Date();
				const expDuration = parseInt(
					formatDistanceStrict(startDate, endDate, {unit: "day"}),
				);

				if (val.company.industry) {
					const key = val.company.industry.name;
					if (!obj[key]) {
						obj[key] = expDuration;
					} else {
						obj[key] = obj[key] + expDuration;
					}
				}
			}

			const sum = Object.values(obj).reduce((prev, current) => prev + current, 0);

			for (const key in obj) {
				obj[key] = Math.round(obj[key] / (sum / 100)) || 1;
			}

			return obj;
		}

		return obj;
	}, [positions]);

	const industriesItems = useMemo(() => formatData(industriesData), [industriesData]);

	const handleSelectedIndustries = async (industryStatObj: TIndustryStatObj) => {
		let item: TIndustryForFilter | undefined;

		if (flattenIndustries.length === 0) {
			await dispatch(setActiveAccordionOnFilter("Industry"));

			setLoadingIndustries(true);

			let industriesData = await dispatch(getIndustries());

			setLoadingIndustries(false);

			industriesData = flattenByChildren(industriesData);

			item = industriesData.find((i: TIndustryForFilter) => i.name === industryStatObj.name);
		} else {
			item = flattenIndustries.find(
				(i: TIndustryForFilter) => i.name === industryStatObj.name,
			);
		}

		if (item) {
			const nextIds = getUpdatedIndustryIdsByCurrentSelectedItem(
				item,
				[...industries],
				allIndustryOptionsWithoutChild,
			);

			dispatch(setIndustryForCandidate({nextIds, position: candidateIndustryPosition}));
		}

		trackEventReport("click");
	};

	const handleSelectedSpecialisms = async (selectedSpec: TSpecialismStatObj) => {
		let itemId;

		if (jobFunctionOptions.length === 0) {
			setLoadingSpecialism(true);

			await dispatch(setActiveAccordionOnFilter("Function & Specialism"));

			const jobFunctionData = await dispatch(getJobFunctions());

			setLoadingSpecialism(false);

			if (jobFunctionData.length > 0) {
				for (const jobFunction of jobFunctionData) {
					for (const specialism of jobFunction.specialisms) {
						if (`${jobFunction.name}: ${specialism.name}` === `${selectedSpec.name}`) {
							itemId = specialism.id;
							break;
						}
					}
				}
			}
		} else {
			// Because we have specialism name as "Job Function Name: Specialism Name" e.g "HR: Generalist"
			for (const jobFunction of jobFunctionOptions) {
				for (const specialism of jobFunction.specialisms) {
					if (`${jobFunction.name}: ${specialism.name}` === `${selectedSpec.name}`) {
						itemId = specialism.id;

						break;
					}
				}
			}
		}
		if (itemId) {
			dispatch(
				setSpecialismForCandidate({
					position: candidateSpecialismPosition,
					logic: candidateSpecialismConnectivityLogic,
					id: itemId,
				}),
			);
		}
		// we have to this specialism in state to display on filterOptionsApi

		trackEventReport("click"); // event report for recommendation
	};

	const mappedIndustries = useMemo(
		() =>
			industries.map(
				(val: number) => flattenIndustries.find((industry) => industry.id === val)?.name,
			),
		[industries, flattenIndustries],
	);

	const mappedSpecialisms = useMemo(() => {
		let items: string[] = [];

		for (const specId of activeSpecialismIds) {
			const item = candidateSpecialisms.find((spec: STSpecialism) => spec.id === specId);

			if (item) {
				items = [...items, `${item.job_function.name}: ${item.name}`];
			}
		}

		return items;
	}, [activeSpecialismIds, candidateSpecialisms]);

	return (
		<div>
			{industriesItems.length > 0 && (
				<ButtonsRow
					items={industriesItems}
					selectedItems={mappedIndustries}
					label="Industries"
					handleSelectedItem={handleSelectedIndustries}
					disabled={disabledTags || loadingIndustries}
					printRequested={printRequested}
				/>
			)}
			{specialismItems.length > 0 && (
				<ButtonsRow
					items={specialismItems}
					selectedItems={mappedSpecialisms}
					label="Specialisms"
					handleSelectedItem={handleSelectedSpecialisms}
					disabled={disabledTags || loadingSpecialism}
					printRequested={printRequested}
				/>
			)}
		</div>
	);
};

export default CandidateExpertise;
