import React, {FC, Fragment, useEffect, useMemo} from "react";
import {TLinkedInBulkImportReportProps} from "./types";
import {Button} from "components";
import classNames from "classnames";
import {BiErrorCircle, BiRefresh} from "react-icons/bi";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import LinkedInBulkImportReportProgressBar from "mapx-components/Modals/SearchByLinkedInModal/Report/progressBar";
import {
	activeImportPercentageForProject,
	searchCandidatesForProjectSelector,
} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {useAppDispatch, useAppSelector} from "hooks";
import LinkedInBulkImportReportToolTip from "mapx-components/Modals/SearchByLinkedInModal/Report/Tooltip";
import styles from "./report.module.scss";
import {setActiveImportPercentageForProject} from "store/mapx/linkedin-bulk-import/linkedInBulkImportActions";
import {isLastBulkImportReportDataLoadingSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import DownloadIcon from "assets/icons/Download";

const LinkedInBulkImportReport: FC<TLinkedInBulkImportReportProps> = ({
	linkedInUrl,
	handleClick,
	handleDownload,
	inProgress,
	otherProjectInProgress,
	lastCompletedDisplay,
	importedLinkedInUrls,
	totalURLs,
}) => {
	const project = useAppSelector(projectSelector);

	const dispatch = useAppDispatch();

	const importsInProgress = useAppSelector(searchCandidatesForProjectSelector);

	const isLastBulkImportReportDataLoading = useAppSelector(
		isLastBulkImportReportDataLoadingSelector,
	);

	const successCount = useMemo(() => {
		return (
			linkedInUrl?.filter((item) =>
				["success", "imported", "completed", "already exists"].includes(
					item.status?.toLowerCase(),
				),
			).length ?? 0
		);
	}, [linkedInUrl]);

	const errorCount = useMemo(() => {
		return (
			linkedInUrl?.filter((item) => ["Bad Url", "Not Found"].includes(item.status)).length ??
			0
		);
	}, [linkedInUrl]);

	const notFoundCount = useMemo(() => {
		return linkedInUrl?.filter((item) => item.status === "Not Found").length ?? 0;
	}, [linkedInUrl]);

	const badURLCount = useMemo(() => {
		return linkedInUrl?.filter((item) => item.status === "Bad Url").length ?? 0;
	}, [linkedInUrl]);

	const waitingCount = useMemo(() => {
		return (
			linkedInUrl?.filter((item) =>
				["Bad Url", "Not Found", "Import Error", "Scraping Error"].includes(item.status),
			).length ?? 0
		);
	}, [linkedInUrl]);

	const progressCount = useMemo(() => {
		if (!linkedInUrl || linkedInUrl.length === 0) {
			return "0";
		}
		// all values which are currently not finished importing
		const nonWaitingOrImportingCount = linkedInUrl.filter(
			(item) =>
				!["waiting", "importing", "scraping", "in progress", "created", "scraped"].includes(
					item.status?.toLowerCase(),
				),
		).length;

		return nonWaitingOrImportingCount + "/" + linkedInUrl.length;
	}, [linkedInUrl]);

	const percentageLoaded = useAppSelector(activeImportPercentageForProject);

	useEffect(() => {
		if (!otherProjectInProgress && lastCompletedDisplay) {
			dispatch(setActiveImportPercentageForProject(100));
		}
	}, [inProgress, otherProjectInProgress, dispatch, project?.id, lastCompletedDisplay]);

	const getStatusColor = useMemo(() => {
		const statusColorMap: {[key: string]: string} = {
			success: "#0C5850FF",
			Imported: "#0C5850FF",
			Completed: "#0C5850FF",
			"In Progress": "#444444",
			Created: "#444444",
			Scraped: "#444444",
			"Already Exists": "#0C5850FF",
			"Import Error": "#4E5555",
			"Bad Url": "#F5A300",
			error: "#4E5555",
			"unknown error": "#4E5555",
			"Not Found": "#F5A300",
			"Scraping Error": "#4E5555",
		};

		return (status: string) => statusColorMap[status] || "#a4a4a4";
	}, []);

	const getStatusMessage = (status: string) => {
		switch (status) {
			case "created":
				return "Processing...";
			case "scraped":
				return "Scraped...";
			case "imported":
				return "Success";
			case "completed":
				return "Success";
			case "already exists":
				return "Success";
			case "in progress":
				return "Processing...";
			case "import error":
				return "Sent for manual import";
			case "scraping error":
				return "Sent for manual import";
			case "bad url":
				return "Not a valid URL";
			case "not found":
				return "Not a valid URL";
			default:
				return status;
		}
	};

	const errorMessage = useMemo(() => {
		return (
			<>
				{waitingCount - badURLCount > 0 && (
					<>
						<strong style={{color: "#2C3030"}}>
							, {waitingCount - (badURLCount + notFoundCount)}
						</strong>{" "}
						profiles will be manually added within the next 12 hours if they are valid
						URLs.
					</>
				)}
			</>
		);
	}, [waitingCount, badURLCount, notFoundCount]);

	const uniqueUrlMessage = useMemo(() => {
		return (
			<>
				{linkedInUrl && (
					<>
						Out of <strong style={{color: "#2C3030"}}>{totalURLs}</strong> Profiles,{" "}
						<strong style={{color: "#2C3030"}}>
							{linkedInUrl.length - (badURLCount + notFoundCount)}
						</strong>{" "}
						unique and valid URLs found
						<br />
						<br />
					</>
				)}
			</>
		);
	}, [totalURLs, badURLCount, notFoundCount, linkedInUrl]);

	const getColorByStatus = (status: string) => {
		const lowerStatus = status?.toLowerCase();
		if (["import error", "scraping error"].includes(lowerStatus)) {
			return "#4E5555";
		} else if (["bad url", "not found"].includes(lowerStatus)) {
			return "#F5A300";
		}

		return null;
	};

	const renderToolTip = (urlInfo: {status: string; reason: string; url: string}) => {
		const color = getColorByStatus(urlInfo.status);
		if (!color) return null;

		return (
			<span>
				<LinkedInBulkImportReportToolTip
					color={color}
					status={urlInfo.status}
					reason={urlInfo.reason}
					url={urlInfo.url}
				/>
			</span>
		);
	};

	return (
		<div className={styles.reportContainer}>
			<h3>Add People</h3>
			<hr />
			{linkedInUrl &&
			linkedInUrl.length > 0 &&
			successCount + waitingCount === linkedInUrl.length ? (
				<section style={{marginBottom: "20px"}}>
					<div className={styles.header}>
						{errorCount === 0 ? (
							<Fragment>
								<svg
									width="20"
									height="20"
									viewBox="0 0 52 52"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M22.066 37.2665L40.066 19.2665L37.7327 16.9998L22.066 32.6665L14.1993 24.7332L11.9327 27.0665L22.066 37.2665ZM25.9993 51.3332C22.5327 51.3332 19.2549 50.6665 16.166 49.3332C13.0771 47.9998 10.3882 46.1887 8.09935 43.8998C5.81046 41.6109 3.99935 38.9221 2.66602 35.8332C1.33268 32.7443 0.666016 29.4665 0.666016 25.9998C0.666016 22.4887 1.33268 19.1887 2.66602 16.0998C3.99935 13.011 5.81046 10.3332 8.09935 8.0665C10.3882 5.79984 13.0771 3.99984 16.166 2.6665C19.2549 1.33317 22.5327 0.666504 25.9993 0.666504C29.5105 0.666504 32.8105 1.33317 35.8993 2.6665C38.9882 3.99984 41.666 5.79984 43.9327 8.0665C46.1994 10.3332 47.9994 13.011 49.3327 16.0998C50.666 19.1887 51.3327 22.4887 51.3327 25.9998C51.3327 29.4665 50.666 32.7443 49.3327 35.8332C47.9994 38.9221 46.1994 41.6109 43.9327 43.8998C41.666 46.1887 38.9882 47.9998 35.8993 49.3332C32.8105 50.6665 29.5105 51.3332 25.9993 51.3332ZM25.9993 48.3332C32.2216 48.3332 37.4994 46.1554 41.8327 41.7998C46.166 37.4443 48.3327 32.1776 48.3327 25.9998C48.3327 19.7776 46.166 14.4998 41.8327 10.1665C37.4994 5.83317 32.2216 3.6665 25.9993 3.6665C19.8216 3.6665 14.5549 5.83317 10.1993 10.1665C5.84379 14.4998 3.66602 19.7776 3.66602 25.9998C3.66602 32.1776 5.84379 37.4443 10.1993 41.7998C14.5549 46.1554 19.8216 48.3332 25.9993 48.3332Z"
										fill="#0C5850FF"
									/>
								</svg>
								<p data-testid="successReport" style={{color: "#0C5850FF"}}>
									Imports {lastCompletedDisplay ? "were" : "are"} completed
								</p>
							</Fragment>
						) : (
							<Fragment>
								<BiErrorCircle size={"20px"} color={"#F5A300"} />
								<p
									data-testid="errorReport"
									style={{color: "#F5A300", fontWeight: "500"}}
								>
									Imports {lastCompletedDisplay ? "were" : "are"} completed with
									errors
								</p>
							</Fragment>
						)}
					</div>
					{successCount && successCount > 0 && waitingCount === 0 ? (
						<p className={styles.successCount}>
							{totalURLs > linkedInUrl.length - (badURLCount + notFoundCount) &&
								uniqueUrlMessage}
							<strong>{successCount}</strong> Profiles successfully added to your{" "}
							<strong>{project?.name}</strong> project
						</p>
					) : (
						<p className={styles.errorCount}>
							{totalURLs > linkedInUrl.length - (badURLCount + notFoundCount) &&
								uniqueUrlMessage}
							<strong style={{color: "#2C3030"}}>{successCount}</strong> Profiles
							added to your <strong>{project?.name}</strong> project
							{errorCount > 0 && errorMessage}
						</p>
					)}
				</section>
			) : (
				inProgress && (
					<LinkedInBulkImportReportProgressBar
						percentage={percentageLoaded}
						progressCount={progressCount}
					/>
				)
			)}

			<div className={styles.reportBox} data-testid="LinkedInBulkImportReport">
				{linkedInUrl && inProgress ? (
					linkedInUrl?.map((urlInfo) => (
						<div className={styles.reportItems} key={urlInfo.url}>
							<p style={{width: "200px"}}>{"/" + urlInfo.url.split("/").pop()}</p>
							<div
								className={styles.statuses}
								style={{color: getStatusColor(urlInfo.status)}}
							>
								{renderToolTip(urlInfo)}
								<span className={styles.status}>
									{getStatusMessage(urlInfo.status?.toLowerCase())}
								</span>
							</div>
						</div>
					))
				) : otherProjectInProgress && percentageLoaded !== 100 ? (
					<div className={styles.warn}>
						<h5>Another project is currently importing ({percentageLoaded}%)</h5>
						<small>
							When the import is finished, You can import URLs for this project
						</small>
					</div>
				) : (
					<div className={styles.warn}>
						<h5>There is an import finished on another project</h5>

						<small>you can start new import for this project now</small>
					</div>
				)}
			</div>

			{(percentageLoaded === 100 || otherProjectInProgress) &&
				!isLastBulkImportReportDataLoading && (
					<div className={styles.downloadReport}>
						{importsInProgress.data && importsInProgress.id === project.id && (
							<a
								onClick={() => handleDownload(linkedInUrl)}
								data-testid="DownloadBulkImportCsvButton"
							>
								<DownloadIcon /> <span>Download CSV report</span>
							</a>
						)}

						{importedLinkedInUrls && (
							<a
								onClick={() => handleDownload(importedLinkedInUrls)}
								data-testid="DownloadBulkImportCsvButton"
							>
								<DownloadIcon /> <span>Download all imports in CSV</span>
							</a>
						)}
					</div>
				)}

			<br />
			<Button
				data-testid="DoneButton"
				name={"Done"}
				type={"button"}
				className={classNames(styles.wrapper__form__btn)}
				onClick={() => handleClick(importsInProgress.id)}
				disabled={percentageLoaded !== 100 || isLastBulkImportReportDataLoading}
			>
				{percentageLoaded !== 100 && (
					<BiRefresh size={22} className={classNames(styles.refresh)} />
				)}{" "}
				{percentageLoaded === 100
					? `Add ${linkedInUrl && linkedInUrl.length > 0 ? "more" : ""} people`
					: otherProjectInProgress
					? `Importing on another project (${percentageLoaded}%)`
					: "Importing"}
			</Button>
		</div>
	);
};

export default LinkedInBulkImportReport;
