import {useCallback, useEffect, useMemo, useState} from "react";
import {Accordion, LocationSearchFilter} from "mapx-components";
import {
	companyCountriesSelector,
	companyHQCountriesSelector,
} from "store/mapx/filter/filterSelectors";
import {setCountryForCompany} from "store/mapx/filter/filterAsyncActions";
import {setMultipleFilterForCompany} from "store/mapx/filter/filterActions";
import {TabSelect} from "components";
import {useAppDispatch, useAppSelector} from "hooks";

const officeOptions = [
	{label: "HQ", value: "HQ"},
	{label: "All Offices", value: "ALL"},
];

const LocationFilter = () => {
	const dispatch = useAppDispatch();

	const [officeLocation, setOfficeLocation] = useState("ALL");

	const selectedCountries = useAppSelector(companyCountriesSelector);

	const selectedHQCountries = useAppSelector(companyHQCountriesSelector);

	const selectedLocationIds = useMemo(() => {
		if (officeLocation === "ALL") {
			return selectedCountries || [];
		} else {
			return selectedHQCountries || [];
		}
	}, [officeLocation, selectedCountries, selectedHQCountries]);

	const updateOnState = useCallback(
		(ids: string, OL: string) => {
			if (OL === "ALL") {
				dispatch(
					setMultipleFilterForCompany({
						countries: [...ids],
						headquarters_countries: [],
					}),
				);
			} else {
				dispatch(
					setMultipleFilterForCompany({
						countries: [],
						headquarters_countries: [...ids],
					}),
				);
			}
		},
		[dispatch],
	);

	const handleChange = useCallback(
		(id: string) => {
			dispatch(setCountryForCompany({id, officeLocation}));
		},
		[dispatch, officeLocation],
	);

	const handleOfficeChange = useCallback(
		(value: string) => {
			setOfficeLocation(value);

			if (value === "ALL" && selectedHQCountries?.length > 0) {
				updateOnState(selectedHQCountries, value);
			} else if (value === "HQ" && selectedCountries?.length > 0) {
				updateOnState(selectedCountries, value);
			}
		},
		[selectedCountries, selectedHQCountries, updateOnState],
	);

	const handleResetClick = useCallback(() => {
		dispatch(
			setMultipleFilterForCompany({
				countries: [],
				headquarters_countries: [],
			}),
		);
	}, [dispatch]);

	const handleBulkUpdate = useCallback(
		(ids: string) => updateOnState(ids, officeLocation),
		[officeLocation, updateOnState],
	);

	useEffect(() => {
		if (selectedCountries?.length > 0) {
			setOfficeLocation("ALL");
		} else {
			setOfficeLocation("HQ");
		}
	}, [selectedCountries?.length]);

	return (
		<Accordion title="Location" subtitle={selectedLocationIds?.length}>
			<TabSelect.LabelContainer>
				<TabSelect
					selected={officeLocation}
					onTabChange={handleOfficeChange}
					options={officeOptions}
				/>
			</TabSelect.LabelContainer>

			<LocationSearchFilter
				filterType={"companies"}
				handleResetClick={handleResetClick}
				handleOnChange={handleChange}
				handleBulkUpdate={handleBulkUpdate}
				selectedLocation={selectedLocationIds}
			/>
		</Accordion>
	);
};

export default LocationFilter;
