import {
	BACKGROUND_BULK_IMPORTS_IN_PROGRESS,
	SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
} from "../mapXActionTypes";
import {AnyAction} from "redux";

const initialState = {
	isLastBulkImportReportDataLoading: true,
	lastCompletedCandidateImportByLinkedInForProject: {}, // used to store completed bulk import urls
};

const backgroundTaskReducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case BACKGROUND_BULK_IMPORTS_IN_PROGRESS:
			return {
				...state,
				isLastBulkImportReportDataLoading: action.payload,
			};

		case SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT:
			return {
				...state,
				lastCompletedCandidateImportByLinkedInForProject: {
					[action.payload.id]: action.payload.data, // sets object for project by id
				},
			};
		default:
			return state;
	}
};

export default backgroundTaskReducer;
