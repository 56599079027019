import React, {type FC, useCallback, useMemo} from "react";
import classNames from "classnames";

import {ChevronIcon} from "assets/icons";
import LockIcon from "assets/icons/LockIcon";
import {UpgradeNowTooltip} from "components";
import {useAppDispatch, useAppSelector} from "hooks";
import {setActiveAccordionOnFilter} from "store/mapx/filter/filterActions";
import {activeAccordionOnFilterSelector} from "store/mapx/filter/filterSelectors";

import type {TAccordionProps} from "./types";
import styles from "./accordion.module.scss";
import SeniorityToolTip from "mapx-components/Accordion/Tooltips/SeniorityToolTip";

const Accordion: FC<TAccordionProps> = ({
	title,
	children,
	subtitle = "",
	disabled = false,
	displayLock = false,
}) => {
	const dispatch = useAppDispatch();
	const activeAccordion = useAppSelector(activeAccordionOnFilterSelector);

	const expanded = useMemo(() => activeAccordion === title, [activeAccordion, title]);

	const handleToggle = useCallback(() => {
		if (disabled) return;

		if (expanded) {
			dispatch(setActiveAccordionOnFilter(null));
		} else {
			dispatch(setActiveAccordionOnFilter(title));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled, expanded, title]);

	return (
		<div
			className={classNames(styles.accordion, {
				[styles.disabled]: disabled,
				[styles.expanded]: expanded,
			})}
			aria-expanded={expanded}
		>
			<div className={styles.header} onClick={handleToggle}>
				<div className={styles.left}>
					<div className={styles.title}>{title}</div>
					{title === "Seniority" && <SeniorityToolTip />}
					{!!subtitle && <div className={styles.count}>{subtitle}</div>}
				</div>
				<div className={styles.right}>
					{displayLock ? (
						<div data-tip={true} data-tooltip-id={title}>
							<LockIcon style={{cursor: "clickable"}} />

							<UpgradeNowTooltip id={title} />
						</div>
					) : (
						<ChevronIcon
							className={classNames(styles.chevron, {[styles.rotated]: !expanded})}
							width={24}
							height={24}
							color={disabled ? "#CBC5C2" : "#808080"}
						/>
					)}
				</div>
			</div>
			<div className={classNames(styles.children, {[styles.expanded]: expanded})}>
				{expanded && children}
			</div>
		</div>
	);
};

export default Accordion;
