import {type FC, useEffect, useRef, useState} from "react";

import type {STCompany} from "api/companyApi/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {Accordion, CompanySearchFilter} from "mapx-components";
import {setSelectedCompaniesDone} from "store/mapx/company/companyActions";
import {setFilterForCompaniesByCompaniesList} from "store/mapx/filter/filterActions";
import useGenerateFilterSetByCompanies from "hooks/mapx/useGenerateFilterSetByCompanies";
import {
	companySpecialitiesConnectivityLogicSelector,
	companySpecialitiesFilterKeyByLogic,
} from "store/mapx/filter/filterSelectors";
import {useSelector} from "react-redux";

const CompanyFilter: FC = () => {
	const dispatch = useAppDispatch();

	const companySpecialitiesConnectivityLogic = useSelector(
		companySpecialitiesConnectivityLogicSelector,
	);

	const specialtyFilterKey = useAppSelector((state) =>
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		companySpecialitiesFilterKeyByLogic(state, companySpecialitiesConnectivityLogic),
	);

	const selected_companies = useAppSelector((state) => state.filter.selected_companies);

	const filterObject = useGenerateFilterSetByCompanies({
		useSelectedCompanies: true,
		extractAttribute: true,
		extractCompanyId: false,
	});
	const [applyFilter, setApplyFilter] = useState(false);
	const isMounted = useRef(true);

	const handleItemClick = async (company: STCompany) => {
		const ids = new Set(selected_companies.map((item: STCompany) => item.id));

		if (!ids.has(company.id)) {
			dispatch(setSelectedCompaniesDone([...selected_companies, company]));

			setApplyFilter(true);
		}
	};

	const removeFromSelectedCompanyList = (id: number) => {
		const updatedSelectedCompanies = [...selected_companies].filter((item) => item.id !== id);

		dispatch(setSelectedCompaniesDone([...updatedSelectedCompanies]));

		setApplyFilter(true);
	};

	useEffect(() => {
		if (applyFilter && filterObject) {
			const filters = {...filterObject};

			// if (filters?.specialties && filters?.specialties?.length > 0) {
			// 	filters[specialtyFilterKey] = filters.specialties;
			//
			// 	delete filters.specialties;
			// }

			dispatch(setFilterForCompaniesByCompaniesList(filters));

			setApplyFilter(false);
		}

		return () => {
			isMounted.current = false;
		};
	}, [dispatch, applyFilter, filterObject, specialtyFilterKey]);

	return (
		<Accordion title="Company" subtitle={selected_companies ? selected_companies.length : ""}>
			<CompanySearchFilter
				handleOnClick={handleItemClick}
				handleRemoveClick={removeFromSelectedCompanyList}
				selectedCompanies={selected_companies}
			/>
		</Accordion>
	);
};

export default CompanyFilter;
