import {createSelector} from "reselect";
import {companyTypeOptionSelector} from "store/mapx/search/searchSelectors";
import {TRootState} from "types";
import {STCompanyType} from "api/companyApi/types";
import {TCompanyTypePosition} from "mapx-components/Filters/CandidateCompanyTypeSearchFilter/types";

export const apCandidateAnyCompanyTypesSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.company_type;
export const apCandidateCurrentCompanyTypesSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_company_type;
export const apCandidatePreviousCompanyTypesSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_company_type;

export const apCompanyTypeFilterConfigSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.company_type_filter_config;

export const apCompanyTypeCandidatePositionSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.company_type_filter_config.active_position;

const apGetCompanyTypePosition = (_: TRootState, position: TCompanyTypePosition) => position;

export const apSelectedAnyCompanyTypesSelector = createSelector(
	[apCandidateAnyCompanyTypesSelector, companyTypeOptionSelector],
	(companyTypes: string[], options: STCompanyType[]) => {
		const any: STCompanyType[] = [];

		const iSet = new Set(companyTypes);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				any.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return any;
	},
);
export const apSelectedPreviousCompanyTypesSelector = createSelector(
	[apCandidatePreviousCompanyTypesSelector, companyTypeOptionSelector],
	(companyTypes: string[], options: STCompanyType[]) => {
		const previous: STCompanyType[] = [];

		const iSet = new Set(companyTypes);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				previous.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return previous;
	},
);
export const apSelectedCurrentCompanyTypesSelector = createSelector(
	[apCandidateCurrentCompanyTypesSelector, companyTypeOptionSelector],
	(companyTypes: string[], options: STCompanyType[]) => {
		const current: STCompanyType[] = [];

		const iSet = new Set(companyTypes);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				current.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return current;
	},
);
export const apAllCompanyTypesCountSelector = createSelector(
	[
		apCandidateCurrentCompanyTypesSelector,
		apCandidatePreviousCompanyTypesSelector,
		apCandidateAnyCompanyTypesSelector,
	],
	(current, previous, any) => current?.length + previous?.length + any?.length,
);
export const apCompanyTypeFilterKeyByPosition = createSelector(
	apGetCompanyTypePosition,
	(position) => {
		switch (position) {
			case "current":
				return "current_company_type";
			case "previous":
				return "previous_company_type";
			case "any":
			default:
				return "company_type";
		}
	},
);

export const apCandidateCompanyTypesByPositionSelector = createSelector(
	[
		apCandidateCurrentCompanyTypesSelector,
		apCandidatePreviousCompanyTypesSelector,
		apCandidateAnyCompanyTypesSelector,
		(_, position) => position,
	],
	(current, previous, any, position) => {
		switch (position) {
			case "current":
				return current || [];
			case "previous":
				return previous || [];
			case "any":
				return any || [];
			default:
				return [];
		}
	},
);
