import React, {type ChangeEvent, useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import SelectedProjectTypeHeader from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/SelectedProjectTypeHeader";
import {TextInput} from "components";
import FooterButtons from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/FooterButtons";
import {
	createProjectModal,
	setProjectWorkFlowStep,
} from "store/mapx/project-list/projectListActions";
import {useAppDispatch} from "hooks";
import {useHistory} from "react-router-dom";
import SelectDropdown from "components/Inputs/SelectDropdown";
import {TStep} from "./types";
import StartWithCompaniesIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/StartWithCompaniesIcon";
import useTargetListSearchService from "services/useTargetListSearchService";
import {STMarketMap} from "api/marketMapApi/types";
import useJobTitleSearchService from "services/useJobTitleSearchService";
import useLocationSearchService from "services/useLocationSearchService";
import {STCountry} from "api/filterOptionsApi/CountryApi/types";
import useKeywordSearchService from "services/useKeywordSearchService";
import useIndustrySearchService from "services/useIndustrySearchService";
import {STJobTitle, STJobTitleClassification} from "api/filterOptionsApi/JobTitleApi/types";
import {STKeywords} from "api/filterOptionsApi/KeywordApi/types";
import {STIndustry} from "api/filterOptionsApi/IndustryApi/types";
import {TCompanyHeadcountRangeItem} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {TCompanyRevenueRangeItem} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";

import companySizeOptions from "api/companyApi/companySizeBand.json";
import companyRevenueOptions from "api/companyApi/companyRevenueBand.json";
import {saveProjectForAI} from "store/mapx/project-list/projectWorkflow";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {setFilterForCandidatesFilterSearch} from "store/mapx/filter/filterActions";
import {TProjectForm} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/types";
import {ActionMeta} from "react-select";
import mapxJobTitleApi from "api/filterOptionsApi/JobTitleApi";
import {successResponse} from "helpers/map";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import {sortObjectsAlphabetically} from "helpers/array";

const StartWithJobTitle = () => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);

	const [step, setStep] = useState<TStep>("project name");

	const [name, setName] = useState("");

	const [targetList, setTargetList] = useState<Nullable<STMarketMap>>(null);

	const [jobTitle, setJobTitle] = useState<Nullable<STJobTitle>>(null);

	const [seniorities, setSeniorities] = useState<string[]>([]);

	const [specialisms, setSpecialisms] = useState<number[]>([]);

	const [keywords, setKeywords] = useState<STKeywords[]>([]);

	const [locations, setLocations] = useState<STCountry[]>([]);

	const [industries, setIndustries] = useState<STIndustry[]>([]);

	const [companySizes, setCompanySizes] = useState<TCompanyHeadcountRangeItem[]>([]);

	const [companyRevenues, setCompanyRevenues] = useState<TCompanyRevenueRangeItem[]>([]);

	const {loadingTargetList, fetchTargetList} = useTargetListSearchService();

	const {loadingJobTitle, setLoadingJobTitle, fetchJobTitle} = useJobTitleSearchService();

	const {loadingLocations, flattenLocations} = useLocationSearchService();

	const {loadingKeyword, fetchKeywords} = useKeywordSearchService();

	const {loadingIndustry, flattenIndustries} = useIndustrySearchService();

	const history = useHistory();

	const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	}, []);

	const handleJobTitleSelection = useCallback(
		async (
			selectedOption: Nullable<STJobTitle>,
			actionMeta: ActionMeta<Nullable<STJobTitle>>,
		) => {
			if (actionMeta.action === "select-option" && selectedOption) {
				setLoadingJobTitle(true);

				const response = await mapxJobTitleApi.classifications({
					job_titles: [selectedOption.job_title],
				});

				if (successResponse(response, 200)) {
					const jobClassifications: STJobTitleClassification[] = response.data;

					let senioritySuggestions: string[] = [];
					let specsSuggestions: number[] = [];

					jobClassifications.forEach((jc) => {
						senioritySuggestions = [...senioritySuggestions, jc.top_seniority];
						specsSuggestions = [
							...specsSuggestions,
							...jc.top_specialisms.map((s) => s.id),
						];
					});

					setSeniorities(senioritySuggestions);
					setSpecialisms(specsSuggestions);
				}

				setLoadingJobTitle(false);
			} else {
				setSeniorities([]);
				setSpecialisms([]);
			}

			setJobTitle(selectedOption);
		},
		[setLoadingJobTitle],
	);

	const handleGoBack = useCallback(() => {
		if (step === "select criteria") {
			setStep("project name");
		} else {
			dispatch(setProjectWorkFlowStep(null));
		}
	}, [dispatch, step]);

	const handleCreateNewProjectButtonClick = useCallback(async () => {
		setLoading(true);

		const formData: TProjectForm = {name};

		if (targetList) {
			formData.market_map_id = targetList.id;
		}

		const newProject = await dispatch(saveProjectForAI(formData));

		if (newProject) {
			const list = await dispatch(getTargetListCompanies());

			if (Array.isArray(list) && list?.length > 0) {
				await dispatch(
					updateProjectSuccessChecklistItem({
						attribute: "has_saved_companies",
						value: true,
					}),
				);
			}

			const filterObject = {
				current_seniority: seniorities,
				current_specialisms_or: specialisms,
				text_keywords_or: keywords.map((k) => k.name), // params accepts string not number
				countries: locations.map((loc) => loc.id),
				current_industries: industries.map((i) => i.id),
				current_company_size: companySizes.map((cs) => cs.id),
				current_revenue: companyRevenues.map((cr) => cr.id),
			};

			dispatch(setFilterForCandidatesFilterSearch(filterObject));

			dispatch(createProjectModal(false));

			history.push("/candidates-filter-search");
		}

		setLoading(false);
	}, [
		name,
		targetList,
		dispatch,
		seniorities,
		specialisms,
		keywords,
		locations,
		industries,
		companySizes,
		companyRevenues,
		history,
	]);

	const handleActionButtonClick = useCallback(() => {
		if (step === "project name") {
			setStep("select criteria");
		} else {
			handleCreateNewProjectButtonClick();
		}
	}, [step, handleCreateNewProjectButtonClick]);

	const isActionButtonDisabled = useMemo(() => {
		if (step === "project name") {
			return !(name !== "" && name?.length > 3);
		} else if (step === "select criteria") {
			return !jobTitle;
		} else {
			return false;
		}
	}, [step, name, jobTitle]);

	const headerInfo = useMemo(() => {
		let info: {description: string; title: string};

		if (step === "project name") {
			info = {
				title: "Name Your Project",
				description:
					"Start by giving your project a name and picking a list of companies to search at",
			};
		} else {
			info = {
				title: "Enter Your Search Criteria",
				description: "Who are you looking to find?",
			};
		}

		return info;
	}, [step]);

	const handleEnterPress = (event: React.KeyboardEvent) => {
		if (event.code === "Enter" || event.code === "NumpadEnter") {
			event.preventDefault();

			if (!isActionButtonDisabled) {
				handleActionButtonClick();
			}
		}
	};

	return (
		<div>
			<div className={styles.container} onKeyDown={handleEnterPress}>
				<SelectedProjectTypeHeader
					title={headerInfo.title}
					description={headerInfo.description}
					icon={<StartWithCompaniesIcon />}
				/>

				{step === "project name" && (
					<div className={styles.content}>
						<TextInput
							label="Project Name*"
							onChange={handleNameChange}
							type="text"
							value={name}
							placeholder={"Enter a project name"}
						/>

						<div className={styles.inputContainer}>
							<label htmlFor="company target list">Company Target List</label>

							<SelectDropdown
								isAsync={true}
								defaultOptions={true}
								value={targetList}
								onChange={setTargetList}
								loadOptions={fetchTargetList}
								showClearIcon={!targetList}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingTargetList}
								placeholder={"Choose a previously created company target list"}
								noOptionsMessage={"Search by target list name"}
								noResultsText={
									"No target list found with your query, try to refine your query"
								}
								menuPortalTarget={document.body}
								menuPosition={"fixed"}
							/>
						</div>
					</div>
				)}

				{step === "select criteria" && (
					<div className={styles.content}>
						<div className={styles.inputContainer}>
							<label htmlFor="job title">Job Title *</label>

							<SelectDropdown
								isAsync={true}
								value={jobTitle}
								onChange={handleJobTitleSelection}
								loadOptions={fetchJobTitle}
								showClearIcon={!jobTitle}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingJobTitle}
								placeholder={"What job title are you searching for?"}
								noOptionsMessage={"Search by job title"}
								noResultsText={
									"No job title found with your query, try to refine your query"
								}
								formatOptionLabel={(item: STJobTitle) => (
									<div className={styles.dropdownItem}>
										<span>{item.job_title}</span>
									</div>
								)}
							/>
						</div>

						<div className={styles.inputContainer}>
							<label htmlFor="example companies">Keywords</label>

							<SelectDropdown
								isMulti={true}
								isAsync={true}
								value={keywords}
								onChange={setKeywords}
								loadOptions={fetchKeywords}
								showClearIcon={keywords?.length > 0}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingKeyword}
								placeholder={
									"Any specific words or phrases to include in their profile?"
								}
								noOptionsMessage={"Search by keyword string"}
								noResultsText={
									"No keyword found with your query, try to refine your query"
								}
							/>
						</div>

						<div className={styles.inputContainer}>
							<label htmlFor={"Location"}>Location</label>

							<SelectDropdown
								isMulti={true}
								value={locations}
								onChange={setLocations}
								options={flattenLocations}
								showClearIcon={locations?.length > 0}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingLocations}
								placeholder={"Which locations do you want to see people from?"}
								noOptionsMessage={"Search by country"}
								noResultsText={
									"No location found with your query, try to refine your query"
								}
							/>
						</div>

						<div className={styles.inputContainer}>
							<label htmlFor="company target list">Industry</label>

							<SelectDropdown
								isMulti={true}
								defaultOptions={true}
								value={industries}
								onChange={setIndustries}
								options={sortObjectsAlphabetically(
									flattenIndustries.filter((i) => i.total_companies_count >= 100),
									"name",
								)}
								showClearIcon={industries?.length > 0}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingIndustry}
								placeholder={
									"Which industries or sectors should these people work in?"
								}
								noOptionsMessage={"Search by industry name"}
								noResultsText={
									"No industry found with your query, try to refine your query"
								}
							/>
						</div>

						<div className={styles.inputContainer}>
							<label htmlFor={"Location"}>Company Size</label>

							<SelectDropdown
								isMulti={true}
								value={companySizes}
								onChange={setCompanySizes}
								options={companySizeOptions}
								showClearIcon={companySizes?.length > 0}
								isSearchable={false}
								isClearable={true}
								placeholder={"What company sizes (in headcount) are relevant?"}
								isDisabled={companyRevenues?.length > 0}
								menuPortalTarget={document.body}
								menuPosition={"fixed"}
							/>
						</div>

						<div className={styles.inputContainer}>
							<label htmlFor={"Location"}>Company Revenue</label>

							<SelectDropdown
								isMulti={true}
								value={companyRevenues}
								onChange={setCompanyRevenues}
								options={companyRevenueOptions}
								showClearIcon={companyRevenues?.length > 0}
								isSearchable={false}
								isClearable={true}
								placeholder={"What company revenue sizes are relevant?"}
								isDisabled={companySizes?.length > 0}
								menuPortalTarget={document.body}
								menuPosition={"fixed"}
							/>
						</div>
					</div>
				)}
			</div>

			<FooterButtons
				actionButtonLabel={step === "select criteria" ? "Create New Project" : "Continue"}
				handleGoButtonClick={handleGoBack}
				handleActionButtonClick={handleActionButtonClick}
				isActionButtonDisabled={isActionButtonDisabled}
				isLoading={loading}
			/>
		</div>
	);
};

export default StartWithJobTitle;
