import React, {useCallback, useMemo} from "react";
import styles from "./styles.module.scss";
import {GeneralButton} from "mapx-components";
import {useHistory} from "react-router-dom";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "hooks";
import {
	getProjectListInProgressSelector,
	projectSelector,
	projectsSelector,
} from "store/mapx/project-list/projectListSelectors";
import {STProject} from "api/projectApi/types";
import {CreateProjectIcon} from "assets/icons";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import {noop} from "lodash";
import {createProjectModal, setProject} from "store/mapx/project-list/projectListActions";
import {ProgressBar} from "components";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import ProjectSkeletonLoader from "mapx-pages/Home/ProjectList/ProjectItemSkeletonLoader";

const ProjectList = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const projects = useAppSelector(projectsSelector);

	const selectedProjectInState = useAppSelector(projectSelector);

	const projectsLoading = useAppSelector(getProjectListInProgressSelector);

	const isStarterOrPlusUser: boolean =
		userLicenseTypeName === "Starter" || userLicenseTypeName === "Plus";

	const displayedProjects = useMemo(
		() => [...projects].splice(0, isStarterOrPlusUser ? 12 : 11),
		[isStarterOrPlusUser, projects],
	);

	const handleProjectClick = useCallback(
		(selectedProject: STProject) => {
			if (selectedProjectInState) {
				dispatch(clearAPWorkflowState());
			}

			if (
				selectedProjectInState === null ||
				selectedProjectInState.id !== selectedProject.id
			) {
				dispatch(setProject(selectedProject));
			}

			history.push(`/project/${selectedProject.id}`);
		},
		[dispatch, history, selectedProjectInState],
	);

	return (
		<div className={styles.container}>
			{displayedProjects?.length > 0 && (
				<div className={styles.titleArea}>
					<span className={styles.title}>Recent Projects</span>
					<GeneralButton
						title={"View All Projects"}
						className={styles.viewAllProjectButton}
						handleClick={() => history.push("/projects")}
					/>
				</div>
			)}

			<div className={styles.contentArea}>
				{!isStarterOrPlusUser && (
					<div
						data-testid="addNewProjectButtonOnHome"
						className={classNames(styles.card, styles.createProjectCard)}
						onClick={
							isStarterOrPlusUser ? noop : () => dispatch(createProjectModal(true))
						}
					>
						<CreateProjectIcon />
						<span>Start New Project</span>
					</div>
				)}

				{projectsLoading && <ProjectSkeletonLoader count={3} />}

				{!projectsLoading &&
					displayedProjects.map((project: STProject) => (
						<div
							key={project.id}
							className={classNames(styles.card, styles.projectCard)}
						>
							<div
								className={styles.projectTitle}
								onClick={() => handleProjectClick(project)}
							>
								{project.name}
							</div>

							<div className={styles.targetListInfo}>
								<span>{project.candidates_number} People</span>
								<span>
									{project.companies_number}{" "}
									{`${project.companies_number > 1 ? "Companies" : "Company"}`}
								</span>
							</div>

							<div className={styles.targetListInfo}>
								<div className={styles.diversityInfo}>
									<div>
										Diversity <span>{project.diversity_percent ?? 0}%</span>
									</div>
									<ProgressBar
										mini
										value={project.diversity_percent ?? 0}
										className={styles.progress}
										background={`linear-gradient(90deg, #0C5850 100%, #DAD8D7 100%)`}
									/>
								</div>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default ProjectList;
