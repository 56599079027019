import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import {setCompaniesOnCandidateFilter} from "store/mapx/filter/filterAsyncActions";
import {companiesByPositionSelector} from "store/mapx/filter/filterSelectors";

function Tags() {
	const dispatch = useDispatch();

	const companies = useSelector(companiesByPositionSelector);

	const handleTagClick = useCallback(
		(company) => {
			dispatch(setCompaniesOnCandidateFilter(company));
		},
		[dispatch],
	);

	return (
		<FilterTags
			label="Added Companies"
			count={companies.length}
			tags={companies.map((item) => (
				<Tag key={item.id} onClick={() => handleTagClick(item)}>
					{item.name}
				</Tag>
			))}
		/>
	);
}

export default React.memo(Tags);
