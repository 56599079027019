import HttpClient from "api/index";
import {
	backgroundTaskApiPaginationOptions,
	backgroundTaskApiParameterOptions,
	TBtLinkedinImports,
} from "./types";

class BackgroundTaskAPI extends HttpClient {
	async createBackgroundTaskRequest(formBody: TBtLinkedinImports, config = {}) {
		return this.doPost(`/background-tasks`, formBody, config);
	}

	async getAllBackgroundTaskRequests(
		parameterOptions: backgroundTaskApiParameterOptions,
		paginationOptions: backgroundTaskApiPaginationOptions,
		config: object,
	) {
		const {projectId, status, backgroundTaskType} = parameterOptions;
		const {page, perPage} = paginationOptions;

		// Base query with mandatory parameters
		let filterQuery = `?page=${page}&per_page=${perPage}&status=${status}&background_task_type=${backgroundTaskType}`;

		// Conditionally add the optional project_id parameter
		if (projectId !== undefined && projectId !== null) {
			filterQuery += `&project_id=${projectId}`;
		}

		return this.doGet(`/background-tasks${filterQuery}`, config);
	}

	async getBackgroundTasksById(id: number, config = {}) {
		return this.doGet(`/background-tasks/${id}`, config);
	}
}

const mapXBackgroundTaskAPI = new BackgroundTaskAPI();

export default mapXBackgroundTaskAPI;
