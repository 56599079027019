import {createSelector} from "reselect";
import {TRootState} from "types";
import {
	TCompanyHeadcountPosition,
	TCompanyHeadcountRangeItem,
} from "mapx-components/Filters/CompanyHeadcountSearchFilter/types";
import {companyHeadcountRangeOptionsSelector} from "store/mapx/search/searchSelectors";

export const apCandidateAnyCompanySizeSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.company_size;

export const apCandidateCurrentCompanySizeSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_company_size;

export const apCandidatePreviousCompanySizeSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_company_size;

export const apCandidateCompanyHeadcountFilterConfigSelector = (state: TRootState) =>
	state.additional_profiles.company_headcount_filter_config;

export const apCandidateCompanyHeadcountFilterActivePositionSelector = (state: TRootState) =>
	state.additional_profiles.company_headcount_filter_config.active_position;

const getCompanyHeadcountPosition = (_: TRootState, position: TCompanyHeadcountPosition) =>
	position;

export const apSelectedCurrentCompanyHeadcountTagsSelector = createSelector(
	[apCandidateCurrentCompanySizeSelector, companyHeadcountRangeOptionsSelector],
	(selectedSizes, options) => {
		const current: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (selectedSizes.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const apSelectedPreviousCompanyHeadcountTagsSelector = createSelector(
	[apCandidatePreviousCompanySizeSelector, companyHeadcountRangeOptionsSelector],
	(selectedSizes, options) => {
		const prev: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (selectedSizes.includes(item.id)) {
				prev.push(item);
			}
		});

		return prev;
	},
);

export const apSelectedAnyCompanyHeadcountTagsSelector = createSelector(
	[apCandidateAnyCompanySizeSelector, companyHeadcountRangeOptionsSelector],
	(selectedSizes, options) => {
		const any: TCompanyHeadcountRangeItem[] = [];

		options.forEach((item: TCompanyHeadcountRangeItem) => {
			if (selectedSizes.includes(item.id)) {
				any.push(item);
			}
		});

		return any;
	},
);

export const apCompanyHeadcountFilterKeyByPosition = createSelector(
	getCompanyHeadcountPosition,
	(position) => {
		switch (position) {
			case "current":
				return "current_company_size";
			case "previous":
				return "previous_company_size";
			case "any":
			default:
				return "company_size";
		}
	},
);

export const apCandidateCompanyHeadcountByPositionSelector = createSelector(
	[
		apCandidateCurrentCompanySizeSelector,
		apCandidatePreviousCompanySizeSelector,
		apCandidateAnyCompanySizeSelector,
		(_, position) => position,
	],
	(currentHeadcount, previousHeadcount, anyHeadcount, position) => {
		switch (position) {
			case "current":
				return currentHeadcount || null;
			case "previous":
				return previousHeadcount || null;
			case "any":
				return anyHeadcount || null;
			default:
				return [];
		}
	},
);

export const apCandidatesAllCompanyHeadcountCountSelector = createSelector(
	[
		apCandidateCurrentCompanySizeSelector,
		apCandidatePreviousCompanySizeSelector,
		apCandidateAnyCompanySizeSelector,
	],
	(current, previous, any) => current.length + previous.length + any.length,
);
