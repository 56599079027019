import React, {type ChangeEvent, useCallback, useState} from "react";
import styles from "./styles.module.scss";
import {TextInput} from "components";
import classNames from "classnames";
import FooterButtons from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/FooterButtons";
import SelectDropdown from "components/Inputs/SelectDropdown";
import useLocationSearchService from "services/useLocationSearchService";
import {STCountry} from "api/filterOptionsApi/CountryApi/types";
import {STUser} from "api/organisationApi/types";
import useUserSearchService from "services/useUserSearchService";
import placeHolderImage from "assets/images/avatar-icon.png";
import {TProjectForm} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm/types";

const ProjectForm = ({
	formType,
	onlyShareOption = false,
	projectName = "",
	projectLocation = null,
	projectUsers = [],
	handleSubmitButtonClick,
	handleGoBackButtonClick,
	loading,
}: TProjectForm) => {
	const [name, setName] = useState(projectName);

	const [location, setLocation] = useState<Nullable<STCountry>>(projectLocation);

	const [users, setUsers] = useState<STUser[]>(projectUsers);

	const {loadingLocations, locations} = useLocationSearchService();

	const {loadingUsers, fetchUsers} = useUserSearchService();

	const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	}, []);

	// const handleUserChange = useCallback(
	//     (users: Options<STUser[]>, action: ActionMeta<Options<STUser[]>>) => {
	//     },
	//     [users],
	// );

	const handleSubmit = () => {
		handleSubmitButtonClick({
			name,
			location,
			users,
		});
	};

	return (
		<div>
			<div className={classNames(styles.formContainer, styles.withPadding)}>
				<form onSubmit={handleSubmit}>
					{!onlyShareOption && (
						<div className={styles.itemBlock}>
							<TextInput
								label="Project Name*"
								onChange={handleNameChange}
								type="text"
								value={name}
								placeholder={"Enter a project name"}
							/>
						</div>
					)}

					{!onlyShareOption && (
						<div className={styles.itemBlock}>
							<label className={styles.label}>Location</label>

							<SelectDropdown
								borderColor="#dadada"
								value={location}
								onChange={setLocation}
								options={locations}
								showClearIcon={!loadingLocations}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingLocations}
								placeholder={"Select a Location"}
								noOptionsMessage={"Select a Location"}
								noResultsText={
									"No location found with your query, try to refine your query"
								}
							/>
						</div>
					)}

					<div className={styles.itemBlock}>
						<label className={styles.label}>Invite user</label>

						<SelectDropdown
							isAsync={true}
							isMulti={true}
							borderColor="#dadada"
							value={users}
							onChange={setUsers}
							cacheOptions
							loadOptions={fetchUsers}
							showClearIcon={!loadingUsers}
							isSearchable={true}
							isClearable={true}
							isLoading={loadingUsers}
							placeholder={"Invite user by name or email"}
							noOptionsMessage={"Invite user by name or email"}
							noResultsText={
								"No user found with your query, try to refine your query"
							}
							formatOptionLabel={(user: STUser) => (
								<div className={styles.dropdownItem}>
									<img src={placeHolderImage} alt={user.first_name} />
									<span>{`${user.first_name} ${user.last_name}`}</span>
								</div>
							)}
						/>
					</div>
				</form>
			</div>

			<FooterButtons
				actionButtonLabel={
					formType === "create-form" ? "Create New Project" : "Save Project"
				}
				handleActionButtonClick={handleSubmit}
				handleGoButtonClick={handleGoBackButtonClick}
				isLoading={loading}
				isActionButtonDisabled={name === "" || name?.length < 2}
			/>
		</div>
	);
};

export default ProjectForm;
