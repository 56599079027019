import {createSelector} from "reselect";
import {flattenIndustriesSelector} from "store/mapx/search/searchSelectors";
import {TRootState} from "types";
import {TJobFunctionPosition} from "api/filterOptionsApi/JobFunctionApi/types";
import {STIndustry} from "api/filterOptionsApi/IndustryApi/types";

export const candidateAnyIndustriesSelector = (state: TRootState) =>
	state.filter.candidate_filters.industries;
export const candidateCurrentIndustriesSelector = (state: TRootState) =>
	state.filter.candidate_filters.current_industries;
export const candidatePreviousIndustriesSelector = (state: TRootState) =>
	state.filter.candidate_filters.previous_industries;

export const industryFilterConfigSelector = (state: TRootState) =>
	state.filter.candidate_filters.industries_filter_config;

export const industryCandidatePositionSelector = (state: TRootState) =>
	state.filter.candidate_filters.industries_filter_config.active_position;

const getIndustryPosition = (_: TRootState, position: TJobFunctionPosition) => position;

export const selectedAnyIndustriesSelector = createSelector(
	[candidateAnyIndustriesSelector, flattenIndustriesSelector],
	(industries: number[], options = []) => {
		const any: STIndustry[] = [];

		const iSet = new Set(industries);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				any.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return any;
	},
);
export const selectedPreviousIndustriesSelector = createSelector(
	[candidatePreviousIndustriesSelector, flattenIndustriesSelector],
	(industries, options) => {
		const previous: STIndustry[] = [];

		const iSet = new Set(industries);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				previous.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return previous;
	},
);
export const selectedCurrentIndustriesSelector = createSelector(
	[candidateCurrentIndustriesSelector, flattenIndustriesSelector],
	(industries, options) => {
		const current: STIndustry[] = [];

		const iSet = new Set(industries);

		options.forEach((o) => {
			if (iSet.has(o.id)) {
				current.push({
					id: o.id,
					name: o.name,
				});
			}
		});

		return current;
	},
);
export const allIndustriesCountSelector = createSelector(
	[
		candidateCurrentIndustriesSelector,
		candidatePreviousIndustriesSelector,
		candidateAnyIndustriesSelector,
	],
	(current, previous, any) => current?.length + previous?.length + any?.length,
);
export const industriesFilterKeyByPosition = createSelector(getIndustryPosition, (position) => {
	switch (position) {
		case "current":
			return "current_industries";
		case "previous":
			return "previous_industries";
		case "any":
		default:
			return "industries";
	}
});

export const candidateIndustriesByPositionSelector = createSelector(
	[
		candidateCurrentIndustriesSelector,
		candidatePreviousIndustriesSelector,
		candidateAnyIndustriesSelector,
		(_, position) => position,
	],
	(currentIndustries, previousIndustries, anyIndustries, position) => {
		switch (position) {
			case "current":
				return currentIndustries || [];
			case "previous":
				return previousIndustries || [];
			case "any":
				return anyIndustries || [];
			default:
				return [];
		}
	},
);
