import {createSelector} from "reselect";
import {TRootState} from "types";
import {
	TKeywordPosition,
	TKeywordPositionConnectivityLogic,
} from "api/filterOptionsApi/KeywordApi/types";

export const apKeywordFilterConfigSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.keyword_filter_config;

export const apKeywordCandidatePositionSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.keyword_filter_config.active_position;

export const apKeywordCurrentPositionConnectivityLogicSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.keyword_filter_config
		.active_current_position_connect_logic;

export const apKeywordPreviousPositionConnectivityLogicSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.keyword_filter_config
		.active_previous_position_connect_logic;

export const apKeywordAnyPositionConnectivityLogicSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.keyword_filter_config.active_any_position_connect_logic;

export const apCurrentAndKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_text_keywords_and;
export const apCurrentOrKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_text_keywords_or;
export const apCurrentNotKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_text_keywords_not;

export const apPreviousAndKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_text_keywords_and;
export const apPreviousOrKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_text_keywords_or;
export const apPreviousNotKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_text_keywords_not;

export const apAnyAndKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.text_keywords_and;
export const apAnyOrKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.text_keywords_or;
export const apAnyNotKeywordsSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.text_keywords_not;

const getAPKeywordPosition = (_: TRootState, position: TKeywordPosition) => position;
const getAPKeywordPositionConnectLogic = (
	_: TRootState,
	__: TRootState,
	logic: TKeywordPositionConnectivityLogic,
) => logic;

export const apKeywordsByPositionSelector = createSelector(
	[
		apCurrentAndKeywordsSelector,
		apCurrentOrKeywordsSelector,
		apCurrentNotKeywordsSelector,
		apPreviousAndKeywordsSelector,
		apPreviousOrKeywordsSelector,
		apPreviousNotKeywordsSelector,
		apAnyAndKeywordsSelector,
		apAnyOrKeywordsSelector,
		apAnyNotKeywordsSelector,
		getAPKeywordPosition,
		getAPKeywordPositionConnectLogic,
	],
	(
		currentAndKeywords,
		currentOrKeywords,
		currentNotKeywords,
		previousAndKeywords,
		previousOrKeywords,
		previousNotKeywords,
		anyAndKeywords,
		anyOrKeywords,
		anyNotKeywords,
		position,
		logic,
	) => {
		if (position === "current" && logic === "and") {
			return currentAndKeywords || [];
		} else if (position === "current" && logic === "or") {
			return currentOrKeywords || [];
		} else if (position === "current" && logic === "not") {
			return currentNotKeywords || [];
		} else if (position === "previous" && logic === "and") {
			return previousAndKeywords || [];
		} else if (position === "previous" && logic === "or") {
			return previousOrKeywords || [];
		} else if (position === "previous" && logic === "not") {
			return previousNotKeywords || [];
		} else if (position === "any" && logic === "and") {
			return anyAndKeywords || [];
		} else if (position === "any" && logic === "or") {
			return anyOrKeywords || [];
		} else {
			return anyNotKeywords || [];
		}
	},
);

export const apCurrentKeywordTagsSelector = createSelector(
	[apCurrentAndKeywordsSelector, apCurrentOrKeywordsSelector, apCurrentNotKeywordsSelector],
	(andKeywords: [], orKeywords: [], notKeywords: []) => {
		return [...andKeywords, ...orKeywords, ...notKeywords];
	},
);
export const apPreviousKeywordTagsSelector = createSelector(
	[apPreviousAndKeywordsSelector, apPreviousOrKeywordsSelector, apPreviousNotKeywordsSelector],
	(andKeywords: [], orKeywords: [], notKeywords: []) => {
		return [...andKeywords, ...orKeywords, ...notKeywords];
	},
);
export const apAnyKeywordTagsSelector = createSelector(
	[apAnyAndKeywordsSelector, apAnyOrKeywordsSelector, apAnyNotKeywordsSelector],
	(andKeywords: [], orKeywords: [], notKeywords: []) => {
		return [...andKeywords, ...orKeywords, ...notKeywords];
	},
);

export const apKeywordFilterKeyByPosition = createSelector(
	getAPKeywordPosition,
	getAPKeywordPositionConnectLogic,
	(position, logic) => {
		if (position === "current" && logic === "and") {
			return "current_text_keywords_and";
		} else if (position === "current" && logic === "or") {
			return "current_text_keywords_or";
		} else if (position === "current" && logic === "not") {
			return "current_text_keywords_not";
		} else if (position === "previous" && logic === "and") {
			return "previous_text_keywords_and";
		} else if (position === "previous" && logic === "or") {
			return "previous_text_keywords_or";
		} else if (position === "previous" && logic === "not") {
			return "previous_text_keywords_not";
		} else if (position === "any" && logic === "and") {
			return "text_keywords_and";
		} else if (position === "any" && logic === "or") {
			return "text_keywords_or";
		} else {
			return "text_keywords_not";
		}
	},
);

export const allAPKeywordsCountSelector = createSelector(
	[
		apCurrentAndKeywordsSelector,
		apCurrentOrKeywordsSelector,
		apCurrentNotKeywordsSelector,
		apPreviousAndKeywordsSelector,
		apPreviousOrKeywordsSelector,
		apPreviousNotKeywordsSelector,
		apAnyAndKeywordsSelector,
		apAnyOrKeywordsSelector,
		apAnyNotKeywordsSelector,
	],
	(
		currentAndKeywords,
		currentOrKeywords,
		currentNotKeywords,
		previousAndKeywords,
		previousOrKeywords,
		previousNotKeywords,
		anyAndKeywords,
		anyOrKeywords,
		anyNotKeywords,
	) =>
		currentAndKeywords.length +
		currentOrKeywords.length +
		currentNotKeywords.length +
		previousAndKeywords.length +
		previousOrKeywords.length +
		previousNotKeywords.length +
		anyAndKeywords.length +
		anyOrKeywords.length +
		anyNotKeywords.length,
);

export const allAPKeywordLabelSelector = createSelector(
	[
		apCurrentAndKeywordsSelector,
		apCurrentOrKeywordsSelector,
		apCurrentNotKeywordsSelector,
		apPreviousAndKeywordsSelector,
		apPreviousOrKeywordsSelector,
		apPreviousNotKeywordsSelector,
		apAnyAndKeywordsSelector,
		apAnyOrKeywordsSelector,
		apAnyNotKeywordsSelector,
	],
	(
		currentAndKeywords,
		currentOrKeywords,
		currentNotKeywords,
		previousAndKeywords,
		previousOrKeywords,
		previousNotKeywords,
		anyAndKeywords,
		anyOrKeywords,
		anyNotKeywords,
	) => [
		...currentAndKeywords,
		...currentOrKeywords,
		...currentNotKeywords,
		...previousAndKeywords,
		...previousOrKeywords,
		...previousNotKeywords,
		...anyAndKeywords,
		...anyOrKeywords,
		...anyNotKeywords,
	],
);
