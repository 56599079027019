import type {FC} from "react";
import ContentLoader from "react-content-loader";

import styles from "./CompanySkeletons.module.scss";

const skeletonItemCount = new Array(5).fill("");

const OrganizationChartSkeleton: FC = () => {
	const renderItems = skeletonItemCount.map((_, idx) => (
		<ContentLoader
			key={idx}
			height={100}
			width={"220px"}
			viewBox="0 0 400 100"
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
			style={{
				border: "1px solid #d1cdcc",
				borderRightWidth: idx !== skeletonItemCount.length - 1 ? "0" : "1px",
			}}
		>
			<circle cx="80" cy="50" r="50" />
			<rect x="155px" y="15.2" rx="5" ry="5" width="150px" height="21px" />
			<rect x="155px" y="45" rx="5" ry="5" width="180px" height="35px" />
		</ContentLoader>
	));

	return (
		<div className={styles.skeleton_container}>
			{skeletonItemCount.map((_, idx) => (
				<div key={idx}>{renderItems}</div>
			))}
		</div>
	);
};

export default OrganizationChartSkeleton;
