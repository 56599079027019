import React, {useCallback, useMemo, useState} from "react";
import styles from "mapx-pages/Project/SavedPeople/styles.module.scss";
import {ActionAltButton} from "mapx-components";
import classNames from "classnames";
import {ArrowDown, MagicWandIcon} from "assets/icons";
import FilterIcon from "assets/icons/IconFilter";
import {useAppDispatch, useAppSelector} from "hooks";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {getAllGenerateSummaryCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateSummarySelectors";
import customConfirmAlert from "helpers/customConfirmAlert";
import {generateCandidatesSummary} from "store/mapx/project/generateCandidateSummaryAsyncActions";
import {SICandidate} from "api/candidateApi/types";
import useCandidateSummaryInstantDisplay from "../useCandidateSummaryInstantDisplay";
import {TSavedPeopleHeaderSectionProps} from "mapx-pages/Project/SavedPeople/SavedPeopleHeaderSection/types";
import {BiPhone} from "react-icons/bi";
import {getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateContactDetailsSelectors";
import {generateCandidatesContactDetails} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import useCandidateContactDetailsInstantDisplay from "../useCandidateContactDetailsInstantDisplay";

const SavedPeopleHeaderSection = ({
	displayAllExperience,
	setDisplayAllExperience,
	currentDisplayedCount,
	totalSavedPeopleCount,
	displayedCandidates,
	hasActiveFilters,
}: TSavedPeopleHeaderSectionProps) => {
	const dispatch = useAppDispatch();

	const targetListID = useAppSelector(targetListIDSelector);

	const [creatingSummaryRequest, setCreatingSummaryRequest] = useState(false);

	const [creatingContactDetailsRequest, setCreatingContactDetailsRequest] = useState(false);

	const isSummaryForInProgressForCurrentProject = useAppSelector<number[]>((state) =>
		getAllGenerateSummaryCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	const isContactDetailsForInProgressForCurrentProject = useAppSelector<number[]>((state) =>
		getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	const generatingSummaryForAll = useMemo(
		() =>
			creatingSummaryRequest ||
			isSummaryForInProgressForCurrentProject?.length === displayedCandidates?.length,
		[
			creatingSummaryRequest,
			isSummaryForInProgressForCurrentProject?.length,
			displayedCandidates?.length,
		],
	);

	const generatingContactDetailsForAll = useMemo(
		() =>
			creatingContactDetailsRequest ||
			isContactDetailsForInProgressForCurrentProject?.length === displayedCandidates?.length,
		[
			creatingContactDetailsRequest,
			isContactDetailsForInProgressForCurrentProject?.length,
			displayedCandidates?.length,
		],
	);

	const shouldDisplayGenerateAllSummary = useMemo(
		() =>
			totalSavedPeopleCount <= 20 &&
			displayedCandidates.some((candidate) => candidate.summary === null),
		[displayedCandidates, totalSavedPeopleCount],
	);

	const shouldDisplayGenerateAllContactDetails = useMemo(
		() =>
			totalSavedPeopleCount <= 20 &&
			displayedCandidates.some((candidate) => candidate.contact_details === null),
		[displayedCandidates, totalSavedPeopleCount],
	);

	const handleGenerateAllSummariesButtonClick = useCallback(async () => {
		customConfirmAlert({
			yesButtonLabel: "Generate",
			noButtonLabel: "Back",
			title: `This action will generate ${
				displayedCandidates.filter((candidate) => !candidate.summary).length
			} candidate summaries.`,
			message: `Would you like to continue?`,
			alertType: "warning",
			handlePressYes: async () => {
				setCreatingSummaryRequest(true);

				const ids = displayedCandidates
					.filter((item: SICandidate) => item.summary === null)
					.map((item: SICandidate) => item.id);

				await dispatch(generateCandidatesSummary(ids));

				setCreatingSummaryRequest(false);
			},
		});
	}, [dispatch, displayedCandidates]);

	const handleGenerateAllContactDetailsButtonClick = useCallback(async () => {
		customConfirmAlert({
			yesButtonLabel: "Generate",
			noButtonLabel: "Back",
			title: `This action will generate ${
				displayedCandidates.filter((candidate) => !candidate.contact_details).length
			} candidate contact details.`,
			message: `Would you like to continue?`,
			alertType: "warning",
			handlePressYes: async () => {
				setCreatingContactDetailsRequest(true);

				const ids = displayedCandidates
					.filter((item: SICandidate) => item.contact_details === null)
					.map((item: SICandidate) => item.id);

				await dispatch(generateCandidatesContactDetails(ids));

				setCreatingContactDetailsRequest(false);
			},
		});
	}, [dispatch, displayedCandidates]);

	useCandidateSummaryInstantDisplay();

	useCandidateContactDetailsInstantDisplay();

	return (
		<div className={styles.headerInfoWrapper}>
			<div className={styles.left}>
				<ActionAltButton
					key={"expand"}
					customClass={classNames(styles.toggleExpandButton, {
						[styles.reverse]: displayAllExperience,
					})}
					iconComponent={<ArrowDown />}
					handleClick={() => setDisplayAllExperience((prev) => !prev)}
					title={displayAllExperience ? "Collapse All" : "Expand All"}
				/>

				{hasActiveFilters && (
					<div className={styles.filterCountInfo}>
						<span>
							<FilterIcon height={20} width={20} />
						</span>{" "}
						Showing {currentDisplayedCount ?? 0} of {totalSavedPeopleCount} people...
					</div>
				)}
			</div>

			<div className={styles.right}>
				{shouldDisplayGenerateAllSummary && (
					<ActionAltButton
						key={"summary"}
						customClass={classNames(styles.generateSummaryButton, {
							[styles.loading]: generatingSummaryForAll,
						})}
						iconComponent={<MagicWandIcon />}
						handleClick={handleGenerateAllSummariesButtonClick}
						title={
							generatingSummaryForAll
								? "Generating Summary for All..."
								: "Generate Summary for All"
						}
						loading={generatingSummaryForAll}
					/>
				)}
				{shouldDisplayGenerateAllContactDetails && (
					<ActionAltButton
						key={"contact"}
						customClass={classNames(styles.generateSummaryButton, {
							[styles.loading]: generatingContactDetailsForAll,
						})}
						iconComponent={<BiPhone color={"#4E5555"} />}
						handleClick={handleGenerateAllContactDetailsButtonClick}
						title={
							generatingContactDetailsForAll
								? "Generating contact details for All..."
								: "Generate contact details for All"
						}
						loading={generatingContactDetailsForAll}
					/>
				)}
			</div>
		</div>
	);
};

export default SavedPeopleHeaderSection;
