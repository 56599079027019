import {type FC} from "react";
import {useAppSelector} from "hooks";

import styles from "mapx-pages/Project/Overview/IndustriesReport/industriesReport.module.scss";
import IndustrieskeletonLoader from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportSkeletonLoader";
import IndustriesReportData from "mapx-pages/Project/Overview/IndustriesReport/IndustriesReportData";

const IndustriesReport: FC = () => {
	const industryReportLoading = useAppSelector(
		(state) => state.mapxProject.industryReportLoading,
	);

	return (
		<div className={styles.industryReportContainer}>
			{industryReportLoading && <IndustrieskeletonLoader columns={2} rows={2} />}
			<IndustriesReportData />
		</div>
	);
};
export default IndustriesReport;
