import type {FC} from "react";
import {useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import colors from "styles/themes.module.scss";
import {useAppSelector, useModalHook} from "hooks";
import type {TSearchByLinkedInProps, TState, TUrlList} from "./types";
import styles from "./searchByLinkedIn.module.scss";
import Form from "./Form";
import LinkedInBulkImportReport from "mapx-components/Modals/SearchByLinkedInModal/Report";
import {
	searchCandidatesForProjectSelector,
	showLastBulkImportReportSelector,
} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {toast} from "react-toastify";
import {lastCompletedCandidateImportByLinkedInForProjectSelector} from "store/mapx/background-tasks/backgroundTaskSelectors";
import Papa from "papaparse";

const SearchByLinkedInModal: FC<TSearchByLinkedInProps> = ({
	setModalDisplay,
	modalDisplay = true,
	onSubmit,
	inProgress,
	onSuccessClose,
	linkedInUrl,
	linkedInModalTestId = "",
	initialState = "link-submit",
}) => {
	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{content: {width: "550px", borderRadius: "8px"}},
		modalDisplay,
		colors.mainThemeColor,
	);

	const [state, setState] = useState<TState>("link-submit");

	const isImporting = useAppSelector(searchCandidatesForProjectSelector);

	const project = useAppSelector(projectSelector);

	const showLastBulkImportReport = useAppSelector(showLastBulkImportReportSelector);

	const lastCompletedCandidateImportByLinkedInForProject = useAppSelector(
		lastCompletedCandidateImportByLinkedInForProjectSelector,
	);

	const sortUrls = (urls: TUrlList[]) => {
		const statusPriority: {[key: string]: number} = {
			success: 1,
			imported: 1,
			completed: 1,
			"already exists": 1,
			"scraping error": 2,
			"import error": 3,
			"not found": 4,
			"bad url": 5,
		};

		if (!urls) return [];

		// Step 1: Filter duplicates by keeping the highest priority status
		const uniqueUrls: {[url: string]: TUrlList} = {};

		urls.forEach((url) => {
			const status = url.status.toLowerCase();
			const priority = statusPriority[status] || 2;

			// If URL is not in the map or the current status has a higher priority, update the map
			if (
				!uniqueUrls[url.url] ||
				(uniqueUrls[url.url] &&
					priority < statusPriority[uniqueUrls[url.url].status.toLowerCase()])
			) {
				uniqueUrls[url.url] = url;
			}
		});

		// Step 2: Convert the map back to an array and sort by status priority
		return Object.values(uniqueUrls).sort((a, b) => {
			const priorityA = statusPriority[a.status.toLowerCase()] || 2;
			const priorityB = statusPriority[b.status.toLowerCase()] || 2;

			return priorityA - priorityB;
		});
	};

	const countUrls = (urls: TUrlList[]): number => {
		return urls?.length;
	};

	const closeModal = () => {
		setModalDisplay(false);
	};

	useEffect(() => {
		setState(initialState);
	}, [initialState]);

	const handleSubmit = async (url: string[]) => {
		const response: TState = await onSubmit(url);
		setState(response);
	};

	const handleDoneClick = (projectID: number) => {
		onSuccessClose(projectID);
		setState("link-submit");
	};

	useEffect(() => {
		if (state === "success" && isImporting.id === project.id && isImporting.data) {
			toast.dismiss("import_success_toaster");
			toast.dismiss("import_error_toaster");

			setModalDisplay(true);
		}
	}, [state, isImporting, linkedInUrl, project?.id, setModalDisplay, showLastBulkImportReport]);

	const importingForCurrentProject = useMemo(() => {
		return (
			Object.entries(isImporting).length > 0 &&
			isImporting.data &&
			isImporting.id === project.id
		);
	}, [isImporting, project.id]);

	const importingForOtherProject = useMemo(() => {
		return (
			Object.entries(isImporting).length > 0 &&
			isImporting.data &&
			isImporting.id !== project.id
		);
	}, [isImporting, project.id]);

	const shouldRenderLastLinkedInBulkImportReport = useMemo(() => {
		const lastCompletedImport = lastCompletedCandidateImportByLinkedInForProject[project.id];

		// Check if the conditions to render last completed import reports are met
		return lastCompletedImport?.id && showLastBulkImportReport && !importingForCurrentProject;
	}, [
		lastCompletedCandidateImportByLinkedInForProject,
		showLastBulkImportReport,
		importingForCurrentProject,
		project.id,
	]);

	const importHistoryData = useMemo(() => {
		return sortUrls(
			lastCompletedCandidateImportByLinkedInForProject[project.id]?.importInfo
				.linkedin_candidate_urls,
		);
	}, [lastCompletedCandidateImportByLinkedInForProject, project.id]);

	// Helper function to determine the status message
	const getStatusMessage = (status: string): string => {
		const lowerStatus = status?.toLowerCase();
		if (!lowerStatus) return "";

		if (["success", "imported", "completed", "already exists"].includes(lowerStatus)) {
			return "Success";
		} else if (lowerStatus === "not found") {
			return "Bad Url";
		} else if (["import error", "scraping error"].includes(lowerStatus)) {
			return "Sent for manual import";
		}

		return status;
	};

	// Helper function to determine the reason message
	const getReasonMessage = (status: string): string => {
		const lowerStatus = getStatusMessage(status).toLowerCase();
		if (lowerStatus === "bad url") {
			return "Not a valid LinkedIn candidate URL";
		} else if (lowerStatus === "not found") {
			return "This profile was not found";
		} else if (lowerStatus === "sent for manual import") {
			return "This profile has been sent for manual import. If it is a valid URL, it will be visible on MapX within 12 hours";
		}

		return "";
	};

	const downloadReport = (linkedInUrls: TUrlList[] | undefined) => {
		if (!linkedInUrls) return;

		// Helper function to generate a sanitized filename
		const generateFileName = (projectName: string) => {
			const date = new Date().toLocaleDateString();

			return (
				projectName.replace(/[.]/g, "_").replace(/[\s,]/g, "_") +
				"_" +
				date.replace(/[\s,]/g, "_")
			);
		};

		// Process the data for CSV
		const csvData = linkedInUrls.map((item) => ({
			linkedin_url: item.url,
			status: getStatusMessage(item.status),
			reason: getReasonMessage(item.status),
		}));

		// Convert to CSV and trigger download
		const csv = Papa.unparse(csvData);
		const blob = new Blob([csv], {type: "text/csv;charset=utf-8;"});
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("href", url);
		a.setAttribute("download", generateFileName(project.name));
		a.click();
	};

	return (
		<Modal
			data-testid={linkedInModalTestId}
			style={customStyles}
			isOpen={modalIsOpen}
			contentLabel="Modal"
			onRequestClose={closeModal}
		>
			<div
				data-testid="searchByLinkedinComponent"
				className={classNames("modal", styles.wrapper)}
			>
				{shouldRenderLastLinkedInBulkImportReport ? (
					<LinkedInBulkImportReport
						totalURLs={countUrls(
							lastCompletedCandidateImportByLinkedInForProject[project.id].importInfo
								.linkedin_candidate_urls,
						)}
						linkedInUrl={importHistoryData}
						handleDownload={(urls) => downloadReport(urls)}
						inProgress={true}
						lastCompletedDisplay={true}
						otherProjectInProgress={importingForOtherProject}
						handleClick={() =>
							handleDoneClick(
								lastCompletedCandidateImportByLinkedInForProject[project.id]?.id,
							)
						}
						importedLinkedInUrls={importHistoryData}
					/>
				) : !isImporting.data ? (
					<Form
						handleSubmit={handleSubmit}
						handleDownload={(urls) => downloadReport(urls)}
						inProgress={isImporting.data || inProgress}
						linkedInUrl={linkedInUrl}
						importedLinkedInUrls={importHistoryData}
					/>
				) : (
					linkedInUrl && (
						<LinkedInBulkImportReport
							totalURLs={countUrls(linkedInUrl)}
							linkedInUrl={sortUrls(linkedInUrl)}
							handleDownload={(urls) => downloadReport(urls)}
							lastCompletedDisplay={false}
							inProgress={importingForCurrentProject}
							otherProjectInProgress={importingForOtherProject}
							handleClick={() => handleDoneClick(isImporting.id)}
							importedLinkedInUrls={importHistoryData}
						/>
					)
				)}
			</div>
		</Modal>
	);
};

export default SearchByLinkedInModal;
