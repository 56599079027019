import React from "react";
import styles from "../projectCandidateCard.module.scss";
import {CgMail} from "react-icons/cg";
import {BiPhone} from "react-icons/bi";
import {MdOutlineContactMail} from "react-icons/md";

const EmptyContactDetails = () => {
	return (
		<>
			<div className={styles.noInfoGroup} data-testid="empty-contact-details">
				<p>
					<BiPhone color={"#4E5555"} />
				</p>
				<p>XXX-XXXX-XXX</p>
			</div>

			<div className={styles.noInfoGroup} data-testid="seeMoreProfessionalMail">
				<p>
					<CgMail size="19px" />
				</p>
				<p>XXXX@XXXX.XXX</p>
			</div>

			<div className={styles.noInfoGroup} data-testid="seeMorePersonalMail">
				<p>
					<MdOutlineContactMail size="16px" />
				</p>
				<p>XXXX@XXXX.XXX</p>
			</div>
		</>
	);
};

export default EmptyContactDetails;
