import React, {useCallback} from "react";
import styles from "mapx-components/Cards/ProjectCandidateCard/projectCandidateCard.module.scss";
import {TProjectCandidateContactDetailInfoGroupProps} from "../types";
import {toast} from "react-toastify";
import {ToastContent} from "components";

const CandidateContactDetailsInfoGroup = ({
	icon: Icon,
	items,
	toggleView,
	showMore,
	testId,
	reference,
	customMargin,
	iconSize,
}: TProjectCandidateContactDetailInfoGroupProps) => {
	const copyToClipboard = useCallback(async (text: string) => {
		try {
			await navigator.clipboard.writeText(text);
			toast.success(ToastContent, {
				autoClose: 2000,
				closeOnClick: true,
				data: {
					title: `${text} copied to clipboard`,
				},
				delay: 100,
			});
		} catch (err) {
			console.error("Failed to copy:", err);
		}
	}, []);

	return (
		<section ref={reference}>
			{items?.length > 0 && (
				<div className={styles.infoGroup}>
					<p>
						<Icon color={"#4E5555"} size={iconSize} />
					</p>
					<p>
						<span
							onClick={() => copyToClipboard(items[0])} // Copy text to clipboard on click
							style={{cursor: "pointer"}}
						>
							{items[0]}
						</span>
						{items?.length > 1 && (
							<span
								onClick={toggleView}
								data-testid={testId}
								title={`Click to see ${items?.length - 1} more`}
								style={{cursor: "pointer"}}
							>
								{items?.length - 1}
							</span>
						)}
					</p>
				</div>
			)}

			{showMore && items && items?.length > 1 && (
				<div className={styles.dropdown} style={{left: customMargin}}>
					{items?.slice(1).map((item: string, index: number) => (
						<div key={index + item} className={styles.infoGroup}>
							<p>
								<Icon color={"#4E5555"} size={iconSize} />
							</p>
							<p>
								<span
									onClick={() => copyToClipboard(item)} // Copy text to clipboard on click
									style={{cursor: "pointer"}}
								>
									{item}
								</span>
							</p>
						</div>
					))}
				</div>
			)}
		</section>
	);
};

export default CandidateContactDetailsInfoGroup;
