import CompanyFilter from "containers/Filters/PeopleFilters/CompanyFilter";
import IndustryFilter from "containers/Filters/PeopleFilters/IndustryFilter";
import JobFunctionFilter from "containers/Filters/PeopleFilters/JobFunctionFilter";
import HeadcountFilter from "containers/Filters/PeopleFilters/HeadcountFilter";
import KeywordFilter from "containers/Filters/PeopleFilters/KeywordFilter";
import {useAppSelector} from "hooks";
import {useMemo} from "react";
import {userLicenseTypeNameSelector} from "store/mapx/user/userSelectors";
import DiversityFilter from "./DiversityFilter";
import LocationFilter from "./LocationFilter";
import css from "./peopleFilters.module.scss";
import SeniorityFilter from "./SeniorityFilter";
import CompanyTypeFilter from "containers/Filters/PeopleFilters/CompanyTypeFilter";
import RevenueFilter from "containers/Filters/PeopleFilters/RevenueFilter";
import DealExperienceFilter from "./DealExperienceFilter";

function PeopleFilters({onlyPeopleFilterActive = false}) {
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);

	const lockFilterForFreemium = useMemo(
		() => userLicenseTypeName === "Starter",
		[userLicenseTypeName],
	);

	return (
		<div className={css.container}>
			<div className={css.wrapper}>
				{/*<PersonFilter />*/}
				<CompanyFilter disabled={onlyPeopleFilterActive} />
				<SeniorityFilter disabled={onlyPeopleFilterActive} />
				<JobFunctionFilter disabled={onlyPeopleFilterActive} />
				<KeywordFilter
					disabled={onlyPeopleFilterActive || lockFilterForFreemium}
					displayLock={lockFilterForFreemium}
				/>
				<LocationFilter
					disabled={onlyPeopleFilterActive || lockFilterForFreemium}
					displayLock={lockFilterForFreemium}
				/>
				<DiversityFilter
					disabled={onlyPeopleFilterActive || lockFilterForFreemium}
					displayLock={lockFilterForFreemium}
				/>
				<IndustryFilter disabled={onlyPeopleFilterActive} />
				<HeadcountFilter disabled={onlyPeopleFilterActive} />
				<RevenueFilter disabled={onlyPeopleFilterActive} />
				<CompanyTypeFilter
					disabled={onlyPeopleFilterActive}
					displayLock={lockFilterForFreemium}
				/>
				<DealExperienceFilter />
			</div>
		</div>
	);
}

export default PeopleFilters;
