import {useState} from "react";
import type {CancelTokenSource} from "axios";
import axios from "axios";
import mapXCandidateApi from "api/candidateApi";
import {ICandidateSearchApiRequestPayload} from "api/candidateApi/form";
import {successResponse} from "helpers/map";
import {debounce} from "lodash";

let cancelToken: CancelTokenSource;

const useCandidateSearchService = () => {
	const [loadingCandidates, setLoadingCandidates] = useState<boolean>(false);

	const fetchCandidates = debounce(
		async (inputString: string, callback: (options: Record<string, string>[]) => void) => {
			setLoadingCandidates(true);
			//Check if there are any previous pending requests
			if (typeof cancelToken !== typeof undefined) {
				cancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			cancelToken = axios.CancelToken.source();
			const config = {cancelToken: cancelToken.token};

			const payload: ICandidateSearchApiRequestPayload = {
				filters: {
					full_name: inputString,
				},
				pagination: {
					page: 1,
					per_page: 100,
				},
			};

			const response = await mapXCandidateApi.getCandidatesByName(payload, config);

			if (response === undefined) {
				console.log("req cancelled");
				setLoadingCandidates(true);
			}

			if (successResponse(response, 200)) {
				setLoadingCandidates(false);

				const data: Record<string, string>[] = response.data.results;

				return callback(data);
			} else {
				setLoadingCandidates(false);

				return callback([]);
			}
		},
		800,
	);

	return {
		loadingCandidates,
		fetchCandidates,
	};
};

export default useCandidateSearchService;
