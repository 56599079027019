import {FloatAddedIcon, MagicIcon} from "assets/icons";
import {Checkbox, CheckboxSkeletonLoader, Tag} from "components";
import useFetchListPaginateOptions from "hooks/useFetchListPaginateOptions";
import {SearchInput} from "mapx-components/index";
import React, {useCallback, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {suggestedJobTitlesSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {setJobTitleSearchQuery} from "store/mapx/search/searchActions";
import {getJobTitles} from "store/mapx/search/searchAsyncActions";
import {
	jobTitlePaginationSelector,
	jobTitleSearchQuerySelector,
	mappedJobTitleOptionsSelector,
} from "store/mapx/search/searchSelectors";
import css from "./jobTitles.module.scss";

function JobTitlesSearchFilter({handleOnChange, selectedJobTitles = [], isDisabled = false}) {
	const jobTitleOptions = useSelector(mappedJobTitleOptionsSelector);

	const suggestedJobTitleOptions = useSelector(suggestedJobTitlesSelector);

	const searchQuery = useSelector(jobTitleSearchQuerySelector);

	const pagination = useSelector(jobTitlePaginationSelector);

	const dispatch = useDispatch();

	const {data, onFilterChanged, page, setPage, searchTerm, listInnerRef, loading} =
		useFetchListPaginateOptions({
			options: jobTitleOptions,
			apiCall: getJobTitles,
			initialSearchQuery: searchQuery,
			setQueryCallbackOnState: setJobTitleSearchQuery,
		});

	const onScroll = useCallback(() => {
		try {
			if (searchTerm === "") return; // when user search only then we allow pagination on job titles

			if (!!setPage && !loading && pagination?.pages > page) {
				if (listInnerRef.current) {
					const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;

					if (scrollTop + clientHeight === scrollHeight) {
						setPage(page + 1);
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [searchTerm, setPage, loading, pagination?.pages, page, listInnerRef]);

	useEffect(() => {
		if (searchTerm === "") {
			setPage(1);
		}
	}, [dispatch, searchTerm, setPage]);

	const filteredData = useMemo(() => {
		if (searchTerm === "" && suggestedJobTitleOptions?.length > 0) {
			return suggestedJobTitleOptions;
		}

		if (page === 1 && loading) return [];

		return [...data].map((d) => d.name).filter((item) => !selectedJobTitles.includes(item));
	}, [data, loading, page, searchTerm, selectedJobTitles, suggestedJobTitleOptions]);

	const hideAddJobTitleIcon = useMemo(() => {
		if (!searchQuery || loading) return true;

		if (searchQuery !== "" && selectedJobTitles?.length > 0) {
			for (const item of selectedJobTitles) {
				if (item.toLowerCase() === searchQuery.toLowerCase()) {
					return true;
				}
			}
		}

		return false;
	}, [loading, searchQuery, selectedJobTitles]);

	const handleAddJobTitleIcon = async () => {
		await handleOnChange(searchQuery);

		dispatch(setJobTitleSearchQuery(""));

		const inputElement = document.querySelector('input[placeholder="Search for a Job title"]');

		if (inputElement) {
			inputElement.value = "";
		}
	};

	return (
		<div>
			<div className={css.searchInputWrapper}>
				{!hideAddJobTitleIcon && (
					<FloatAddedIcon
						onClick={handleAddJobTitleIcon}
						className={css.floatAddedIcon}
					/>
				)}

				<SearchInput
					defaultValue={searchQuery}
					onChange={onFilterChanged}
					placeholder="Search for a Job title"
					type="text"
					isLoading={loading}
				/>
			</div>

			<div className={css.selectedJobTitles}>
				{selectedJobTitles?.map((jobTitle) => (
					<Tag
						closable={true}
						key={jobTitle}
						onClick={() => handleOnChange(jobTitle)}
						disabled={isDisabled}
					>
						{jobTitle}
					</Tag>
				))}
			</div>

			{/*<ResetFilters onClick={handleResetClick}/>*/}

			{((searchTerm === "" && suggestedJobTitleOptions?.length > 0) || searchTerm !== "") && (
				<div>
					<div className={css.title}>
						{searchTerm !== "" ? (
							(!loading || page > 1) && "Search Result of Job Titles"
						) : (
							<>
								<MagicIcon /> AI Suggested Job Titles
							</>
						)}
					</div>

					{!loading && (
						<div className={css.checkboxList} ref={listInnerRef} onScroll={onScroll}>
							{filteredData?.map((jobTitle) => (
								<Checkbox
									borderColor="#0C5850"
									containerClass={css.checkboxContainer}
									isChecked={selectedJobTitles.includes(jobTitle)}
									key={jobTitle}
									label={jobTitle.toString()}
									onChange={() => handleOnChange(jobTitle)}
									value={jobTitle.toString()}
									disabled={isDisabled || selectedJobTitles?.length >= 10}
								/>
							))}
						</div>
					)}

					{loading && <CheckboxSkeletonLoader />}
				</div>
			)}
		</div>
	);
}

export default JobTitlesSearchFilter;
