import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {removeDuplicateObjectFromArray} from "helpers/filterHandlers";

const useFetchListOptions = ({
	options,
	callbackMethod,
	filterKey = "name",
	childFilterKey = null,
	nestedChildFilterKey = null,
}) => {
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetch = useCallback(async () => {
		if (!options?.length && callbackMethod !== undefined) {
			setLoading(true);
			await dispatch(callbackMethod());
			setLoading(false);
		}
	}, [dispatch, options?.length, callbackMethod]);

	const nestedChildrenFilterKey = useMemo(
		() => (nestedChildFilterKey ? nestedChildFilterKey : childFilterKey),
		[childFilterKey, nestedChildFilterKey],
	);

	useEffect(() => {
		if (Array.isArray(options)) {
			setData([...options]);
		}
	}, [options]);

	useEffect(() => {
		setFilteredData(removeDuplicateObjectFromArray([...data]));
	}, [data]);

	useEffect(() => {
		fetch().finally();
	}, [fetch]);

	/**
	 *
	 * @param e => search string
	 * Loop through parent item to search through
	 * Also loop through child item to search through
	 */

	const onFilterChanged = (e) => {
		const searchString = e.target.value.toLowerCase();
		const currentData = structuredClone(data);

		// here we browse through parent to 2nd level child to see if there is a match
		// if there is a match we include the parent item for next level filtering with all the children data
		let searchFilteredData = currentData.filter((item) => {
			const parentFound = item[filterKey].toLowerCase().indexOf(searchString) >= 0;

			if (parentFound) return true;

			let childFound = false;

			if (childFilterKey) {
				for (const subItem of item[childFilterKey]) {
					childFound = subItem[filterKey]?.toLowerCase().indexOf(searchString) >= 0;

					if (childFound) {
						return true;
					}

					if (
						!childFound &&
						subItem[nestedChildrenFilterKey] !== undefined &&
						subItem[nestedChildrenFilterKey]?.length > 0
					) {
						for (const nestedSubItem of subItem[nestedChildrenFilterKey]) {
							childFound =
								nestedSubItem[filterKey]?.toLowerCase().indexOf(searchString) >= 0;

							if (childFound) {
								return true;
							}
						}
					}
				}
			}

			return false;
		});

		if (childFilterKey) {
			searchFilteredData = [...searchFilteredData].map((item) => {
				const parentFound = item[filterKey].toLowerCase().indexOf(searchString) >= 0;

				// if parent name has match, then should just display all the child
				if (!parentFound) {
					const hasChild =
						item[childFilterKey] !== undefined && item[childFilterKey]?.length > 0;

					if (hasChild) {
						const childrenData = structuredClone(item[childFilterKey]);

						let filteredChildrenData = [];

						// here we start 1st level child searching
						// if we find the 1st level child matched, then we include all the child
						for (const nestedChildItem of childrenData) {
							const found =
								nestedChildItem[filterKey]?.toLowerCase().indexOf(searchString) >=
								0;

							if (found) {
								filteredChildrenData.push(nestedChildItem);
							} else {
								const hasNestedChild =
									nestedChildItem[nestedChildrenFilterKey] !== undefined &&
									nestedChildItem[nestedChildrenFilterKey]?.length > 0;

								if (hasNestedChild) {
									const moreNestedChildrenData = structuredClone(
										nestedChildItem[nestedChildrenFilterKey],
									);

									if (moreNestedChildrenData?.length > 0) {
										let filteredNestedChildrenData = [];

										// here we start 2nd level child searching
										// if we find the 2nd level child matched,
										// then we only include the current child
										for (const moreNestedChildItem of moreNestedChildrenData) {
											const foundInNested =
												moreNestedChildItem[filterKey]
													?.toLowerCase()
													.indexOf(searchString) >= 0;

											if (foundInNested) {
												filteredNestedChildrenData.push(
													moreNestedChildItem,
												);
											}
										}

										if (filteredNestedChildrenData?.length > 0) {
											nestedChildItem[nestedChildrenFilterKey] =
												filteredNestedChildrenData;
											filteredChildrenData.push(nestedChildItem);
										}
									}
								}
							}
						}

						if (filteredChildrenData?.length > 0) {
							item[childFilterKey] = filteredChildrenData;
						} else {
							item[childFilterKey] = [];
						}
					} else {
						item[childFilterKey] = [];
					}
				}

				return item;
			});
		}

		setFilteredData([...searchFilteredData]);
		setSearchTerm(searchString);

		return [...searchFilteredData];
	};

	return {data, filteredData, searchTerm, onFilterChanged, setFilteredData, loading};
};

export default useFetchListOptions;
