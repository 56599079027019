import React, {type FC, useCallback} from "react";

import {Button, Loader} from "components";

import SearchByLinkedInModal from "../Modals/SearchByLinkedInModal";

import type {TAddPeopleByLinkedInProps} from "./types";
import styles from "./styles.module.scss";
import {BiRefresh} from "react-icons/bi";
import classNames from "classnames";
import {useAppSelector} from "hooks";
import {activeImportPercentageForProject} from "store/mapx/linkedin-bulk-import/linkedinBulkImportSelectors";
import {isLastBulkImportReportDataLoadingSelector} from "../../store/mapx/background-tasks/backgroundTaskSelectors";

const AddPeopleByLinkedIn: FC<TAddPeopleByLinkedInProps> = ({
	containerClass,
	inProgress,
	onSubmit,
	onSuccessClose,
	linkedInUrl,
	initialState,
	toggleModalDisplay,
	modalDisplay,
	setModalDisplay,
}) => {
	const handleClick = useCallback(() => {
		setModalDisplay((prev) => {
			toggleModalDisplay(!prev);

			return !prev;
		});
	}, [toggleModalDisplay, setModalDisplay]);

	const importPercentage = useAppSelector(activeImportPercentageForProject);

	const isLastBulkImportReportDataLoading = useAppSelector(
		isLastBulkImportReportDataLoadingSelector,
	);

	return (
		<div className={containerClass} data-testid="add-people-by-linkedin">
			<Button
				name={"Add Profile"}
				type={"button"}
				className={styles.addCandidateButton}
				onClick={handleClick}
				disabled={isLastBulkImportReportDataLoading}
			>
				{inProgress && !isLastBulkImportReportDataLoading ? (
					<BiRefresh size={22} className={classNames(styles.refresh)} />
				) : (
					!isLastBulkImportReportDataLoading && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
						>
							<mask
								id="mask0_4434_26661"
								style={{maskType: "alpha"}}
								maskUnits="userSpaceOnUse"
								x="0"
								y="0"
								width="20"
								height="20"
							>
								<rect width="20" height="20" fill="#D9D9D9" />
							</mask>
							<g mask="url(#mask0_4434_26661)">
								<path
									d="M14.75 11V9.25H13V7.75H14.75V6H16.25V7.75H18V9.25H16.25V11H14.75ZM8 10C7.16667 10 6.45833 9.70833 5.875 9.125C5.29167 8.54167 5 7.83333 5 7C5 6.16667 5.29167 5.45833 5.875 4.875C6.45833 4.29167 7.16667 4 8 4C8.83333 4 9.54167 4.29167 10.125 4.875C10.7083 5.45833 11 6.16667 11 7C11 7.83333 10.7083 8.54167 10.125 9.125C9.54167 9.70833 8.83333 10 8 10ZM2 16V14.0833C2 13.7253 2.08681 13.3962 2.26042 13.096C2.43403 12.7959 2.67361 12.5556 2.97917 12.375C3.74306 11.9306 4.54861 11.5903 5.39583 11.3542C6.24306 11.1181 7.11111 11 8 11C8.88889 11 9.75694 11.1181 10.6042 11.3542C11.4514 11.5903 12.2569 11.9306 13.0208 12.375C13.3264 12.5556 13.566 12.7959 13.7396 13.096C13.9132 13.3962 14 13.7253 14 14.0833V16H2ZM3.5 14.5H12.5V14.0833C12.5 13.9935 12.479 13.9118 12.437 13.8382C12.395 13.7647 12.3396 13.7075 12.2708 13.6667C11.6181 13.2917 10.9306 13.0035 10.2083 12.8021C9.48611 12.6007 8.75 12.5 8 12.5C7.25 12.5 6.51389 12.6007 5.79167 12.8021C5.06944 13.0035 4.38194 13.2917 3.72917 13.6667C3.66042 13.7075 3.60503 13.7647 3.56302 13.8382C3.52101 13.9118 3.5 13.9935 3.5 14.0833V14.5ZM8.00442 8.5C8.41814 8.5 8.77083 8.35269 9.0625 8.05808C9.35417 7.76346 9.5 7.40929 9.5 6.99558C9.5 6.58186 9.35269 6.22917 9.05808 5.9375C8.76346 5.64583 8.40929 5.5 7.99558 5.5C7.58186 5.5 7.22917 5.64731 6.9375 5.94192C6.64583 6.23654 6.5 6.59071 6.5 7.00442C6.5 7.41814 6.64731 7.77083 6.94192 8.0625C7.23654 8.35417 7.59071 8.5 8.00442 8.5Z"
									fill="#0C5850"
								/>
							</g>
						</svg>
					)
				)}
				{isLastBulkImportReportDataLoading ? (
					<Loader />
				) : inProgress && importPercentage < 100 ? (
					"Importing"
				) : (
					"Add Profile"
				)}
			</Button>

			<SearchByLinkedInModal
				linkedInModalTestId="linkedin-modal"
				setModalDisplay={setModalDisplay}
				modalDisplay={modalDisplay}
				inProgress={inProgress}
				onSubmit={onSubmit}
				onSuccessClose={onSuccessClose}
				linkedInUrl={linkedInUrl}
				initialState={initialState}
			/>
		</div>
	);
};

export default AddPeopleByLinkedIn;
