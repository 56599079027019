import {createSelector} from "reselect";
import {TRootState} from "types";

export const importLinkedInCandidateInProgressSelector = (state: TRootState) =>
	state.linkedinImport.importLinkedInCandidateInProgress;

export const importLinkedInProfilesInfoSelector = (state: TRootState) =>
	state.linkedinImport.importLinkedInProfileInfo;

export const showLastBulkImportReportSelector = (state: TRootState) =>
	state.linkedinImport.showLastBulkImportReport;

export const isCurrentImportFinishedSelector = (state: TRootState) =>
	state.linkedinImport.isCurrentImportFinished;

export const searchCandidatesForProjectSelector = (state: TRootState) =>
	state.linkedinImport.searchCandidateForProject;

export const candidateImportsByLinkedInForProjectSelector = (state: TRootState) =>
	state.linkedinImport.candidateImportByLinkedInForProject;

export const isModalDisplayedForProject = (state: TRootState) =>
	state.linkedinImport.activeAddPeopleModalForProject;

export const activeImportPercentageForProject = (state: TRootState) =>
	state.linkedinImport.activeImportPercentageForProject;

export const activeImportsForProject = (state: TRootState) =>
	state.linkedinImport.activeImportsForProject;

export const candidatesListSelector = (state: TRootState) =>
	state.linkedinImport.importLinkedInCandidatesInProgress;

export const projectIdSelector = (_: unknown, projectId: number) => projectId;

export const isLinkedInProfilesImportIsInProgress = createSelector(
	[importLinkedInProfilesInfoSelector],
	(importLinkedInProfileInfo) =>
		importLinkedInProfileInfo !== null && importLinkedInProfileInfo?.status !== undefined,
);

export const getCandidateSearchInProgressByProjectId = createSelector(
	[searchCandidatesForProjectSelector, projectIdSelector],
	(searchCandidateForProject, projectId) => {
		return searchCandidateForProject &&
			projectId &&
			searchCandidateForProject[projectId] !== undefined
			? searchCandidateForProject[projectId]
			: false;
	},
);

export const getCandidateImportInfoByProjectId = createSelector(
	[candidateImportsByLinkedInForProjectSelector, projectIdSelector],
	(candidateImportByLinkedInForProject, projectId) => {
		return candidateImportByLinkedInForProject && projectId
			? Object.values(candidateImportByLinkedInForProject)
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					.filter((value) => Number(value.id) === projectId)[0]
			: null;
	},
);
