import {useSelector} from "react-redux";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {targetListIDSelector} from "store/mapx/target-list/targetListSelectors";
import {checkCandidateContactDetailsGeneratedStatus} from "store/mapx/project/generateCandidateContactDetailsAsyncActions";
import {getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector} from "store/mapx/project/generateCandidateContactDetailsSelectors";

/* eslint-disable  @typescript-eslint/no-explicit-any */
let candidateIdsIntervalId = {} as any;
const useCandidateContactDetailsInstantDisplay = () => {
	const dispatch = useAppDispatch();

	const targetListID = useAppSelector(targetListIDSelector);

	const allCandidateIdsInProgress = useSelector((state) =>
		getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector(state, targetListID),
	);

	/**
	 * Here on the state we have kept the information for all candidate contact details generate request by project
	 * Then We watch the candidate ids for the project and send a request to check if the contact details is generated
	 * if generated then we remove the specific id from the watch list and update the info on the candidate list
	 */

	useEffect(() => {
		if (
			allCandidateIdsInProgress &&
			Array.isArray(allCandidateIdsInProgress) &&
			allCandidateIdsInProgress?.length > 0
		) {
			candidateIdsIntervalId = setInterval(async () => {
				await dispatch(
					checkCandidateContactDetailsGeneratedStatus(allCandidateIdsInProgress),
				);
			}, 20 * 1000);

			setTimeout(() => {
				clearInterval(candidateIdsIntervalId);
			}, 60 * 30 * 1000); // it will clear after 30 min
		}

		if (allCandidateIdsInProgress?.length === 0) {
			clearInterval(candidateIdsIntervalId);
		}

		return () => {
			if (candidateIdsIntervalId) {
				clearInterval(candidateIdsIntervalId);
			}
		};
	}, [allCandidateIdsInProgress, dispatch]);
};

export default useCandidateContactDetailsInstantDisplay;
