import {
	BACKGROUND_BULK_IMPORTS_IN_PROGRESS,
	SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT,
} from "../mapXActionTypes";
import {TSetLastCompletedImportInfoPayload} from "./types";

export function setBackgroundBulkImportsInProgress(payload: boolean) {
	return {type: BACKGROUND_BULK_IMPORTS_IN_PROGRESS, payload};
}

export function setLastCompleteImportLinkedInProfileInfoForProject(
	payload: TSetLastCompletedImportInfoPayload,
) {
	return {type: SET_LAST__COMPLETE_IMPORT_LINKEDIN_PROFILE_INFO_FOR_PROJECT, payload};
}
