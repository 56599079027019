import {useState} from "react";
import {debounce} from "lodash";
import axios, {type CancelTokenSource} from "axios";
import {successResponse} from "helpers/map";
import mapXOrganisationApi from "api/organisationApi";
import {STUser} from "api/organisationApi/types";
import {useAppSelector} from "hooks";
import {userSelector} from "store/mapx/user/userSelectors";

let cancelToken: CancelTokenSource;

const useUserSearchService = () => {
	const user = useAppSelector(userSelector);

	const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

	const fetchUsers = debounce(
		async (inputString: string, callback: (options: Record<string, string>[]) => void) => {
			setLoadingUsers(true);
			//Check if there are any previous pending requests
			if (typeof cancelToken !== typeof undefined) {
				cancelToken.cancel("Operation canceled due to new request.");
			}

			//Save the cancel token for the current request
			cancelToken = axios.CancelToken.source();

			const config = {cancelToken: cancelToken.token};

			const response = await mapXOrganisationApi.searchUsers(inputString, config);

			if (response === undefined) {
				setLoadingUsers(true);
			}

			if (successResponse(response, 200)) {
				setLoadingUsers(false);

				const data: Record<string, string>[] = response.data.results
					.filter((u: STUser) => u.user_id !== user.user_id)
					.map((user: STUser) => ({
						...user,
						id: user.user_id,
						name: `${user.first_name} ${user.last_name}`,
					}));

				return callback(data);
			} else {
				setLoadingUsers(false);

				return callback([]);
			}
		},
		800,
	);

	return {
		loadingUsers,
		fetchUsers,
	};
};

export default useUserSearchService;
