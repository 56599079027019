import type {FC} from "react";

import type {IconProps} from "./types";

const HelpIcon: FC<IconProps> = ({fill = "#404040", className = "", dataTooltipId}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		data-tooltip-id={dataTooltipId}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_53_2878"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_53_2878)">
			<path
				fill={fill}
				d="M10 15C10.278 15 10.514 14.9027 10.708 14.708C10.9027 14.514 11 14.278 11 14C11 13.722 10.9027 13.486 10.708 13.292C10.514 13.0973 10.278 13 10 13C9.722 13 9.486 13.0973 9.292 13.292C9.09733 13.486 9 13.722 9 14C9 14.278 9.09733 14.514 9.292 14.708C9.486 14.9027 9.722 15 10 15ZM9.25 11.812H10.771C10.771 11.2987 10.816 10.9343 10.906 10.719C10.9967 10.5037 11.2153 10.2293 11.562 9.896C12.0487 9.424 12.3857 9.02133 12.573 8.688C12.7603 8.35467 12.854 7.98633 12.854 7.583C12.854 6.81967 12.5937 6.19833 12.073 5.719C11.5523 5.23967 10.889 5 10.083 5C9.375 5 8.76067 5.18733 8.24 5.562C7.71867 5.93733 7.354 6.44433 7.146 7.083L8.5 7.646C8.62533 7.25667 8.82333 6.95467 9.094 6.74C9.36467 6.52467 9.68067 6.417 10.042 6.417C10.4307 6.417 10.75 6.528 11 6.75C11.25 6.972 11.375 7.26367 11.375 7.625C11.375 7.94433 11.2673 8.229 11.052 8.479C10.8367 8.729 10.597 8.972 10.333 9.208C9.847 9.65267 9.545 10.0173 9.427 10.302C9.309 10.5867 9.25 11.09 9.25 11.812ZM10 18C8.90267 18 7.868 17.7917 6.896 17.375C5.924 16.9583 5.07333 16.3853 4.344 15.656C3.61467 14.9267 3.04167 14.076 2.625 13.104C2.20833 12.132 2 11.0973 2 10C2 8.88867 2.20833 7.85033 2.625 6.885C3.04167 5.92033 3.61467 5.07333 4.344 4.344C5.07333 3.61467 5.924 3.04167 6.896 2.625C7.868 2.20833 8.90267 2 10 2C11.1113 2 12.1497 2.20833 13.115 2.625C14.0797 3.04167 14.9267 3.61467 15.656 4.344C16.3853 5.07333 16.9583 5.92033 17.375 6.885C17.7917 7.85033 18 8.88867 18 10C18 11.0973 17.7917 12.132 17.375 13.104C16.9583 14.076 16.3853 14.9267 15.656 15.656C14.9267 16.3853 14.0797 16.9583 13.115 17.375C12.1497 17.7917 11.1113 18 10 18ZM10 16.5C11.8053 16.5 13.34 15.868 14.604 14.604C15.868 13.34 16.5 11.8053 16.5 10C16.5 8.19467 15.868 6.66 14.604 5.396C13.34 4.132 11.8053 3.5 10 3.5C8.19467 3.5 6.66 4.132 5.396 5.396C4.132 6.66 3.5 8.19467 3.5 10C3.5 11.8053 4.132 13.34 5.396 14.604C6.66 15.868 8.19467 16.5 10 16.5Z"
			/>
		</g>
	</svg>
);

export default HelpIcon;
