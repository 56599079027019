import {FC, useRef} from "react";
import classNames from "classnames";

import {useAppSelector} from "hooks";
import {LogoOutlineMedium, Reset} from "assets/icons";
import {Button, MultiSelect, ProgressBar} from "components";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

import type {TFiltersRowComponentProps} from "./types";
import styles from "./FiltersRowComponent.module.scss";

const FiltersRowComponent: FC<TFiltersRowComponentProps> = ({
	loading,
	handleMap,
	resetFilters,
	onSelectChange,
	selectedFilters,
	multiSelectsData,
	keywordPagination,
	progressPercentage,
	isMappingInProgress,
	hasSelectedFilterOption,
	companyOrgChartFiltering,
	searchInputValue,
	setSearchInputValue,
	handleLoadMore,
}) => {
	const project = useAppSelector(projectSelector);

	const filterRef = useRef<Nullable<HTMLDivElement>>(null);

	const renderMultiSelects = multiSelectsData.map(
		({
			id,
			name,
			data,
			allSelectedValues,
			type,
			title,
			values,
			subName,
			subValues,
			placeholder,
			itemsOnLeft,
			nextSubValues,
			hideRightIcon,
			selectSearchable,
			nextSubName,
		}) => (
			<MultiSelect
				key={id}
				name={name}
				data={data}
				allSelectedValues={allSelectedValues}
				type={type}
				title={title}
				values={values}
				loading={loading}
				subName={subName}
				subValues={subValues}
				nextSubName={nextSubName}
				itemsOnLeft={itemsOnLeft}
				placeholder={placeholder}
				nextSubValues={nextSubValues}
				hideRightIcon={hideRightIcon}
				onSelectChange={onSelectChange}
				selectedFilters={selectedFilters}
				selectSearchable={selectSearchable}
				keywordPagination={keywordPagination}
				searchInputValue={searchInputValue}
				setSearchInputValue={setSearchInputValue}
				handleLoadMore={handleLoadMore}
			/>
		),
	);

	const handleResetClick = () => {
		resetFilters();

		const orgChartDiv = document.querySelector("#org-chart");

		if (orgChartDiv) {
			orgChartDiv.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	return (
		<div
			className={classNames(styles.container, {
				[styles.container_name]: project?.name,
			})}
			ref={filterRef}
		>
			<div className={styles.container__filters}>{renderMultiSelects}</div>

			<div className={styles.container__actions}>
				<Button
					defaultBtnStyle
					onClick={handleResetClick}
					data-testid="reset-button"
					disabled={!hasSelectedFilterOption}
					className={classNames(styles.container__actions__reset, {
						[styles.container__actions__reset_resettable]: hasSelectedFilterOption,
					})}
				>
					<Reset
						className={classNames({
							[styles.container__actions__reset_disabled]: hasSelectedFilterOption,
						})}
					/>{" "}
					<span>Reset</span>
				</Button>

				<Button
					onClick={handleMap}
					data-testid="search-button"
					disabled={companyOrgChartFiltering}
					className={classNames(styles.container__actions__map, {
						[styles.container__actions__map_loading]: isMappingInProgress,
						[styles.container__actions__map__disabled]: companyOrgChartFiltering,
					})}
				>
					<LogoOutlineMedium />

					<span>{isMappingInProgress ? "Searching..." : "AI Company Search"}</span>
				</Button>
			</div>

			{isMappingInProgress && (
				<ProgressBar
					mini
					value={progressPercentage}
					className={styles.progress}
					miniClass={styles.progress__mini}
					background={`#0C5850`}
				/>
			)}
		</div>
	);
};

export default FiltersRowComponent;
