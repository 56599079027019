import {type FC, useCallback, useMemo} from "react";

import {
	useAppDispatch,
	useAppSelector,
	useCompanyIdParam,
	useOrgChartFilterOptions,
	useOrgChartFilters,
	useSearchDebounce,
} from "hooks";
import {
	candidateMappingForCurrentCompanySelector,
	companyOrgChartFilteringSelector,
	companyOrgChartSelector,
	orgChartKeywordsFiltersDropdownDataSelector,
	getCandidateMappingInProgressForCurrentCompany,
} from "store/mapx/company/companySelectors";
import {FiltersRowComponent} from "mapx-components";
import type {ISelectDataTypes, TValue} from "components/MultiSelect/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {createMapFunctionByCompanyId} from "store/mapx/company/companyAsyncActions";
import {
	keywordOptionsInProgressSelector,
	keywordPaginationSelector,
} from "store/mapx/search/searchSelectors";

import {TSeniority} from "api/candidateApi/types";
import type {SIOrgChartProps} from "api/companyApi/types";
import {getKeywordsList} from "store/mapx/search/searchAsyncActions";

const FiltersRow: FC = () => {
	const dispatch = useAppDispatch();

	const {seniorityOptions, functionSpecialismOptions, countryOptions, keywordOptions} =
		useOrgChartFilterOptions({});

	const {selectedFilters, onSelectChange, resetFilters} = useOrgChartFilters();

	const companyParamId = useCompanyIdParam();

	const project = useAppSelector(projectSelector);

	const isMappingInProgress = useAppSelector((state) => {
		return getCandidateMappingInProgressForCurrentCompany(state, companyParamId);
	});

	const mapInfoForCurrentCompany = useAppSelector((state) => {
		return candidateMappingForCurrentCompanySelector(state, companyParamId);
	});

	const loading = useAppSelector(keywordOptionsInProgressSelector);

	const keywordPagination = useAppSelector(keywordPaginationSelector);

	const currentKeywordFiltersDropdownData = useAppSelector(
		orgChartKeywordsFiltersDropdownDataSelector,
	);

	const companyOrgChartFiltering = useAppSelector(companyOrgChartFilteringSelector);

	const companyOrgChart: Nullable<SIOrgChartProps[]> = useAppSelector(companyOrgChartSelector);

	const [searchInputValue, setSearchInputValue] = useSearchDebounce(800);

	const progressPercentage = useMemo(() => {
		if (mapInfoForCurrentCompany && mapInfoForCurrentCompany.progress) {
			return mapInfoForCurrentCompany.progress || 2;
		} else {
			return 2;
		}
	}, [mapInfoForCurrentCompany]);

	const hasSelectedFilterOption = useMemo(
		() =>
			Object.values(selectedFilters).some((filter) => !!filter?.length) &&
			!companyOrgChartFiltering,
		[companyOrgChartFiltering, selectedFilters],
	);

	const hasOneOfOrgChartSeniorityExpandedToMax = useMemo(
		() =>
			companyOrgChart instanceof Array &&
			companyOrgChart.some(
				({candidates}: SIOrgChartProps) =>
					candidates instanceof Array && candidates.length > 40,
			),
		[companyOrgChart],
	);

	const handleLoadMore = useCallback(
		(isSearching?: boolean) => {
			dispatch(
				getKeywordsList(
					searchInputValue,
					isSearching ? 1 : keywordPagination?.page + 1 || 1,
				),
			);
		},
		[keywordPagination?.page, searchInputValue, dispatch],
	);

	const multiSelectsData: ISelectDataTypes[] = [
		{
			id: 1,
			title: "Seniority",
			data: seniorityOptions,
			name: "current_seniority",
			type: "small_left_radius",
			values: selectedFilters.current_seniority as TSeniority[],
		},
		{
			id: 2,
			type: "large",
			hideRightIcon: true,
			name: "current_job_functions",
			title: "Function & Specialism",
			subName: "current_specialisms",
			data: functionSpecialismOptions,
			values: selectedFilters.current_job_functions,
			subValues: selectedFilters.current_specialisms,
		},
		{
			id: 3,
			type: "large",
			title: "Keywords",
			data: keywordOptions,
			allSelectedValues: currentKeywordFiltersDropdownData,
			selectSearchable: true,
			name: "current_keywords",
			placeholder: "Search for Keywords",
			values: selectedFilters.current_keywords as TValue[],
		},
		{
			id: 4,
			type: "small",
			name: "countries",
			itemsOnLeft: true,
			subName: "countries",
			title: "Location",
			hideRightIcon: true,
			data: countryOptions,
			nextSubName: "regions",
			values: selectedFilters.countries as TValue[],
			subValues: selectedFilters.countries,
			nextSubValues: selectedFilters.regions,
		},
	];

	const handleMap = async () => {
		if (!isMappingInProgress && companyParamId) {
			const filters = {
				company_id: companyParamId,
				country_ids: selectedFilters.countries,
				keyword_ids: selectedFilters.current_keywords,
				seniority: selectedFilters.current_seniority,
				specialism_ids: selectedFilters.current_specialisms,
			};

			await dispatch(createMapFunctionByCompanyId(filters));
		}
	};

	return (
		<FiltersRowComponent
			loading={loading}
			handleMap={handleMap}
			projectName={project?.name}
			resetFilters={resetFilters}
			onSelectChange={onSelectChange}
			selectedFilters={selectedFilters}
			multiSelectsData={multiSelectsData}
			keywordPagination={keywordPagination}
			progressPercentage={progressPercentage}
			isMappingInProgress={isMappingInProgress}
			hasSelectedFilterOption={
				hasSelectedFilterOption || hasOneOfOrgChartSeniorityExpandedToMax
			}
			companyOrgChartFiltering={companyOrgChartFiltering}
			setSearchInputValue={setSearchInputValue}
			searchInputValue={searchInputValue}
			handleLoadMore={handleLoadMore}
		/>
	);
};

export default FiltersRow;
