import classNames from "classnames";
import {noop} from "lodash";

import css from "./specialityButton.module.scss";

function InfoButton({
	name,
	active = false,
	handleClick,
	customStyle = null,
	secondText,
	disabled,
	customClass = "",
}) {
	return (
		<button
			className={classNames(css.specialityButton, {
				[css.specialitySelected]: active,
				[customClass]: customClass,
				[css.disabled]: disabled,
			})}
			style={customStyle ? customStyle : {}}
			onClick={disabled ? noop : handleClick}
		>
			{name}

			{secondText && (
				<span className={classNames(css.secondText, {[css.activeSecond]: active})}>
					{secondText}
				</span>
			)}
		</button>
	);
}

export default InfoButton;
