import type {FC} from "react";

import type {IconProps} from "./types";

const MultiSelectUserIcon: FC<IconProps> = ({className = ""}) => (
	<svg
		width="20"
		height="20"
		fill="none"
		viewBox="0 0 20 20"
		className={className}
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			x="0"
			y="0"
			width="20"
			height="20"
			id="mask0_1386_25201"
			maskUnits="userSpaceOnUse"
			style={{maskType: "alpha"}}
		>
			<rect width="20" height="20" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1386_25201)">
			<path
				fill="#808080"
				d="M2 16V14.0833C2 13.7222 2.08681 13.3924 2.26042 13.0938C2.43403 12.7951 2.67361 12.5556 2.97917 12.375C3.72917 11.9306 4.52431 11.5903 5.36458 11.3542C6.20486 11.1181 7.08333 11 8 11C8.91667 11 9.79514 11.1181 10.6354 11.3542C11.4757 11.5903 12.2708 11.9306 13.0208 12.375C13.3264 12.5556 13.566 12.7951 13.7396 13.0938C13.9132 13.3924 14 13.7222 14 14.0833V16H2ZM15.5 16V14.0833C15.5 13.5 15.3646 12.9583 15.0938 12.4583C14.8229 11.9583 14.4583 11.5486 14 11.2292C14.5417 11.3403 15.066 11.4896 15.5729 11.6771C16.0799 11.8646 16.5625 12.0972 17.0208 12.375C17.3264 12.5556 17.566 12.7951 17.7396 13.0938C17.9132 13.3924 18 13.7222 18 14.0833V16H15.5ZM8 10C7.16667 10 6.45833 9.70833 5.875 9.125C5.29167 8.54167 5 7.83333 5 7C5 6.16667 5.29167 5.45833 5.875 4.875C6.45833 4.29167 7.16667 4 8 4C8.83333 4 9.54167 4.29167 10.125 4.875C10.7083 5.45833 11 6.16667 11 7C11 7.83333 10.7083 8.54167 10.125 9.125C9.54167 9.70833 8.83333 10 8 10ZM15 7C15 7.83333 14.7083 8.54167 14.125 9.125C13.5417 9.70833 12.8333 10 12 10C11.8889 10 11.7847 9.99653 11.6875 9.98958C11.5903 9.98264 11.4861 9.96528 11.375 9.9375C11.7222 9.53472 11.9965 9.08681 12.1979 8.59375C12.3993 8.10069 12.5 7.56944 12.5 7C12.5 6.43056 12.3993 5.89931 12.1979 5.40625C11.9965 4.91319 11.7222 4.46528 11.375 4.0625C11.4861 4.03472 11.5903 4.01736 11.6875 4.01042C11.7847 4.00347 11.8889 4 12 4C12.8333 4 13.5417 4.29167 14.125 4.875C14.7083 5.45833 15 6.16667 15 7Z"
			/>
		</g>
	</svg>
);

export default MultiSelectUserIcon;
