import {useCallback, useEffect, useState} from "react";
import {Accordion, AutoComplete} from "mapx-components";
import {
	getSearchedCompaniesData,
	moveAllCompaniesToCandidate,
	removeAllCompaniesFromCandidateFilter,
	setCompaniesOnCandidateFilter,
} from "store/mapx/filter/filterAsyncActions";
import useSearchDebounce from "hooks/useSearchDebounce";
import {CompanyItem, ResetFilters, TabSelect} from "components";
import {
	allCompaniesCountSelector,
	candidateCompanyPositionSelector,
	searchedCompaniesSelector,
	targetListFilterCountSelector,
} from "store/mapx/filter/filterSelectors";
import Tags from "./Tags";
import TargetListFilter from "containers/Filters/PeopleFilters/CompanyFilter/TargetListFilter";
import {TCompaniesPositionValues} from "containers/Filters/PeopleFilters/CompanyFilter/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {STCompany} from "api/companyApi/types";
import {targetCompaniesPaginationSelector} from "store/mapx/target-list/targetListSelectors";

const positionOptions = [
	{label: "Current", value: "current"},
	{label: "Previous", value: "previous"},
	{label: "Any", value: "any"},
];

function CompanyFilter({disabled}: {disabled: boolean}) {
	const dispatch = useAppDispatch();

	const [searchTerm, setSearchTerm] = useSearchDebounce(800);

	const [loading, setLoading] = useState(false);

	const position = useAppSelector(candidateCompanyPositionSelector);

	const searchedCompanies = useAppSelector(searchedCompaniesSelector);

	const companiesCount = useAppSelector(allCompaniesCountSelector);

	const targetListCompaniesCount = useAppSelector(targetListFilterCountSelector);

	const targetCompaniesPagination = useAppSelector(targetCompaniesPaginationSelector);

	const handlePositionChange = useCallback(
		(value: string) => {
			if (position !== value) {
				dispatch(moveAllCompaniesToCandidate(value as TCompaniesPositionValues));
			}
		},
		[dispatch, position],
	);

	const searchCompanies = useCallback(
		async (companyName: string) => {
			if (companyName !== "" && companyName.length >= 2) {
				setLoading(true);
				await dispatch(getSearchedCompaniesData(companyName));
				setLoading(false);
			}
		},
		[dispatch],
	);

	useEffect(() => {
		searchCompanies(searchTerm).finally(() => setLoading(false));
	}, [dispatch, searchCompanies, searchTerm]);

	const handleItemClick = (company: STCompany) => {
		dispatch(setCompaniesOnCandidateFilter(company));
	};

	const handleResetClick = useCallback(() => {
		dispatch(removeAllCompaniesFromCandidateFilter());
	}, [dispatch]);

	return (
		<Accordion
			title="Companies"
			subtitle={companiesCount + targetListCompaniesCount}
			disabled={disabled}
		>
			<TabSelect.LabelContainer label="Position">
				<TabSelect
					selected={position}
					onTabChange={handlePositionChange}
					options={positionOptions}
				/>
			</TabSelect.LabelContainer>

			<AutoComplete
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				suggestions={searchedCompanies}
				handleItemClick={handleItemClick}
				handleFilterChange={setSearchTerm}
				isLoading={loading}
				notFoundResult="No company found"
				SuggestionListItemComponent={CompanyItem}
			/>

			{companiesCount > 0 && (
				<ResetFilters
					parentStyle={{color: "#5A5A5A", marginRight: 19}}
					onClick={handleResetClick}
					displayIcon={true}
				>
					Clear Selection
				</ResetFilters>
			)}

			<Tags />

			<TargetListFilter totalCompanies={targetCompaniesPagination?.count} />
		</Accordion>
	);
}

export default CompanyFilter;
