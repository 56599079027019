import {FC} from "react";
import {isNumber} from "lodash";
import classNames from "classnames";

import {Checkbox} from "components/shared";
import {ArrowDown, MultiSelectUserIcon} from "assets/icons";

import type {TSelectItemProps} from "./types";
import styles from "./MultiSelect.module.scss";

const SelectItem: FC<TSelectItemProps> = ({
	id,
	name,
	count,
	checked,
	children,
	maxWidth,
	onChange,
	onMouseOut,
	onMouseOver,
	hideRightIcon,
	className = "",
	isPartial = false,
}) => (
	<div
		onMouseOut={onMouseOut}
		onMouseOver={onMouseOver}
		className={classNames(styles.item, {
			[className]: className,
		})}
		style={{maxWidth: maxWidth ? `${maxWidth}px` : "unset", width: maxWidth ? "100%" : "unset"}}
	>
		<div className={styles.item__left} data-testid="select-item">
			<Checkbox
				value={checked}
				onChange={onChange}
				name={`${name}-${id}`}
				useCustomOnChange={false}
				className={styles.item__left__checkbox}
				checkboxBorderClassName={styles.item__left__checkbox__border}
			>
				<span className={styles.item__left__name} data-tooltip={name}>
					{name}
				</span>
			</Checkbox>

			{isPartial && <div className={styles.item__left_partial} />}
		</div>

		<div className={styles.item__right}>
			{isNumber(count) && (
				<>
					{!hideRightIcon && (
						<>
							<span className={styles.item__right__count}>{count}</span>

							<MultiSelectUserIcon
								className={classNames({
									[styles.item__right__icon]: !!count,
								})}
							/>
						</>
					)}

					{!!count && (
						<ArrowDown
							width={20}
							height={20}
							stroke="#CBC5C2"
							className={styles.item__right__count__arrow}
						/>
					)}
				</>
			)}
		</div>

		{children}
	</div>
);

export default SelectItem;
