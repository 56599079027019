import {FC, MouseEvent, useCallback, useEffect, useMemo} from "react";

import {HeaderDropDown} from "components";
import {customConfirmAlert} from "helpers";
import {useAppDispatch, useAppSelector} from "hooks";
import {PeopleHeaderIcon, PreviewIcon, RecycleBinLine} from "assets/icons";
import type {TFeatureProp} from "components/HeaderDropDown/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {
	removeCandidateFromTargetList,
	removeCandidatesFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";
import {
	removingCandidatesFromTargetListInProgressSelector,
	targetCandidatesPaginationSelector,
	targetListCandidateInProgressSelector,
	targetListCandidatesSelector,
	targetListsInProgressSelector,
	targetListsSelector,
} from "store/mapx/target-list/targetListSelectors";

import styles from "../header.module.scss";
import {setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {useHistory} from "react-router-dom";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";

const SavedPeoples: FC = () => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const project = useAppSelector(projectSelector);

	const targetLists = useAppSelector(targetListsSelector);

	const candidates = useAppSelector(targetListCandidatesSelector);

	const loadingTargetLists = useAppSelector(targetListsInProgressSelector);

	const targetCandidatesPagination = useAppSelector(targetCandidatesPaginationSelector);

	const loadingCandidateTargetList = useAppSelector(targetListCandidateInProgressSelector);

	const removingCandidates = useAppSelector(removingCandidatesFromTargetListInProgressSelector);

	const loading = useMemo(
		() => loadingCandidateTargetList || loadingTargetLists || removingCandidates,
		[loadingCandidateTargetList, loadingTargetLists, removingCandidates],
	);

	useEffect(() => {
		if (targetLists.length > 0 && !loadingCandidateTargetList) {
			dispatch(getTargetListCandidates());
		}
	}, [dispatch, project?.id, targetLists]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleRemoveAllClick = () => {
		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "People Deletion Warning!",
			message: `Are you sure about deleting all people from the Target List?`,
			handlePressYes: () => {
				dispatch(removeCandidatesFromTargetList());
			},
		});
	};

	const handleTrashClick = (event: MouseEvent<SVGElement>, candidateID: number) => {
		event.stopPropagation();
		event.preventDefault();

		// @TODO Need to refactor redux to ts
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const candidate = candidates.find((c: any) => c.id === candidateID);

		customConfirmAlert({
			yesButtonLabel: "Yes",
			noButtonLabel: "No",
			title: "People Deletion Warning!",
			message: `Are you sure about deleting "${
				candidate.full_name ?? candidate.name
			}" from the Target List?`,
			handlePressYes: () => {
				dispatch(removeCandidateFromTargetList(candidateID));
			},
		});
	};

	const handleSeeAllPeopleClick = useCallback(() => {
		if (project?.id) {
			dispatch(setProjectTabIndex(1));
			history.push(`/project/${project.id}`);
		}
	}, [dispatch, history, project?.id]);

	const featureUserList: TFeatureProp[] = [
		targetCandidatesPagination?.count > 200
			? {
					id: 11,
					deleteBtn: false,
					icon: PreviewIcon,
					// btnClassName: isStarterOrPlusUser ? styles.starter : "",
					text: "See All People",
					onClick: handleSeeAllPeopleClick,
			  }
			: {
					id: "EmptyItem",
			  },
		Number(!!targetCandidatesPagination?.count) > 0
			? {
					id: 2,
					deleteBtn: true,
					icon: RecycleBinLine,
					text: "Remove All People",
					onClick: handleRemoveAllClick,
			  }
			: {id: "EmptyItem"},

		Number(!!targetCandidatesPagination?.count) === 0
			? {
					id: "EmptyData",
					text: "person",
			  }
			: {id: "EmptyItem"},
	];

	const displayedCandidates = useMemo(() => {
		return candidates.slice(0, 50);
	}, [candidates]);

	return (
		<HeaderDropDown
			deleteItem
			groupCenter
			loading={loading}
			pathName="candidate"
			scrollList={displayedCandidates}
			title={targetCandidatesPagination?.count ?? 0}
			LeftIcon={PeopleHeaderIcon}
			listClasses={styles.list__width}
			featureUserList={featureUserList}
			handleTrashClick={handleTrashClick}
		/>
	);
};

export default SavedPeoples;
