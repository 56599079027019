import {type FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import classNames from "classnames";
import useInfiniteScroll from "react-infinite-scroll-hook";

import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {useAppDispatch, useOutsideClick, usePrevious} from "hooks";

import SelectHead from "./SelectHead";
import SelectItem from "./SelectItem";
import type {TMultiSelectProps, TRegion, TSelectData, TStepsData} from "./types";
import styles from "./MultiSelect.module.scss";
import {setOrgChartKeywordsFiltersDropdownData} from "store/mapx/company/companyActions";

const MultiSelect: FC<TMultiSelectProps> = ({
	data,
	allSelectedValues,
	name,
	title,
	values,
	subName,
	loading,
	isSelected,
	placeholder,
	isResetShow,
	nextSubName,
	isResettable,
	nextSubValues,
	resetFilters,
	hideRightIcon,
	type = "once",
	subValues = [],
	className = "",
	onSelectChange,
	selectedFilters,
	keywordPagination,
	itemsOnLeft = false,
	isScrollable = true,
	selectSearchable = false,
	setSearchInputValue,
	searchInputValue,
	handleLoadMore,
}) => {
	const position = window.pageYOffset;

	const dispatch = useAppDispatch();

	const ref = useRef<HTMLDivElement>(null);
	const headRef = useRef<HTMLDivElement>(null);
	const multiSliderRef = useRef<HTMLDivElement>(null);

	const headWidth = headRef.current && headRef.current.getBoundingClientRect().width * 1.3;
	const itemWidth = Number(headWidth) + 2;

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isShow, setIsShow] = useState<boolean>(false);
	const [regions, setRegions] = useState<TSelectData[]>([]);
	const [steps, setSteps] = useState<TSelectData["steps"]>();
	const [multiWidthSize, setMultiWidthSize] = useState<number>(0);
	const [scrollPosition, setScrollPosition] = useState<number>(0);

	const isOnce = type === "once";
	const shouldRenderSecondStep = !!steps?.length && subName;

	const prevSearchInputValue = usePrevious<string>(searchInputValue ?? "");

	const hasMoreKeywords = keywordPagination?.pages > keywordPagination?.page;

	const changeScrollPosition = () => {
		window.scrollTo({
			top: 400,
			behavior: "smooth",
		});
	};

	const [sentryRef] = useInfiniteScroll({
		loading,
		onLoadMore: () => handleLoadMore,
		rootMargin: "0px 0px 400px 0px",
		hasNextPage: hasMoreKeywords,
	});

	const containerClasses = classNames(styles.container, {
		[className]: className,
		[styles.container_once]: isOnce,
		[styles.container_once_open]: isOnce && isOpen,
		[styles.container_small]: type === "small",
		[styles.container_large]: type === "large",
		[styles.container_small_left_radius]: type === "small_left_radius",
	});

	useEffect(() => {
		if (
			(prevSearchInputValue && !searchInputValue) ||
			(searchInputValue && prevSearchInputValue !== searchInputValue)
		) {
			if (handleLoadMore) {
				handleLoadMore(true);
			}
		}
	}, [searchInputValue, handleLoadMore, prevSearchInputValue]);

	useEffect(() => {
		const multiWidth =
			multiSliderRef.current && multiSliderRef.current.getBoundingClientRect().width;
		setMultiWidthSize(Number(multiWidth));
	}, [isShow]);

	useEffect(() => {
		setScrollPosition(position);
	}, [position]);

	useEffect(() => {
		if (scrollPosition > 470 && isScrollable) {
			changeScrollPosition();
		}
	}, [selectedFilters, scrollPosition, isScrollable]);

	useOutsideClick(ref, () => setIsOpen(false));

	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleMouseOver = (id: number, steps: TSelectData["steps"]) => {
		const filterSteps = steps?.map((item) => {
			return {...item, selectId: id};
		});

		if (steps) {
			setIsShow(true);
			setSteps(filterSteps);
		}
	};

	const handleMouseOut = useCallback(
		(steps: TSelectData["steps"]) => {
			if (!steps) {
				setIsShow(false);
				setSteps([]);
			}

			if (regions) {
				setRegions([]);
			}
		},
		[regions],
	);

	const handleItemMouseOver = useCallback(
		(regData: TSelectData[]) => {
			const filterRegions = regData?.length ? regData : [];

			if (regions) {
				setRegions(filterRegions);
			}
		},
		[regions],
	);

	const handleParentSelectChange = useCallback(
		(checked: boolean, itemId: number | string, itemSteps: TStepsData[]) => {
			// countries parent should not be saved on state
			if (name !== "countries") {
				onSelectChange(name, itemId, checked);
			}

			if (subName && itemSteps) {
				const itemStepsIds = itemSteps.map((itemStep) => itemStep.id);
				const nextSubStepsIds = itemSteps.reduce<number[]>((acc, curVal) => {
					let updatedAcc = [...acc];

					if (curVal.regions?.length) {
						const regionIds = curVal.regions.map((regionItem) => regionItem.id);
						updatedAcc = [...updatedAcc, ...regionIds];
					}

					return updatedAcc;
				}, []);

				onSelectChange(subName, itemId, checked, itemStepsIds);

				if (nextSubStepsIds?.length && nextSubName) {
					onSelectChange(nextSubName, itemId, checked, nextSubStepsIds);
				}
			}
		},
		[name, nextSubName, onSelectChange, subName],
	);

	const onChangeChecked = useCallback(
		(checked: boolean, elem: TStepsData, argName?: string) => {
			const subValueSteps = subValues?.filter((subValueItem) =>
				steps?.some((step) => step.id === subValueItem),
			);

			const isLastCheckedIn = !!(
				subValueSteps &&
				checked &&
				subValueSteps?.length + 1 === steps?.length
			);

			onSelectChange(argName || subName || "", elem.id, Boolean(checked));

			const regionIds = elem?.regions?.map((regionItem) => regionItem.id);
			const subSelectItem = steps?.find((stepItem) =>
				stepItem.regions?.some((regionItem) => regionItem.id === elem.id),
			);
			const subSelectRegionIds = subSelectItem?.regions?.map(
				(subSelectItemRegion) => subSelectItemRegion.id,
			);

			if (isLastCheckedIn || subSelectItem?.id) {
				if (subSelectItem?.id) {
					onSelectChange(name, subSelectItem.id, true);
				}

				if (nextSubValues) {
					const allNextSubSelected = subSelectRegionIds?.every((regionId) =>
						[...nextSubValues, elem.id]?.includes(regionId),
					);

					if (allNextSubSelected && subName) {
						onSelectChange(subName, subSelectItem?.id as number, true);
					}
				}

				if (regionIds?.length) {
					onSelectChange(nextSubName as string, elem.id, true, regionIds);
				}
			}

			if (
				!checked &&
				(values.includes(elem?.id as number) ||
					subValues.includes(subSelectItem?.id as number))
			) {
				if (elem?.id) {
					onSelectChange(name, elem?.id as number, false);
				}

				if (
					subName &&
					subSelectItem?.id &&
					nextSubValues?.length &&
					nextSubValues?.length < 2
				) {
					onSelectChange(subName, subSelectItem?.id, false);
				}
			} else if (regionIds?.length) {
				onSelectChange(nextSubName as string, elem.id, checked, regionIds);
			}
		},
		[name, nextSubName, nextSubValues, onSelectChange, steps, subName, subValues, values],
	);

	const parentSelectChange = useCallback(
		(checked: boolean, item: TSelectData) => {
			if (allSelectedValues) {
				const itemExists = allSelectedValues.some(
					(existingItem) => existingItem.id === item.id,
				);

				const updatedData = itemExists ? allSelectedValues : [item, ...allSelectedValues];

				dispatch(setOrgChartKeywordsFiltersDropdownData(updatedData));
			}

			handleParentSelectChange(checked, item.id, item?.steps ?? []);
		},
		[handleParentSelectChange, dispatch, allSelectedValues],
	);

	const renderSelectItems = useMemo(
		() =>
			data.map((item: TSelectData) => {
				let isChecked = values.includes(item.id);
				const subItemIds = item.steps?.map((stepItem) => stepItem.id) || [];
				const stepsWithRegionChecks = item.steps?.reduce<{[key: string]: boolean}>(
					(acc, stepItem) => {
						if (stepItem?.regions?.length) {
							acc[stepItem.id] = stepItem?.regions?.every((regionItem) =>
								nextSubValues?.includes(regionItem.id),
							);
							acc.withSome = stepItem?.regions?.some((regionItem) =>
								nextSubValues?.includes(regionItem.id),
							);
						}

						return acc;
					},
					{},
				);
				const isRegionsExistedAndChecked =
					stepsWithRegionChecks && Object.keys(stepsWithRegionChecks).length
						? Object.values(stepsWithRegionChecks).every(
								(stepsWithRegionChecksItem) => stepsWithRegionChecksItem,
						  )
						: true;

				if (!isChecked && subItemIds?.length > 0) {
					isChecked =
						subItemIds.every((subItemId: number) => subValues.includes(subItemId)) &&
						!!isRegionsExistedAndChecked;
				}

				const isAllStepsPartial =
					item.steps?.some(
						(step) =>
							subValues?.includes(step.id) ||
							(stepsWithRegionChecks && stepsWithRegionChecks.withSome),
					) && !isChecked;

				return (
					<SelectItem
						name={item.name}
						checked={isChecked}
						id={Number(item.id)}
						maxWidth={itemWidth}
						count={item?.steps?.length}
						isPartial={isAllStepsPartial}
						hideRightIcon={hideRightIcon}
						key={`select-item-${item.id}`}
						onMouseOut={() => handleMouseOut(item?.steps)}
						onChange={(checked) => parentSelectChange(checked as boolean, item)}
						onMouseOver={() => handleMouseOver(item?.id, item?.steps)}
					/>
				);
			}),
		[
			data,
			values,
			itemWidth,
			subValues,
			hideRightIcon,
			nextSubValues,
			handleMouseOut,
			parentSelectChange,
		],
	);

	const renderSteps = useMemo(
		() =>
			subName &&
			steps?.map((elem) => {
				const regionsIds = elem?.regions?.map((region: TRegion) => region?.id);

				const regIdsLength = regionsIds?.length;
				const regValLength = nextSubValues?.length;

				const isPartialSelected =
					regIdsLength && regValLength ? regIdsLength !== regValLength : false;
				const isChecked =
					regValLength && regIdsLength
						? regIdsLength === regValLength
						: subValues?.includes(elem?.id);

				return (
					<SelectItem
						id={elem.id}
						name={elem.text}
						checked={isChecked}
						key={`step-${elem.id}`}
						maxWidth={itemWidth}
						isPartial={isPartialSelected}
						hideRightIcon={hideRightIcon}
						count={elem.count || elem?.regions?.length}
						onChange={(checked) => onChangeChecked(checked as boolean, elem)}
						onMouseOver={() => handleItemMouseOver(elem?.regions ?? [])}
					/>
				);
			}),
		[
			steps,
			subName,
			subValues,
			itemWidth,
			hideRightIcon,
			onChangeChecked,
			handleItemMouseOver,
			nextSubValues?.length,
		],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderRegions = regions?.map((elem: any) => (
		<SelectItem
			id={elem.id}
			name={elem.name}
			key={`region-${elem.id}`}
			hideRightIcon={hideRightIcon}
			checked={nextSubValues?.includes(elem.id)}
			onChange={(checked) => onChangeChecked(checked as boolean, elem, "regions")}
		/>
	));

	return (
		<div ref={ref} className={containerClasses} data-testid="test-id">
			<SelectHead
				data={allSelectedValues ?? data}
				name={name}
				ref={headRef}
				title={title}
				isOpen={isOpen}
				values={values}
				isOnce={isOnce}
				onClick={toggleIsOpen}
				isSelected={isSelected}
				isResetShow={isResetShow}
				placeholder={placeholder}
				isLoading={loading}
				resetFilters={resetFilters}
				isResettable={isResettable}
				selectSearchable={selectSearchable}
				setSearchInputValue={setSearchInputValue}
				onRemoveSelection={(id: number, stepsData: TStepsData[]) => {
					handleParentSelectChange(false, id, stepsData);
				}}
			/>

			{isOpen && (
				<div
					data-testid="render-select-list"
					className={styles.container__body}
					style={{
						minWidth: !isShow ? `${itemWidth}px` : `${itemWidth + multiWidthSize}px`,
					}}
				>
					<div
						className={classNames(styles.container__body__wrapper, {
							[styles.container__body__wrapper_left]: itemsOnLeft,
							[styles.container__body__wrapper_second]:
								shouldRenderSecondStep && !itemsOnLeft,
							[styles.container__body__wrapper_second_left]:
								!!regions?.length && itemsOnLeft,
						})}
						style={{width: Number(itemWidth), maxHeight: "240px"}}
					>
						{renderSelectItems}

						{hasMoreKeywords && selectSearchable && (
							<div ref={sentryRef} className={styles.container__body__wrapper_loader}>
								<Loader
									width={25}
									height={25}
									type="ThreeDots"
									data-testid="select-loader"
									color={colors.loaderDotColor}
									displayAtCenterOfPage={false}
								/>
							</div>
						)}
					</div>

					{shouldRenderSecondStep && (
						<div
							ref={multiSliderRef}
							style={{
								right: itemsOnLeft ? "unset" : 0,
								width: !itemsOnLeft ? "unset" : "100%",
								left: !itemsOnLeft ? "unset" : ` -${itemWidth}px`,
								maxWidth: !itemsOnLeft ? "unset" : `${itemWidth}px`,
							}}
							className={classNames(styles.container__body__multi, {
								[styles.container__body__multi_left]: itemsOnLeft,
							})}
						>
							{renderSteps}
						</div>
					)}

					{!!regions?.length && (
						<div
							className={styles.container__body__region}
							style={{
								width: `${itemWidth}px`,
								left: `-${itemWidth * 2}px`,
							}}
						>
							{renderRegions}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default MultiSelect;
