import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import useSearchDebounce from "./useSearchDebounce";

const useFetchListPaginateOptions = ({
	apiCall,
	setFilteredData,
	options = [],
	initialSearchQuery = "",
	setQueryCallbackOnState = undefined,
}) => {
	const [searchTerm, setSearchTerm] = useSearchDebounce(800, initialSearchQuery);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [data, setData] = useState([]);
	const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
	const [stopPaginate, setStopPaginate] = useState(false);
	const listInnerRef = useRef(null);
	const dispatch = useDispatch();

	const fetchData = useCallback(
		async (query = "", pageNumber = 1) => {
			if (stopPaginate) return;

			let response;
			setLoading(true);

			if (query !== "") {
				response = await dispatch(apiCall(query, pageNumber));

				if (setFilteredData && pageNumber === 1) {
					setFilteredData([]);
				}
			} else if (query === "") {
				response = await dispatch(apiCall("", pageNumber));
			}

			setLoading(false);

			if (response !== undefined && response?.stopPaginate !== undefined) {
				setStopPaginate(response.stopPaginate);
			}
		},
		[dispatch, setFilteredData, apiCall, stopPaginate],
	);

	/***
	 ** Here, on mount, we are checking if we are already fetched
	 */
	useEffect(() => {
		if (options?.length) {
			setData([...options]);
		}

		if (options?.length === 0 && !initialFetchCompleted) {
			fetchData().then(() => {
				if (!initialFetchCompleted) {
					setInitialFetchCompleted(true);
				}
			});
		}
	}, [options, fetchData, initialFetchCompleted, searchTerm]);

	useEffect(() => {
		if (searchTerm !== "" || page > 1) {
			fetchData(searchTerm, page).finally((r) => r);
		}
	}, [searchTerm, page, fetchData]);

	const onFilterChanged = async (e) => {
		setPage(1);
		setStopPaginate(false);
		const value = e.target.value;

		setSearchTerm(value);

		if (setQueryCallbackOnState) {
			dispatch(setQueryCallbackOnState(value)); // storing the query on redux state
		}

		if (e.target.value === "" && typeof setFilteredData === "function") {
			setFilteredData([]);
		}

		if (e.target.value === "") {
			await dispatch(apiCall("", 1));
		}
	};

	return {
		data,
		onFilterChanged,
		page,
		setPage,
		loading,
		listInnerRef,
		searchTerm,
		setSearchTerm,
	};
};

export default useFetchListPaginateOptions;
