import {createSelector} from "reselect";
import {TRootState} from "types";
import {
	TCompanyRevenuePosition,
	TCompanyRevenueRangeItem,
} from "mapx-components/Filters/CompanyRevenueSearchFilter/types";
import {companyRevenueRangeOptionsSelector} from "store/mapx/search/searchSelectors";

export const apCandidateAnyCompanyRevenueSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.revenue;

export const apCandidateCurrentCompanyRevenueSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.current_revenue;

export const apCandidatePreviousCompanyRevenueSelector = (state: TRootState) =>
	state.additional_profiles.ap_filters.previous_revenue;

export const apCandidateCompanyRevenueFilterConfigSelector = (state: TRootState) =>
	state.additional_profiles.company_revenue_filter_config;

export const apCandidateCompanyRevenueFilterActivePositionSelector = (state: TRootState) =>
	state.additional_profiles.company_revenue_filter_config.active_position;

const getCompanyRevenuePosition = (_: TRootState, position: TCompanyRevenuePosition) => position;

export const apSelectedCurrentCompanyRevenueTagsSelector = createSelector(
	[apCandidateCurrentCompanyRevenueSelector, companyRevenueRangeOptionsSelector],
	(selectedRevenues, options = []) => {
		const current: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (selectedRevenues.includes(item.id)) {
				current.push(item);
			}
		});

		return current;
	},
);

export const apSelectedPreviousCompanyRevenueTagsSelector = createSelector(
	[apCandidatePreviousCompanyRevenueSelector, companyRevenueRangeOptionsSelector],
	(selectedRevenues, options = []) => {
		const prev: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (selectedRevenues.includes(item.id)) {
				prev.push(item);
			}
		});

		return prev;
	},
);

export const apSelectedAnyCompanyRevenueTagsSelector = createSelector(
	[apCandidateAnyCompanyRevenueSelector, companyRevenueRangeOptionsSelector],
	(selectedRevenues, options = []) => {
		const any: TCompanyRevenueRangeItem[] = [];

		options.forEach((item: TCompanyRevenueRangeItem) => {
			if (selectedRevenues.includes(item.id)) {
				any.push(item);
			}
		});

		return any;
	},
);

export const apCompanyRevenueFilterKeyByPosition = createSelector(
	getCompanyRevenuePosition,
	(position) => {
		switch (position) {
			case "current":
				return "current_revenue";
			case "previous":
				return "previous_revenue";
			case "any":
			default:
				return "revenue";
		}
	},
);

export const apCandidateCompanyRevenueByPositionSelector = createSelector(
	[
		apCandidateCurrentCompanyRevenueSelector,
		apCandidatePreviousCompanyRevenueSelector,
		apCandidateAnyCompanyRevenueSelector,
		(_, position) => position,
	],
	(currentRevenue, previousRevenue, anyRevenue, position) => {
		switch (position) {
			case "current":
				return currentRevenue || null;
			case "previous":
				return previousRevenue || null;
			case "any":
				return anyRevenue || null;
			default:
				return [];
		}
	},
);

export const apCandidatesAllCompanyRevenueCountSelector = createSelector(
	[
		apCandidateCurrentCompanyRevenueSelector,
		apCandidatePreviousCompanyRevenueSelector,
		apCandidateAnyCompanyRevenueSelector,
	],
	(current, previous, any) => current.length + previous.length + any.length,
);
