export const addRecommendedArrayWithinAnotherArray = (recommendedArray, array) => {
	let results = [...array];
	let start = 0;

	for (const recommendedArrayKey in recommendedArray) {
		const recommendedItem = recommendedArray[recommendedArrayKey];

		if (start < 12) {
			start = start + 1 + parseInt(recommendedArrayKey);
			results.splice(start, 0, recommendedItem);
		} else {
			start = start + 4;
			results.splice(start, 0, recommendedItem);
		}
	}

	return results;
};

/**
 * COMBINING Generalist and No Specialism Helper
 * for example if we have HR: Generalist 46% and HR: No Specialism 3%, we only display HR: Generalist 49%
 */

export const combineGeneralistAndNoSpecialismExpDurations = (obj) => {
	const updatedObject = {};

	for (const itemKey in obj) {
		const splitSpecialism = itemKey.split(":");
		const jobFunction = splitSpecialism[0];

		if (splitSpecialism[1] === " No Specialism") {
			const generalistSpecialism = `${jobFunction}: Generalist`;

			if (obj[generalistSpecialism]) {
				updatedObject[generalistSpecialism] = obj[itemKey] + obj[generalistSpecialism];
			} else {
				updatedObject[`${jobFunction}: No Specialism`] = obj[itemKey];
			}
		} else {
			updatedObject[itemKey] = obj[itemKey];
		}
	}

	return updatedObject;
};

/***
 * Insert line breaks where newlines (\n) (\r) occur, using the parameter:
 * breaks into multiple lines
 */

export const sanitizeStringText = (string) => {
	return String(string).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1<br/>$2");
};

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * // Test objects
 * const obj1 = {
 *   a: 1,
 *   b: 1,
 *   c: { x: 1, y: 1 },
 *   d: [ 1, 1 ]
 * }
 * const obj2 = {
 *   b: 2,
 *   c: { y: 2, z: 2 },
 *   d: [ 2, 2 ],
 *   e: 2
 * }
 * const obj3 = mergeDeep(obj1, obj2);
 *
 * output: {
 *   a: 1,
 *   b: 2,
 *   c: {
 *     x: 1,
 *     y: 2,
 *     z: 2
 *   },
 *   d: [1, 2],
 *   e: 2
 * }
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const mergeDeep = (...objects) => {
	const isObject = (obj) => obj && typeof obj === "object";

	return objects.reduce((prev, obj) => {
		Object.keys(obj).forEach((key) => {
			const pVal = prev[key];
			const oVal = obj[key];

			if (Array.isArray(pVal) && Array.isArray(oVal)) {
				// prev[key] = pVal.concat(...oVal);
				prev[key] = [...new Set([...oVal, ...pVal])];
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = mergeDeep(pVal, oVal);
			} else {
				prev[key] = oVal;
			}
		});

		return prev;
	}, {});
};

export const successResponse = (response, responseCode) => {
	return (
		response !== undefined &&
		"status" in response &&
		"data" in response &&
		response.status === responseCode
	);
};
