import RejectMinusIcon from "assets/icons/RejectMinusIcon";
import SavePlusIcon from "assets/icons/SavePlusIcon";
import {MoreLikeThisButton} from "mapx-components";
import React, {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";

import {
	approveCandidate,
	rejectCandidate,
} from "store/mapx/additional-profiles/candidateDecisionAsyncActions";
import {addCandidateToTargetListDone} from "store/mapx/target-list/targetListActions";
import {
	addCandidateToTargetList,
	getTargetListCompanies,
	removeCandidateFromTargetList,
} from "store/mapx/target-list/targetListAsyncActions";
import {
	isCandidateSavedSelector,
	targetListIDSelector,
} from "store/mapx/target-list/targetListSelectors";
import {ActionAltButton} from "../index";
import styles from "./styles.module.scss";
import {TAdditionalProfileActionsProps} from "mapx-components/AdditionalProfileActions/types";
import {useAppDispatch, useAppSelector} from "hooks";
import {useSelector} from "react-redux";

const AdditionalProfileActions = ({
	searchType,
	candidate,
	isAdditionalProfileList = false,
	isQuickView = false,
}: TAdditionalProfileActionsProps) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const targetListID = useAppSelector(targetListIDSelector);

	const [saveLoading, setSaveLoading] = useState(false);

	const [rejectLoading, setRejectLoading] = useState(false);

	const isSaved = useSelector((state) => isCandidateSavedSelector(state, candidate.id));

	const saveIconComponent = <SavePlusIcon />;

	const rejectIconComponent = <RejectMinusIcon />;

	const handleSaveClick = async () => {
		setSaveLoading(true);

		if (searchType === "People Search" || searchType === "Profile") {
			await dispatch(addCandidateToTargetList(candidate.id));
		} else {
			await dispatch(approveCandidate(candidate.id, !isAdditionalProfileList));

			dispatch(addCandidateToTargetListDone(candidate, targetListID));
		}

		setSaveLoading(false);

		if (searchType === "Free Text Input Search") {
			dispatch(getTargetListCompanies());
		}

		if (searchType !== "People Search" && isQuickView) {
			history.goBack();
		}
	};

	const handleRejectClick = async () => {
		setRejectLoading(true);

		if (
			searchType === "People Search" ||
			searchType === "Project" ||
			searchType === "Profile"
		) {
			await dispatch(removeCandidateFromTargetList(candidate.id));
		} else {
			await dispatch(rejectCandidate(candidate));
		}

		setRejectLoading(false);

		if (searchType !== "People Search" && isQuickView) {
			history.goBack();
		}
	};

	const displaySaveButton = useMemo(() => {
		if (
			(searchType === "People Search" ||
				searchType === "Project" ||
				searchType === "Profile") &&
			isSaved
		) {
			return false;
		} else if (searchType === "People Search" && !isSaved) {
			return true;
		} else {
			return !isSaved;
		}
	}, [isSaved, searchType]);

	const displayRejectButton = useMemo(() => {
		if (searchType === "People Search") {
			return !!isSaved;
		} else if (searchType === "Project") {
			return true;
		} else if (searchType === "Profile") {
			return !!isSaved;
		} else {
			return !isSaved;
		}
	}, [isSaved, searchType]);

	const rejectButtonTitle = useMemo(() => {
		if (
			searchType === "People Search" ||
			searchType === "Project" ||
			searchType === "Profile"
		) {
			return rejectLoading ? "Removing" : "Remove";
		} else {
			return rejectLoading ? "Rejecting" : "Reject";
		}
	}, [rejectLoading, searchType]);

	return (
		<div className={styles.buttonRow}>
			<div className={styles.buttonGroup}>
				<MoreLikeThisButton candidate={candidate} />

				{displayRejectButton && (
					<ActionAltButton
						key={"reject"}
						customClass={styles.rejectButton}
						iconComponent={rejectIconComponent}
						handleClick={handleRejectClick}
						loading={rejectLoading}
						title={rejectButtonTitle}
						disabled={saveLoading || rejectLoading}
					/>
				)}

				{displaySaveButton && (
					<ActionAltButton
						key={"approve"}
						customClass={styles.saveButton}
						iconComponent={saveIconComponent}
						handleClick={handleSaveClick}
						loading={saveLoading}
						title={saveLoading ? "Saving" : "Save"}
						disabled={saveLoading || rejectLoading}
					/>
				)}
			</div>
		</div>
	);
};

export default AdditionalProfileActions;
