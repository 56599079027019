import {
	GET_INDUSTRIES_REPORT_FETCH,
	GET_INDUSTRIES_REPORT_SUCCESS,
	GET_INDUSTRY_BACKGROUND_REPORT_DONE,
	GET_INDUSTRY_BACKGROUND_REPORT_FAILED,
	GET_INDUSTRY_BACKGROUND_REPORT_INIT,
	SET_COUNTRY_REPORT,
	SET_ETHNIC_DIVERSITY_DATA,
	SET_GENDER_DIVERSITY_DATA,
	SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT,
	SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_CURRENT_PROJECT,
	SET_JOB_FUNCTION_DISTRIBUTION_DATA,
	SET_ONBOARDING_STEP,
	SET_ONBOARDING_VISIBILITY,
	SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA,
	SET_PROJECT_STATS,
	SET_PROJECT_STATS_FAILED,
	SET_PROJECT_STATS_INIT,
	SET_PROJECT_CHECKLIST_FULL_DISPLAY,
	UPDATE_PROJECT_SUCCESS_CHECKLIST,
} from "../mapXActionTypes";

export function setGenderDiversityData(payload) {
	return {type: SET_GENDER_DIVERSITY_DATA, payload};
}

export function getIndustriesReportFetch() {
	return {type: GET_INDUSTRIES_REPORT_FETCH};
}

export function setCountryReport(payload) {
	return {type: SET_COUNTRY_REPORT, payload};
}

export function getIndustriesReportSuccess(payload) {
	return {type: GET_INDUSTRIES_REPORT_SUCCESS, payload};
}

export function setEthnicDiversityData(payload) {
	return {type: SET_ETHNIC_DIVERSITY_DATA, payload};
}

export function setJobFunctionDistributionData(payload) {
	return {type: SET_JOB_FUNCTION_DISTRIBUTION_DATA, payload};
}

export function setProjectStats(payload) {
	return {type: SET_PROJECT_STATS, payload};
}

export function getProjectStatsInit() {
	return {type: SET_PROJECT_STATS_INIT};
}

export function getProjectStatsFailed() {
	return {type: SET_PROJECT_STATS_FAILED};
}

export function setProjectFrequentlyHiredFromData(payload) {
	return {type: SET_PROJECT_FREQUENTLY_HIRED_FROM_DATA, payload};
}

//industries background report
export function getIndustriesBackgroundReportInit() {
	return {type: GET_INDUSTRY_BACKGROUND_REPORT_INIT};
}

export function getIndustriesBackgroundReportDone(payload) {
	return {type: GET_INDUSTRY_BACKGROUND_REPORT_DONE, payload};
}

export function getIndustriesBackgroundReportFailed() {
	return {type: GET_INDUSTRY_BACKGROUND_REPORT_FAILED};
}

export function setProjectChecklistFullDisplay(payload) {
	return {type: SET_PROJECT_CHECKLIST_FULL_DISPLAY, payload};
}

export function updateProjectSuccessChecklist(payload) {
	return {type: UPDATE_PROJECT_SUCCESS_CHECKLIST, payload};
}

// Onboarding
export function setOnboardingVisibility(payload) {
	return {type: SET_ONBOARDING_VISIBILITY, payload};
}

export function setOnboardingStep(payload) {
	return {type: SET_ONBOARDING_STEP, payload};
}

// CANDIDATE SUMMARIES

export function setGenerateSummaryCandidateIdsForCurrentProject(payload) {
	return {type: SET_GENERATE_SUMMARY_CANDIDATE_IDS_FOR_CURRENT_PROJECT, payload};
}

// CANDIDATE CONTACT DETAILS

export function setGenerateContactDetailsCandidateIdsForCurrentProject(payload) {
	return {type: SET_GENERATE_CONTACT_DETAILS_CANDIDATE_IDS_FOR_CURRENT_PROJECT, payload};
}
