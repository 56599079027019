import React from "react";
import {TSelectedProjectTypeHeaderProps} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/SelectedProjectTypeHeader/types";
import styles from "./styles.module.scss";

const SelectedProjectTypeHeader = ({title, description, icon}: TSelectedProjectTypeHeaderProps) => {
	return (
		<div className={styles.header}>
			<div className={styles.icon}>{icon}</div>
			<div className={styles.info}>
				<div className={styles.title}>{title}</div>
				<div className={styles.description}>{description}</div>
			</div>
		</div>
	);
};

export default SelectedProjectTypeHeader;
