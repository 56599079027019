import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Tag} from "components";
import FilterTags from "mapx-components/FilterTags";
import {setAPCompany} from "store/mapx/additional-profiles/additionalProfilesAsyncActions";
import {apCandidateCompaniesOptionsSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";

function Tags() {
	const dispatch = useDispatch();

	const companies = useSelector(apCandidateCompaniesOptionsSelector);

	const handleTagClick = useCallback(
		(id) => {
			dispatch(setAPCompany({id}));
		},
		[dispatch],
	);

	return (
		<FilterTags
			label="Added Companies"
			count={companies.length}
			tags={companies.map((item) => (
				<Tag key={item.id} onClick={() => handleTagClick(item.id)}>
					{item.name}
				</Tag>
			))}
		/>
	);
}

export default React.memo(Tags);
