import React, {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {getProjectStats} from "store/mapx/project/projectAsyncActions";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";

import css from "mapx-pages/Project/Overview/ProjectStats/projectStats.module.scss";
import SkeletonLoaderProjectStats from "mapx-pages/Project/Overview/ProjectStats/StatsSkeletonLoader";
import {TRootState} from "types";
import {useAppDispatch, useAppSelector} from "hooks";

export default function ProjectStats() {
	const dispatch = useAppDispatch();

	const projectStats = useSelector((state: TRootState) => state.mapxProject.projectStats);

	const project = useSelector(projectSelector);

	const statsLoading = useAppSelector((state) => state.mapxProject.projectStatsLoading);

	const statsLoadingRef = useRef(statsLoading);

	useEffect(() => {
		if (project?.id && !statsLoadingRef.current) {
			dispatch(getProjectStats(project.id));
		}
	}, [dispatch, project?.id]);

	return (
		<div className={css.statCounts}>
			{statsLoading ? (
				<span className={css.loader}>
					<SkeletonLoaderProjectStats count={3} />
				</span>
			) : (
				<>
					<div className={css.countBox}>
						<div className={css.itemCount}> {projectStats?.candidates_number} </div>
						People
					</div>
					<div className={css.countBox}>
						<div className={css.itemCount}> {projectStats?.companies_number} </div>
						Companies
					</div>
					<div className={css.countBox}>
						<div className={css.itemCount}>
							{projectStats?.diversity_percent ? (
								<>{`${projectStats?.diversity_percent}%`}</>
							) : (
								"-"
							)}
						</div>
						Diversity
					</div>
				</>
			)}
		</div>
	);
}
