import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getOrImportCandidateByLinkedInUrl} from "store/mapx/candidate/linkedInProfileImportAsyncActions";
import css from "./styles.module.scss";
import {SearchInput} from "mapx-components";
import {Loader} from "components";
import colors from "styles/themes.module.scss";
import {isLinkedinProfileUrl, linkedInUrlPurify} from "helpers/string";
import {clearSearchedCandidates, setFilterForCandidates} from "store/mapx/filter/filterActions";
import {useSelector} from "react-redux";
import {candidateLinkedInUrlSelector} from "store/mapx/filter/filterSelectors";
import {importLinkedInCandidateInProgressSelector} from "store/mapx/candidate/candidateSelectors";
import {
	getSingleCandidateDone,
	setSelectedCandidatesDone,
} from "store/mapx/candidate/candidateActions";
import {TSearchByLinkedInProps} from "mapx-pages/CandidateSearch/ProfileSearch/PersonFilter/SearchByLinkedIn/types";
import {useAppDispatch} from "hooks";

const SearchByLinkedIn = ({toggleDisplayUserSearchedResult}: TSearchByLinkedInProps) => {
	const dispatch = useAppDispatch();

	const searchedLinkedInUrl = useSelector(candidateLinkedInUrlSelector);

	const importLinkedInCandidateInProgress = useSelector(
		importLinkedInCandidateInProgressSelector,
	);

	const [loading, setLoading] = useState(false);

	const [input, setInput] = useState("");

	const isInvalidLinkedInUrl = (url: string) => url !== "" && !isLinkedinProfileUrl(url);

	// const handleResetClick = () => {
	//     dispatch(setFilterForCandidates({type: "linkedin_url", values: null}));
	// };

	const getCandidateByLinkedInUrl = useCallback(
		async (url: string) => {
			setLoading(true);

			const candidate = await dispatch(getOrImportCandidateByLinkedInUrl(url));

			setLoading(false);

			toggleDisplayUserSearchedResult([]);

			if (candidate) {
				candidate.searched_by_linkedin = true;

				dispatch(setSelectedCandidatesDone([candidate]));

				dispatch(getSingleCandidateDone(candidate));

				dispatch(clearSearchedCandidates());
			}
		},
		[dispatch, toggleDisplayUserSearchedResult],
	);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const val = linkedInUrlPurify(e.target.value);

		setInput(val);

		if (!isInvalidLinkedInUrl(val)) {
			getCandidateByLinkedInUrl(val).finally(() => setLoading(false));

			dispatch(setFilterForCandidates({type: "linkedin_url", values: val}));
		}
	};

	const hasError = useMemo(() => isInvalidLinkedInUrl(input), [input]);

	useEffect(() => {
		if (searchedLinkedInUrl) {
			setInput(linkedInUrlPurify(searchedLinkedInUrl));
		}
	}, [searchedLinkedInUrl]);

	const InlineLoaderComponent = (
		<Loader
			displayAtCenterOfPage={false}
			type={"ThreeDots"}
			height={20}
			width={20}
			color={colors.loaderDotColor}
		/>
	);

	return (
		<>
			<div className={css.searchWrapper}>
				<SearchInput
					type="text"
					placeholder={"Paste LinkedIn Profile URL"}
					onChange={handleOnChange}
					value={input}
					autoComplete="off"
					errorText={hasError && "Please enter a valid LinkedIn profile url!"}
					errorClass={css.errorClass}
					disabled={importLinkedInCandidateInProgress}
					isLinkIcon={true}
					disableFocus
					style={{border: "1px solid #DAD8D7", borderRadius: "0px 0px 0px 4px"}}
				/>

				{searchedLinkedInUrl !== null && loading && (
					<span className={css.loader}>{InlineLoaderComponent}</span>
				)}
			</div>

			{/*{input && !hasError && (*/}
			{/*    <ResetFilters*/}
			{/*        onClick={handleResetClick}*/}
			{/*        parentStyle={{margin: "-5px 0", paddingBottom: 10}}*/}
			{/*    >*/}
			{/*        Clear search*/}
			{/*    </ResetFilters>*/}
			{/*)}*/}
		</>
	);
};

export default SearchByLinkedIn;
