import classNames from "classnames";

import type {FC} from "react";

import type {TSwitch} from "./types";

import css from "./switch.module.scss";

const Switch: FC<TSwitch> = ({checked, ...rest}) => {
	return (
		<label className={css.switch}>
			<input className={css.input} type="checkbox" checked={checked} {...rest} />
			<span className={classNames(css.slider, css.round)}>
				<span className={css.dot} />
			</span>
		</label>
	);
};

export default Switch;
