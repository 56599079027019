export const prepareSpreadSheetFileAndDownload = (contentData, fileName) => {
	const url = window.URL.createObjectURL(new Blob([contentData]));
	const link = document.createElement("a");

	link.href = url;
	link.setAttribute("download", `${fileName}.xlsx`);

	document.body.appendChild(link);
	link.click();

	link.remove();
};

export const prepareDateForFileNameSuffix = () => {
	const today = new Date();
	const yyyy = today.getFullYear();

	let mm = today.getMonth() + 1; // Months start at 0!
	let dd = today.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	return dd + "/" + mm + "/" + yyyy;
};

export const getJobTitleWithHighlightEffect = (keywordSet = [], fullText) => {
	try {
		keywordSet = keywordSet.map((k) => k.name.toUpperCase());

		return fullText
			.split(" ")
			.map((text) => {
				if (keywordSet.includes(text.toUpperCase())) {
					text = `<span>${text}</span>`;
				}

				return text;
			})
			.join(" ");
	} catch (e) {
		console.log(e);
	}
};
