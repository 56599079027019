import React, {type FC, Fragment, useRef, useState} from "react";
import {isEmpty} from "lodash";
import classNames from "classnames";
import {useLocation} from "react-router-dom";

import {Button} from "components";
import {useAppDispatch, useAppSelector, useOutsideClick} from "hooks";
import {ArrowCircleUpIcon, ExternalLinkIcon, LogoIcon} from "assets/icons";
import {onboardingForStarterUserSelector} from "store/mapx/project/projectSelectors";
import {projectSelector, projectsSelector} from "store/mapx/project-list/projectListSelectors";
import {
	hasAccessToExtendedPagesSelector,
	isSuperUserSelector,
	userLicenseTypeNameSelector,
	userOrganisationRoleSelector,
	userOrganisationTypeSelector,
	userSelector,
} from "store/mapx/user/userSelectors";
import {contactAdminModal, pricingTableModal} from "store/mapx/payment/paymentActions";
import type {TWatchVideoHandle} from "mapx-components/WatchVideo/types";
import {useAccessControl} from "mapx-components/Layouts/Sidebar/useAccessControl";

import {pages} from "./utils";
import NavItem from "./NavItem";
import type {TNavItemProps} from "./types";
import styles from "./Sidebar.module.scss";
import {organisationHasPaidPlanSelector} from "store/mapx/organisation/organisationSelectors";
import {CgClose, CgMenu} from "react-icons/cg";
import HeadUser from "../Header/HeadUser";
import {ChangePasswordModal} from "../../index";
import UserImageOrAvatar from "assets/icons/userImageOrAvatar";
import LogoIconSmall from "assets/icons/LogoIconSmall";
import {setSideBarExtended} from "store/mapx/layout/layoutActions";

const Sidebar: FC = () => {
	// const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();

	const ref = useRef<HTMLDivElement>(null);
	const watchVideoRef = useRef<TWatchVideoHandle | null>(null);
	const isHome = location.pathname === "/home";

	const [expanded, setExpanded] = useState<boolean>(false);

	const [[changePassModalDisplay], setChangePassModalDisplay] = useState<[boolean, null]>([
		false,
		null,
	]);

	useOutsideClick(ref, () => setChangePassModalDisplay([false, null]));

	const user = useAppSelector(userSelector);
	const project = useAppSelector(projectSelector);
	const projects = useAppSelector(projectsSelector);
	const isSuperUser = useAppSelector(isSuperUserSelector);
	const organisationType = useAppSelector(userOrganisationTypeSelector);

	const organisationRole = useAppSelector(userOrganisationRoleSelector);
	const userLicenseTypeName = useAppSelector(userLicenseTypeNameSelector);
	const organisationHasPaidPlan = useAppSelector(organisationHasPaidPlanSelector);

	// const shouldDisplayOnBoarding =
	// 	userLicenseTypeName === "Starter" || userLicenseTypeName === "Plus";

	const isAdmin = organisationRole === "Admin";

	const onboardingForStarterUser = useAppSelector(onboardingForStarterUserSelector);

	const hasAccessToExtendedPages = useAppSelector(hasAccessToExtendedPagesSelector);

	useAccessControl(hasAccessToExtendedPages);

	useOutsideClick(ref, () => {
		setExpanded(false);
		dispatch(setSideBarExtended(false));
	});

	const openPaymentModalHandler = () => {
		if (organisationHasPaidPlan) {
			dispatch(contactAdminModal(true));
		} else {
			dispatch(pricingTableModal(true));
		}
	};

	const handleMouseOut = () => {
		setExpanded(false);
		dispatch(setSideBarExtended(false));
	};
	const handleMouseOver = () => {
		if (!watchVideoRef.current?.show) {
			setExpanded(true);
			dispatch(setSideBarExtended(true));
		}
	};

	const notClient: boolean = organisationType !== "Client";
	const superUserOrNotClient = isSuperUser && notClient;

	const renderPageSteps = pages(
		isSuperUser,
		superUserOrNotClient,
		hasAccessToExtendedPages,
		isEmpty(projects),
		isAdmin,
		onboardingForStarterUser,
	)?.map((item: TNavItemProps, idx) => {
		if (item.Icon) {
			return (
				<div key={`${item.name}-${idx}`} className={styles.navItemWrapper}>
					<NavItem
						name={item.name}
						Icon={item.Icon}
						path={item.path}
						collapsed={!expanded}
						childPaths={item.childPaths}
					/>
				</div>
			);
		}

		return null;
	});

	const renderNavClassName = classNames(styles.nav, {
		[styles.is_home]: isHome,
		[styles.navCollapsed]: !expanded,
		[styles.nav__selected]: project?.name,
		[styles.is_z_index]: onboardingForStarterUser,
	});

	const WEB_TUTORIAL_URL =
		process.env?.REACT_APP_DWS_WEB_TUTORIAL_URL ??
		"https://26144956.hs-sites-eu1.com/en/knowledge-base";

	return (
		<>
			{!expanded && (
				<CgMenu
					className={styles.menu}
					onClick={() => setExpanded(!expanded)}
					size={25}
					color={"#808080"}
				/>
			)}

			{expanded && (
				<CgClose
					className={classNames(styles.menu, styles.closeMenu)}
					onClick={() => setExpanded(!expanded)}
					size={20}
					color={"#808080"}
				/>
			)}

			<nav
				ref={ref}
				onMouseOut={handleMouseOut}
				onMouseOver={handleMouseOver}
				className={renderNavClassName}
				data-testid="nav-sidebar-container"
			>
				<Button defaultBtnStyle className={styles.navItems__logo} to="/home">
					{expanded ? (
						<span style={{marginLeft: "60px"}} className={styles.logoFadeIn}>
							<LogoIcon />
						</span>
					) : (
						<LogoIconSmall className={styles.logoFadeIn} />
					)}
				</Button>

				<div className={styles.navItems}>
					<div className={styles.navItems__container}>{renderPageSteps}</div>

					<div className={styles.navItems__footer}>
						{/*{shouldDisplayOnBoarding && (*/}
						{/*	<Fragment>*/}
						{/*		<div*/}
						{/*			role="button"*/}
						{/*			className={classNames(styles.navItems__footer__info, {*/}
						{/*				[styles.navItems__footer__info_close]: !expanded,*/}
						{/*			})}*/}
						{/*			onClick={() => history.push("/onboarding/home")}*/}
						{/*		>*/}
						{/*			<HelpIcon fill="#868d8d" />*/}
						{/*			{expanded && (*/}
						{/*				<p className={styles.navItems__footer__info_text}>*/}
						{/*					Quick Start Guide*/}
						{/*				</p>*/}
						{/*			)}*/}
						{/*		</div>*/}

						{/*		<div className={styles.navItems__footer__solid} />*/}
						{/*	</Fragment>*/}
						{/*)}*/}

						<Fragment>
							<div
								role="button"
								className={classNames(styles.navItems__footer__info, {
									[styles.navItems__footer__info_close]: !expanded,
								})}
								onClick={() => window.open(WEB_TUTORIAL_URL, "_blank")}
							>
								<ExternalLinkIcon color="#868d8d" />

								{expanded && (
									<p className={styles.navItems__footer__info_text}>
										Help Center
									</p>
								)}
							</div>

							<div className={styles.navItems__footer__solid} />
						</Fragment>

						<div
							className={classNames(styles.navItems__footer__user, {
								[styles.navItems__footer__user_close]: !expanded,
							})}
						>
							{!expanded ? (
								<UserImageOrAvatar />
							) : (
								<div className={styles.navItems__footer__user__info}>
									<HeadUser setModalDisplay={setChangePassModalDisplay} />
								</div>
							)}

							<ChangePasswordModal
								ref={ref}
								changePassModalDisplay={changePassModalDisplay}
								setChangePassModalDisplay={setChangePassModalDisplay}
							/>
						</div>

						{(userLicenseTypeName === "Plus" || (isAdmin && organisationHasPaidPlan)) &&
							expanded && (
								<div className={styles.navItems__footer__plus}>
									<p className={styles.navItems__footer__plus_title}>
										Want even more features?
									</p>

									<a
										target="blank"
										className={styles.navItems__footer__plus_description}
										href={`https://share-eu1.hsforms.com/1E6nq5jD6Qyei811lydzk4wfkdks?email=${user.email}&firstname=${user.firstName}&lastname=${user.lastName}&company=${user.organisation.name}`}
									>
										Inquire about our Pro and <br /> Enterprise licenses
									</a>
								</div>
							)}

						{/*{(userLicenseTypeName === "Enterprise" || userLicenseTypeName === "Pro") && (*/}
						{/*	<WatchVideo*/}
						{/*		expanded={expanded}*/}
						{/*		ref={watchVideoRef}*/}
						{/*		className={styles.navItems__footer_video}*/}
						{/*	/>*/}
						{/*)}*/}

						{userLicenseTypeName === "Starter" &&
							!(isAdmin && organisationHasPaidPlan) && (
								<Button
									LeftIcon={ArrowCircleUpIcon}
									onClick={openPaymentModalHandler}
									className={classNames(styles.navItems__upgrade, {
										[styles.navItems__upgrade_expanded]: expanded,
									})}
									leftIconClasses={classNames(styles.navItems__upgrade__icon, {
										[styles.navItems__upgrade__icon_expanded]: expanded,
									})}
								>
									{expanded && (
										<div className={styles.navItems__upgrade__content}>
											<p className={styles.navItems__upgrade__content_title}>
												Upgrade to
												<span className={styles.plus}>PLUS</span>
											</p>
											<span
												className={
													styles.navItems__upgrade__content_subtitle
												}
											>
												7 days free trial
											</span>
										</div>
									)}
								</Button>
							)}
					</div>
				</div>
			</nav>
		</>
	);
};

export default Sidebar;
