/** @format */

import HttpClient from "api";
import type {STEvents} from "api/types";
import {STProjectForm, TProjectChecklistForm} from "api/projectApi/types";

class MapxProjectApi extends HttpClient {
	async exportProject(projectID: number | string, config = {responseType: "arraybuffer"}) {
		return this.doGet(`/mapx/projects/${projectID}/export`, config);
	}

	async deleteProject(projectID: number) {
		return this.doDelete(`/mapx/projects/${projectID}`);
	}

	async getProject(projectID: number, config = {}) {
		return this.doGet(`/mapx/projects/${projectID}`, config);
	}

	async getProjects(pageNumber = 1, name = "") {
		let filterQuery = `?page=${pageNumber}`;

		// Conditionally add the optional name parameter
		if (name !== undefined && name !== null && name !== "") {
			filterQuery += `&name=${name}`;
		}

		return this.doGet(`/mapx/projects${filterQuery}`);
	}

	async saveProject(formData: STProjectForm, config = {}) {
		return this.doPost("/mapx/projects", formData, config);
	}

	async updateProject(projectID: number, formData: STProjectForm, config = {}) {
		return this.doPatch(`/mapx/projects/${projectID}`, formData, config);
	}

	async getProjectSuccessChecklistInfo(projectId: number, config = {}) {
		return this.doGet(`/project/${projectId}/checklist`, config);
	}

	async updateProjectChecklistInfo(
		projectId: number,
		formData: TProjectChecklistForm,
		config = {},
	) {
		return this.doPatch(`/project/${projectId}/checklist`, formData, config);
	}

	async saveEvents(data: STEvents[], config = {}) {
		return this.doPost(`/mapx/events`, data, config);
	}
}

const mapxProjectApi = new MapxProjectApi();

export default mapxProjectApi;
