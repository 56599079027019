import {TRootState} from "types";
import {createSelector} from "reselect";

export const generateCandidatesContactDetailsForAllProjectSelector = (state: TRootState) =>
	state.mapxProject.generateCandidatesContactDetailsByProject;

export const targetListIdSelector = (_: TRootState, id: number) => id;
export const candidateIdSelector = (_: TRootState, __: TRootState, candidateId: number) =>
	candidateId;

export const getAllGenerateContactDetailsCandidateIdsForCurrentProjectSelector = createSelector(
	generateCandidatesContactDetailsForAllProjectSelector,
	targetListIdSelector,
	(generateCandidatesContactDetailsForAllProject, tlId) => {
		if (tlId) {
			const objectKey =
				typeof generateCandidatesContactDetailsForAllProject === "object"
					? Object.keys(generateCandidatesContactDetailsForAllProject).find(
							(id) => id.toString() === tlId.toString(),
					  )
					: null;

			if (
				objectKey &&
				generateCandidatesContactDetailsForAllProject[objectKey] !== undefined
			) {
				return generateCandidatesContactDetailsForAllProject[objectKey] || [];
			}
		}

		return [];
	},
);

export const isContactDetailsForCurrentCandidateInProgressForCurrentProjectSelector =
	createSelector(
		generateCandidatesContactDetailsForAllProjectSelector,
		targetListIdSelector,
		candidateIdSelector,
		(generateCandidatesContactDetailsForAllProject, tlId, candidateId) => {
			if (tlId && candidateId) {
				const objectKey =
					typeof generateCandidatesContactDetailsForAllProject === "object"
						? Object.keys(generateCandidatesContactDetailsForAllProject).find(
								(id) => id.toString() === tlId.toString(),
						  )
						: null;

				if (
					objectKey &&
					generateCandidatesContactDetailsForAllProject[objectKey] !== undefined &&
					Array.isArray(generateCandidatesContactDetailsForAllProject[objectKey])
				) {
					return generateCandidatesContactDetailsForAllProject[objectKey].includes(
						candidateId,
					);
				}
			}

			return false;
		},
	);
