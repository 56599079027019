import {
	setActiveSearchRequest,
	setPRFilter,
	toggleJobTitleSuggestionLoading,
} from "store/mapx/additional-profiles/additionalProfilesActions";
import axios from "axios";
import {Dispatch} from "redux";
import {TAppDispatch, TRootState} from "types";
import mapxProfileRequestsApi from "api/projectApi/profileRequestApi";
import mapxJobTitleApi from "api/filterOptionsApi/JobTitleApi";
import {successResponse} from "helpers/map";
import {prFiltersSelector} from "store/mapx/additional-profiles/additionalProfilesSelectors";
import {TSearchRequestCreate} from "api/projectApi/searchRequestApi/types";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {createSearchRequest} from "store/mapx/additional-profiles/searchRequestAsyncActions";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const cancelTokens = {} as any;

export const getJobTitlesSuggestionForProfileRequest =
	(candidateId: number, onlySuggestedJobTitles: boolean) =>
	async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = `PR_JOB_TITLE_SUGGESTIONS`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		try {
			dispatch(toggleJobTitleSuggestionLoading(true));

			const response = await mapxJobTitleApi.suggestion([candidateId], config);

			if (successResponse(response, 200)) {
				const jobTitles: string[] = response.data;

				if (!onlySuggestedJobTitles) {
					const selectedJobTitles = jobTitles.slice(0, 5); // takes 1st 5
					dispatch(setPRFilter({type: "selected_job_titles", values: selectedJobTitles}));
				}

				const suggestedJobTitles = jobTitles.slice(5); // skips 1st 5, takes rest of the items

				dispatch(setPRFilter({type: "job_title_suggestions", values: suggestedJobTitles}));
			}

			dispatch(toggleJobTitleSuggestionLoading(false));

			return response && "status" in response ? response?.status : 500;
		} catch (e) {
			console.log(`Error from job title suggestion request: ${e}`);
		}
	};
export const createProfileRequest =
	() => async (dispatch: Dispatch<TAppDispatch>, getState: TRootState) => {
		try {
			const state = getState();

			const filters = prFiltersSelector(state);

			const project = projectSelector(state);

			const apiPayload: TSearchRequestCreate = {
				type: "Additional Profiles",
				company_ids: filters.company_ids,
				candidate_id: filters.candidate_id,
				job_titles: filters.selected_job_titles,
				country_ids: filters.country_ids,
				search_width: filters.search_width,
				project_id: project.id,
			};

			if (apiPayload.candidate_id === null) {
				delete apiPayload.candidate_id;
			}

			await dispatch(createSearchRequest(apiPayload));
		} catch (e) {
			console.log(`Error from creating Profile request: ${e}`);
		}
	};

export const getProfileRequestById =
	(requestId: number) => async (dispatch: Dispatch<TAppDispatch>) => {
		const requestKey = `GET_PROFILE_REQUEST_DETAILS_${requestId}`;

		if (requestKey in cancelTokens) {
			cancelTokens[requestKey].cancel("Operation canceled due to new request.");
		}

		cancelTokens[requestKey] = axios.CancelToken.source();

		const config = {
			cancelToken: cancelTokens[requestKey].token,
		};

		let response;

		try {
			response = await mapxProfileRequestsApi.getProfileRequestsById(requestId, config);

			if (response?.status === 200) {
				dispatch(setActiveSearchRequest(response.data));

				return response.data;
			}
		} catch (e) {
			console.log(`Error from getting all Profile request: ${e}`);
		}
	};
