import type {FC} from "react";
import ContentLoader from "react-content-loader";

import styles from "./frequentlyHiredFrom.module.scss";

const skeletonItemCount = new Array(9).fill("");

const CompanyCardSkeleton: FC = () => {
	const renderItems = skeletonItemCount.map((_, idx) => (
		<ContentLoader
			key={idx}
			height={200}
			width={"360px"}
			viewBox="0 0 360 200"
			backgroundColor="#DCE7E7"
			foregroundColor="#F9F9F9"
			style={{
				borderRadius: "5px",
				border: "1px solid #d1cdcc",
			}}
		>
			<circle cx="50" cy="50" r="30" />
			<rect x="90" y="15.2" rx="5" ry="5" width="150px" height="21px" />
			<rect x="90" y="45" rx="5" ry="5" width="180px" height="35px" />
			<rect x="90" y="95" rx="5" ry="5" width="220px" height="55px" />
		</ContentLoader>
	));

	return <div className={styles.skeleton_container}>{renderItems}</div>;
};

export default CompanyCardSkeleton;
